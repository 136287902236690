import React, { Component } from 'react';
import axios from 'axios';


class FloatingChatBoxForBMGroup extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            showChatBox: false,
            inputComment: '',
            comments: [],
        }
        this.key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj';
    }

    componentDidMount = () => {

        axios({
            method: 'post',
            url: `${localStorage.apiHost}/all-comment-bm-group`,
            headers: {
                'key': this.key,
                'access-token': localStorage.token
            },
            data: {
                'program-id': localStorage.programId
            }
        }).then(response => {
            if (response.data.status === 200) {
                // response.data[0].map(comment => {
                //     this.state.comments.push(comment);
                // });
                this.setState({ comments: response.data[0] });
                setTimeout(function() {
                    let elem = document.getElementById('bm-group-chatbox-body');
                    if(elem !== null){
                        elem.scrollTop = elem.scrollHeight;
                    }
                }.bind(this), 2000);
            }
        }).catch((error) => {
            if(error.response){
                console.log(error.response);
            }
        });
    }

    
    componentDidUpdate(prevProps) {

        if (this.props.eventbMGroupComment !== prevProps.eventbMGroupComment) {
            
            this.state.comments.push(this.props.eventbMGroupComment);
            
            this.setState({comments: this.state.comments});

            setTimeout(function() {
                let elem = document.getElementById('bm-group-chatbox-body');
                if(elem !== null){
                    elem.scrollTop = elem.scrollHeight;
                }
            }.bind(this), 2000);

            
        }

    }

    openChatBox = (event) => {
        localStorage.bmGroupChatNotification = "false";
        this.setState({ showChatBox: true });
        event.preventDefault();
    }

    closeChatBox = (event) => {
        localStorage.bmGroupChatNotification = "false";
        this.setState({ showChatBox: false });
        event.preventDefault();
    }

    setComment = (event) => {
        this.setState({ inputComment: event.target.value });
    }

    submitComment = (event) => {
        let inputComment = this.state.inputComment;
        if (inputComment.length > 0) {
            axios({
                method: 'post',
                url: `${localStorage.apiHost}/save-comment-bm-group`,
                headers: {
                    'key': this.key,
                    'access-token': localStorage.token
                },
                data: {
                    'program-id': localStorage.programId,
                    'comment': inputComment
                }
            }).then(response => {
                if (response.data.status === 200) {
                    document.getElementById("bm-group-comment-form").reset();
                } else {
                    alert("message not send");
                }
            }).catch((error) => {
                if(error.response){
                    console.log(error.response);
                }
            });
        }

        event.preventDefault();
    }

    // broadCastAudio = (audioId, event) => {
    //     //broadcast audio to all shareholders
    //     axios({
    //         method: 'post',
    //         url: `${localStorage.apiHost}/send-audio-broadcast`,
    //         headers: {
    //             'key': this.key,
    //             'access-token': localStorage.token
    //         },
    //         data: {
    //             'program-id': localStorage.programId,
    //             'voice-id': audioId
    //         }
    //     }).then(response => {
    //         if (response.data.status === 200) {
    //             alert("audio successfully broadcast");
    //         }
    //     })
    //     event.preventDefault();
    // }

    render() {
        let elem = document.getElementById('bm-group-chatbox-body');
        if(elem !== null){
            elem.scrollTop = elem.scrollHeight;
        }
        
        return (
            <div>
                {
                    this.state.showChatBox === true
                        ?
                        <div className={`${localStorage.BmBoderatorChat === '1' ? "chat-popup-box chat-popup-bm-box":"chat-popup-box" }`}>
                            <div className="chat-panel round-top" style={{backgroundColor:'white'}}>
                                <div className="chatbox-header">
                                    <div className="col-md-11 col-sm-11 col-xs-11">
                                        <h5>{this.props.bmGroupChatHeading}</h5>
                                    </div>
                                    <div>
                                        <img onClick={(e) => this.closeChatBox(e)}
                                            src="/styles/img/icon/times-solid.svg" alt="exit Icon"
                                            className="chatbox_icon"
                                            style={{ cursor: 'pointer' }} /> 
                                    </div>
                                </div>
                                <div id="bm-group-chatbox-body" className="chatbox_body chatbox_scrollbar" style={{height: '380px'}}>


                                    {this.state.comments.map((comment, index) => {
                                        return (
                                            <div className="chatbox_1 lighter" key={index}>
                                                <strong className={comment.bm_name === null ? 'fa-pull-left' : 'fa-pull-right'}>{comment.bm_name }</strong><br />
                                                {
                                                    comment.comment !== null &&
                                                    <p>{comment.comment}</p>
                                                }

                                               
                                                <br />
                                                <span className="time-right">{comment.created}</span>
                                            </div>
                                        );
                                    })
                                    }

                                </div>


                                <div className="messages-box">
                                    <form id="bm-group-comment-form" onSubmit={(e) => this.submitComment(e)}>
                                        <input onChange={(e) => this.setComment(e)}
                                            type="text" name="comment" className="write_messages" placeholder={this.props.bmModeratorChatTypePlaceholder}/>
                                        <img onClick={(e) => this.submitComment(e)}
                                            src="/styles/img/icon/angle-right-solid.svg"
                                            alt="send"
                                            className="send-icon"
                                            style={{ cursor: 'pointer' }} />
                                    </form>
                                </div>


                            </div>
                        </div>
                        :
                        <button onClick={(e) => this.openChatBox(e)} className={`${localStorage.BmBoderatorChat === '1' ?"chat-popup-bm-button chat-popup-button bm_mod_chat":"chat-popup-button bm_mod_chat" }`}>
                            {
                                localStorage.bmGroupChatNotification === "true" &&
                                <span className="notify_bm_chat"><i className="fa fa-bell" /></span>
                            }
                            <img
                                src="/styles/img/icon/comment-dots-solid.svg" alt="img" 
                                className={`chat-pop-up-icon`} /> {this.props.bmGroupChatHeading}
                        </button>
                }
            </div>
        );
    }
}

export default FloatingChatBoxForBMGroup;