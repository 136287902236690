import React from 'react';
import VoteButton from './VoteButton';
// import ProposedSeconded from "./ProposedSeconded";
import SubAgenda from "./SubAgenda";

class PhysicalAgendaVote extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            agendas: [],
            disabledAgenda: [],
            // enableVoteButton: false
        }
        this.key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj';

        // sultana
        this.showproposed=true;
        // sultana
    }


    componentDidMount = () => {
        this.setState({agendas: this.props.eventAgenda});
    }

    changeAgendaStatus = (value) => {
        this.state.disabledAgenda.push(value);
    }

    render() {
        return (
            <div>
                {this.state.agendas.map((agenda, index) => {

                    let total_share = localStorage.total_share*1;
                    let favor = agenda.favour ? agenda.favour*1 : 0;
                    let against = agenda.against ? agenda.against*1 : 0;

                    let vote_percentage_favor = 0;
                    let vote_percentage_against = 0;

                    if(localStorage.votingPercentageLogic === '1'){

                        if(total_share > 0 && favor > 0){
                            vote_percentage_favor = Math.round((favor*100/total_share) * 100) / 100;
                        }
    
                        if(total_share > 0 && against > 0){
                            vote_percentage_against = Math.round((against*100/total_share) * 100) / 100;
                        }

                    }

                    if(localStorage.votingPercentageLogic === '2'){

                        let voted_shares = favor + against;

                        if(voted_shares > 0 && favor > 0){
                            vote_percentage_favor = Math.round((favor*100/voted_shares) * 100) / 100;
                        }
    
                        if(voted_shares > 0 && against > 0){
                            vote_percentage_against = Math.round((against*100/voted_shares) * 100) / 100;
                        }
                        
                    }
                    if(agenda.status === 1){
                        return (

                            <div 
                                className={`${this.state.disabledAgenda.indexOf(agenda.id) > -1 ? "vote-agenda-blog vote-agenda-blog-gray mt-2 pb-2":"vote-agenda-blog mt-2 pb-2" }`}
                                key={index}>
                                    <div className="row">

                                        <div className="col-lg-12 col-md-12 col-sm-12">

                                            <h4 style={{background:localStorage.agendaHeaderBgColor, color:localStorage.agendaHeaderTextColor, fontSize:localStorage.agendaNameFontSize, margin: '0 0 10px 0'}}>
                                                
                                                <span dangerouslySetInnerHTML={{__html: agenda.agenda_name+' '}}/>
                                            
                                                <span dangerouslySetInnerHTML={{__html: agenda.agenda_name_bn}}/>

                                            </h4>
                                            
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="row pl-3 pr-3">

                                                <div className={`${agenda.description_bn ? "col-md-4 col-sm-4 col-xs-12" : "col-md-8 col-sm-8 col-xs-12"}`}>

                                                    <p className="text-justify m-0 p-0 pb-1" style={{fontSize:localStorage.agendaDescriptionFontSizeEn}} dangerouslySetInnerHTML={{__html: agenda.description}} />

                                                </div>
                                                {agenda.description_bn && 
                                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                                        
                                                        <p className="text-justify m-0 p-0" style={{fontSize:localStorage.agendaDescriptionFontSizeBn}} dangerouslySetInnerHTML={{__html: agenda.description_bn}} />

                                                    </div>
                                                }
                                                
                                                <div className="pt-1 col-md-4 col-sm-4 col-xs-12">
                                                    {agenda.sub_agenda === 0 &&

                                                    <VoteButton 
                                                        onClick={(e) => this.changeAgendaStatus(e)}
                                                        agenda={agenda}                            
                                                        agendaFavorButton={this.props.agendaFavorButton}
                                                        agendaAgainstButton={this.props.agendaAgainstButton}
                                                        subAgendaVoteRequiredText={this.props.subAgendaVoteRequiredText}
                                                        subAgendaMaxAllowedVoteText={this.props.subAgendaMaxAllowedVoteText}
                                                        subAgendaVoteSubmitButton={this.props.subAgendaVoteSubmitButton}    
                                                        buttonType={'physical'}
                                                        agendaVoteHereButton={this.props.agendaVoteHereButton}
                                                        agendaVoteCastedButton={this.props.agendaVoteCastedButton}
                                                        vote_percentage_favor={vote_percentage_favor}
                                                        vote_percentage_against={vote_percentage_against}
                                                    />
                                                    }

                                                    {
                                                        agenda.sub_agenda === 1 &&
                                                        <SubAgenda subAgendaText={agenda.sub_agenda_text} agendaId={agenda.id} requiredVote={agenda.required_sub_agenda_vote}
                                                        // agendaVoteModalButton={this.props.agendaVoteModalButton}
                                                        subAgendaVoteRequiredText={this.props.subAgendaVoteRequiredText}
                                                        subAgendaMaxAllowedVoteText={this.props.subAgendaMaxAllowedVoteText}
                                                        subAgendaVoteSubmitButton={this.props.subAgendaVoteSubmitButton}   
                                                        buttonType={2}
                                                        />
                                                    }
                                                    <span id="modal-close-button"></span>
                                                </div>
                                            </div>                     
                                        </div>                     
                                        
                                    </div>
                            </div>
                        );
                    }
                })}
                <br></br>
            </div>
        );
    }

}

export default PhysicalAgendaVote;