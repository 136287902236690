import React, {Component} from 'react';
import axios from 'axios';
import {toast} from "react-toastify";

class SubAgenda extends Component {

    constructor(props) {
        super(props);
        this.state = {
            subAgendas: null,
            voteSubmitAllowed: false
        }
        this.subAgendaId = '';
        this.inputCount = 0;
        this.key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj';
    }

    componentDidMount() {
        
        this.setState({vote_given_sub_agenda: this.props.vote_given_sub_agenda});

        axios({
            method: 'post',
            url: `${localStorage.apiHost}/sub-agenda`,
            headers: {
                'key': this.key,
                'access-token': localStorage.token
            },
            data: {
                'program-id': localStorage.programId,
                'agenda-id': this.props.agendaId
            }
        }).then(response => {
            if (response.data.status === 200) {
                this.setState({subAgendas: response.data[0]})
            }
        }).catch((error) => {
            if(error.response){
                console.log(error.response);
            }
        });

        // axios({
        //     method: 'post',
        //     url: `${localStorage.apiHost}/check-sub-agenda-vote-status`,
        //     headers: {
        //         'key': this.key,
        //         'access-token': localStorage.token
        //     },
        //     data: {
        //         'program-id': localStorage.programId
        //     }
        // }).then(response => {

        //     console.log('response.data.vote_given_sub_agenda');
        //     console.log(response.data.vote_given_sub_agenda);
            
        //     this.setState({vote_given_sub_agenda: response.data.vote_given_sub_agenda === 0 ? false : true });

        // }).catch((error) => {
        //     if(error.response){
        //         console.log(error.response);
        //     }
        // });
    }

    handleInput = (e) => {
        // console.log("initial input count", this.inputCount);
        // console.log("initial input value", this.subAgendaId);
        if (e.target.checked) {
            this.inputCount += 1;
            if (this.inputCount > this.props.requiredVote) {
                alert(`${this.props.subAgendaMaxAllowedVoteText} ${this.props.requiredVote}`);
                e.target.checked = false;
            } else {
                this.subAgendaId += e.target.value + ',';
            }
        }
        if (!e.target.checked) {
            this.inputCount -= 1;
            this.subAgendaId = this.subAgendaId.replace(`${e.target.value},`, '');
        }
        if (this.props.requiredVote === this.inputCount) {
            this.setState({voteSubmitAllowed: true});
        }else{
            this.setState({voteSubmitAllowed: false});
        }
        // console.log("input count", this.inputCount);
        // console.log("input value", this.subAgendaId);
    }

    submitVote = (e) => {
        // eslint-disable-next-line no-restricted-globals
        // if (confirm(`${this.props.subAgendaVoteRequiredText}`)) {
            if (this.props.requiredVote === this.inputCount) {
                this.subAgendaId = this.subAgendaId.substring(0, this.subAgendaId.length - 1);
                if (localStorage.userType === "otherMember") {
                    toast.warning("Other Members are not allowed to give vote");
                } else {
                    axios({
                        method: 'post',
                        url: `${localStorage.apiHost}/sub-agenda-vote`,
                        headers: {
                            'key': this.key,
                            'access-token': localStorage.token
                        },
                        data: {
                            'program-id': localStorage.programId,
                            'sub-agenda-id': this.subAgendaId,
                            'agm-type': this.props.buttonType
                        }
                    }).then(response => {
                        if (response.data.status === 200) {
                            toast.success(response.data.message);
                        } else {
                            toast.error(response.data.message);
                        }
                    }).catch((error) => {
                        if(error.response){
                            console.log(error.response);
                        }
                    });
                }
            }
        // }
        // document.querySelector("#modal-close-button").click();
        e.preventDefault();
    }

    render() {
        // console.log("render agenda");
        return (
            <div className="model-blog pl-10 pr-10 mb-2 col-lg-12 col-md-12 col-sm-12">
                <p className="p-2">
                    {this.props.subAgendaText}
                </p>

                <form id="sub-agenda-vote-form" onSubmit={(e)=>e.stopPropagation()} className="pl-2 pr-2">
                <table className="table p-2">
                    <tbody className="text-center">
                        {this.state.subAgendas !== null && this.state.subAgendas.map((subAgenda, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <p className="center p-0 m-0">{subAgenda.serial}</p>
                                    </td>
                                    <td className="">
                                        <div className="form-group text-left p-0 m-0">
                                            <label className="form-check-label d-block p-0 m-0">
                                                <p className="p-0 m-0">{subAgenda.candidate}</p>
                                            </label>
                                        </div>
                                    </td>

                                    <td>
                                        <p className="center p-0 m-0">
                                            <input
                                                disabled={this.props.vote_given_sub_agenda}
                                                onChange={(e) => this.handleInput(e)}
                                                id={`subagenda-${subAgenda.candidate}`}
                                                type="checkbox" name="voteId" value={subAgenda.id}/>
                                        </p>
                                    </td>
                                </tr>
                            );
                        })}

                  </tbody>
                </table>
                <p className="p-2">

                    {this.props.vote_given_sub_agenda ? 

                        <input className="btn btn-success"
                            disabled={true}
                            type="button"
                            style={{border:0, background:localStorage.SubAgendaSubmitButtonColor,color:localStorage.SubAgendaSubmitButtonTextColor}}
                            value={'Vote Submitted'}/>

                    :      

                        <input className="btn btn-success"
                            onClick={(e) => this.submitVote(e)}
                            id="sub_agenda_submit_button"
                            disabled={!this.state.voteSubmitAllowed || (localStorage.userType === "otherMember")}
                            type="button"
                            style={{border:0, background:localStorage.SubAgendaSubmitButtonColor,color:localStorage.SubAgendaSubmitButtonTextColor}}
                            value={this.props.subAgendaVoteSubmitButton}/>

                        

                    }
                </p>
                </form>
            </div>

            // <div>
            //     <div className="row">
            //         <div className="col-md-12">
            //             <div className="sl_mdl_content">
            //                 <p className="my-5 text-center text-danger font-weight-bold">
            //                     নিম্নোক্ত ১১ জন্য পরিচালকগনের মধ্য হতে কেবলমাত্র নয়জনকে নির্বাচিত করুন ( টিক চিহ্ন
            //                     দিয়ে নির্বাচিত করুন) । নয়জনের কম বা বেশী ভোট গ্রহণযোগ্য নয়।
            //                 </p>
            //             </div>


            //             <table className="table table-bordered">
            //                 <tbody>

            //                 {this.state.subAgendas !== null && this.state.subAgendas.map(subAgenda => {
            //                     return (
            //                         <tr key={subAgenda.serial}>
            //                             <td>
            //                                 <p className="center pt20">{subAgenda.serial}</p>
            //                             </td>
            //                             <td className="">
            //                                 <div className="form-group text-left ">
            //                                     <label className="form-check-label d-block">
            //                                         <p className="pt20">{subAgenda.candidate}</p>
            //                                     </label>

            //                                 </div>
            //                             </td>

            //                             <td>
            //                                 <p className="center pt20">
            //                                     <input
            //                                         onChange={(e) => this.handleInput(e)}
            //                                         id={`subagenda-${subAgenda.candidate}`}
            //                                         type="checkbox" name="voteId" value={subAgenda.id}/>
            //                                 </p>
            //                             </td>
            //                         </tr>
            //                     );
            //                 })}

            //                 </tbody>
            //                 <tfoot>
            //                 <tr>
            //                     <td colSpan="3">
            //                         <div className="form-group">
            //                             <input className="btn btn-success"
            //                                    onClick={(e) => this.submitVote(e)}
            //                                    id="sub_agenda_submit_button"
            //                                    disabled={!this.state.voteSubmitAllowed}
            //                                    type="submit"
            //                                    style={{background:localStorage.SubAgendaSubmitButtonColor,color:localStorage.SubAgendaSubmitButtonTextColor}}
            //                                    value="Submit Vote"/>
            //                         </div>
            //                     </td>
            //                 </tr>
            //                 </tfoot>
            //             </table>

            //         </div>
            //     </div>
            // </div>
        );
    }
}

export default SubAgenda;