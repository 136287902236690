// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.d-none {
    display: none;
}

@media print {
    .d-print-block {
        display: block !important;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/PrintComponent.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".d-none {\n    display: none;\n}\n\n@media print {\n    .d-print-block {\n        display: block !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
