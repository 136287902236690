import React from 'react';
import IndividualLogin from './login/IndividualLogin';
import CompanyLogin from './login/CompanyLogin';
import ProxyLogin from './login/ProxyLogin';
import BoardMemberLogin from './login/BoardMemberLogin';
import OtherLogin from './login/OtherLogin';
import OTPLogin from './login/OTPLogin';
// import UserRegister from './login/UserRegister';
import Otp from './Otp';
import '../Login.css';

class ShareHolderLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeForm: 'nav-individual-tab',
            otpPageLoad: false,
        }

    }

    clickTab = (event) => {
        this.setState({activeForm: event.target.id});
        event.preventDefault();
    }

    changeLoginState = (value) => {
        if (value === "true") {
            this.props.onClick(value);
        } else {
            this.setState({otpPageLoad: true});

        }
    }

    render() {
        const loginElement = <div className="login-section mt-2 mb-2 container">
            <div className="col-md-12 no-sm-h-padding ">
                <div className="row ">

                    <div className="col-md-7  mb-2">
                        <div className="card login-card">
                            <div className="card-header login-card-header" style={{background:localStorage.loginTabHeaderBgColor, color:localStorage.loginTabHeaderTextColor}}>
                                <h3 className="card-title text-center" style={{fontSize:localStorage.loginAsHeadingFontSize}}>
                                    {localStorage.loginAsShareholderHeading === "null" ? 'Login as Shareholder' : localStorage.loginAsShareholderHeading}
                                </h3>
                            </div>
                            <div className="card-body">
                                <nav>
                                    <div className="nav nav-tabs nav-fill login-nav-tabs" id="nav-tab" role="tablist">
                                        <a className={`nav-item nav-link ${this.state.activeForm === 'nav-individual-tab' ? 'nav-active' : ''}`}
                                        id="nav-individual-tab" onClick={this.clickTab} data-toggle="tab"
                                        style={{
                                            background:`${this.state.activeForm === 'nav-individual-tab' ? localStorage.loginActiveTabColor: localStorage.loginButtonTabColor}`,
                                            color:`${this.state.activeForm === 'nav-individual-tab' ? localStorage.loginActiveTabTextColor : localStorage.loginButtonTabTextColor}`
                                        }}
                                        role="tab" href="/" aria-controls="nav-individual"
                                        aria-selected="true">{localStorage.individualTab === "null" ? 'Individual' : localStorage.individualTab}</a>

                                        <a className={`nav-item nav-link ${this.state.activeForm === 'nav-company-tab' ? 'nav-active' : ''}`}
                                        id="nav-company-tab" onClick={this.clickTab} data-toggle="tab"
                                        style={{
                                            background:`${this.state.activeForm === 'nav-company-tab' ? localStorage.loginActiveTabColor: localStorage.loginButtonTabColor}`,
                                            color:`${this.state.activeForm === 'nav-company-tab' ? localStorage.loginActiveTabTextColor : localStorage.loginButtonTabTextColor}`
                                        }}
                                        href="/" role="tab" aria-controls="nav-company" aria-selected="false">
                                            {localStorage.companyTab === "null" ? 'Company' : localStorage.companyTab}</a>

                                        <a className={`nav-item nav-link ${this.state.activeForm === 'nav-proxy-tab' ? 'nav-active' : ''}`}
                                        id="nav-proxy-tab" onClick={this.clickTab} data-toggle="tab"
                                        style={{
                                            background:`${this.state.activeForm === 'nav-proxy-tab' ? localStorage.loginActiveTabColor : localStorage.loginButtonTabColor}`,
                                            color:`${this.state.activeForm === 'nav-proxy-tab' ? localStorage.loginActiveTabTextColor : localStorage.loginButtonTabTextColor}`
                                        }}
                                        href="/" role="tab" aria-controls="nav-proxy" aria-selected="false">
                                            {localStorage.proxyTab === "null" ? 'Proxy' : localStorage.proxyTab}</a>


                                        {localStorage.physical_otp_login === '1' &&
                                            <a className={`nav-item nav-link ${this.state.activeForm === 'nav-otp' ? 'nav-active' : ''}`}
                                            id="nav-otp-tab" onClick={this.clickTab} data-toggle="tab"
                                            style={{
                                                background:`${this.state.activeForm === 'nav-otp-tab' ? localStorage.loginActiveTabColor : localStorage.loginButtonTabColor}`,
                                                color:`${this.state.activeForm === 'nav-otp-tab' ? localStorage.loginActiveTabTextColor : localStorage.loginButtonTabTextColor}`
                                            }}
                                            href="/" role="tab" aria-controls="nav-otp" aria-selected="false">
                                                OTP</a>
                                        }                               

                                    </div>
                                </nav>

                                <div className="login-tab-content tab-content py-3 px-3 px-sm-0 border border-top-0" id="nav-tabContent">

                                    {/*tab content 1*/}
                                    {this.state.activeForm === 'nav-individual-tab' &&
                                    <IndividualLogin otp={this.props.otp} agmType={this.props.agmType} onClick={this.changeLoginState}/>
                                    }

                                    {/*tab content 2*/}
                                    {this.state.activeForm === 'nav-company-tab' &&
                                    <CompanyLogin otp={this.props.otp} agmType={this.props.agmType} onClick={this.changeLoginState}/>
                                    }

                                    {/*tab content 3*/}
                                    {this.state.activeForm === 'nav-proxy-tab' &&
                                    <ProxyLogin otp={this.props.otp} agmType={this.props.agmType} onClick={this.changeLoginState}/>
                                    }

                                    {/*tab content 3*/}
                                    {this.state.activeForm === 'nav-otp-tab' &&
                                    <OTPLogin otp={this.props.otp} agmType={this.props.agmType} onClick={this.changeLoginState}/>
                                    }
                                    
                                    {localStorage.registration_module === '1' &&
                                    <div className="col-sm-12">                                        
                                        <div className="col-sm-12 text-right">                                        
                                            <button type="button" className="mt-1 mr-2 btn btn-xs btn-info login-btn" data-toggle="modal" data-target="#register">
                                                Pre-Registration
                                            </button>
                                        </div>                                        
                                    </div>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-5">
                        <BoardMemberLogin agmType={this.props.agmType} otp={this.props.otp} onClick={this.changeLoginState}/>
                        <OtherLogin agmType={this.props.agmType} otp={this.props.otp} onClick={this.changeLoginState}/>

                        {localStorage.showModeratorLinkInLogin === '1' && 
                        <div className="card login-card">
                            <div className="card-header login-card-header" style={{background:localStorage.loginTabHeaderBgColor, color:localStorage.loginTabHeaderTextColor}}>
                                <h3 className="card-title text-center" style={{fontSize:localStorage.loginAsHeadingFontSize}}>
                                    {localStorage.moderatorLoginHeading === "null" ? 'Moderator Login' : localStorage.moderatorLoginHeading}
                                </h3>
                            </div>
                            <div className="card-body pt-2 pb-3">
                                <div className="text-center">
                                    <a href={localStorage.moderatorLink} target='_blank' className="mt-1 btn btn-login" style={{background:localStorage.loginButtonBgColor,color:localStorage.loginButtonTextColor}}>
                                        {localStorage.moderatorLoginButton === "null" ? 'Click here to login' : localStorage.moderatorLoginButton}
                                    </a>
                                </div>
                            </div>
                        </div>
                        }
                    </div>

                </div>
            </div>
        </div>
        const otpElement = <Otp onClick={this.changeLoginState}/>
        return (
            (this.state.otpPageLoad) ?
                otpElement
                : loginElement
        );
    }
}

export default ShareHolderLogin;