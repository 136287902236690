import React from 'react'
import axios from 'axios';
import {toast} from "react-toastify";
import Notiflix from 'notiflix';
import "notiflix/dist/notiflix-3.2.6.min.css";

class VoteButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isReason: false,
            reason: '',
            disabledButton: -1,
            proxylist:[],
            votedproxy:[],
            favorproxy:[],
            againstproxy:[],
            check_all: 0,
            companylist:[],
            votedcompany:[],
            favorcompany:[],
            againstcompany:[],
        }
        this.key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj';
        this.urlParams = new URLSearchParams(window.location.search);
    }

    componentDidMount = () => {


        if(localStorage.userType === "Shareholder" && localStorage.isProxy === "Proxy" && localStorage.multiple_proxy === '1'){
            axios({
                method: 'post',
                url: `${localStorage.apiHost}/get-proxy-list`,
                headers: {
                    'key': this.key,
                    'access-token': localStorage.token
                },
                data: {
                    'program-id': localStorage.programId
                }
            }).then(response => {

                if (response.data.status === 200) {

                    // response.data.proxy.forEach(proxy => {
                    //     this.state.proxylist.push(proxy);
                    // })

                    this.setState({proxylist: response.data.proxy});
                }

            }).catch((error) => {
                if(error.response){
                    console.log(error.response);
                }
            });

            axios({
                method: 'post',
                url: localStorage.apiHost+"/check-proxy-votes",
                headers: {
                    'key': this.key,
                    'access-token': localStorage.token
                },
                data: {
                    'program-id': localStorage.programId,
                    'agenda-id': this.props.agenda.id
                }
            }).then(response => {

                // console.log(response);
                
                if (response.data.status === 200) {

                    this.setState({votedproxy: response.data.proxy});

                } else {

                    toast.error(response.data.message);

                }
                
            }).catch(err => {
                toast.error("Something wrong please try again");
            });
        }


        if(localStorage.userType === "Shareholder" && localStorage.isMultipleCompany === "isMultipleCompany" && localStorage.gp_multiple_company === '1'){
            axios({
                method: 'post',
                url: `${localStorage.apiHost}/get-company-list`,
                headers: {
                    'key': this.key,
                    'access-token': localStorage.token
                },
                data: {
                    'program-id': localStorage.programId
                }
            }).then(response => {

                // console.log(response);

                if (response.data.status === 200) {

                    this.setState({companylist: response.data.company});
                }

            }).catch((error) => {
                if(error.response){
                    console.log(error.response);
                }
            });

            axios({
                method: 'post',
                url: localStorage.apiHost+"/check-company-votes",
                headers: {
                    'key': this.key,
                    'access-token': localStorage.token
                },
                data: {
                    'program-id': localStorage.programId,
                    'agenda-id': this.props.agenda.id
                }
            }).then(response => {

                // console.log(response);
                
                if (response.data.status === 200) {

                    this.setState({votedcompany: response.data.company});

                } else {

                    toast.error(response.data.message);

                }
                
            }).catch(err => {
                toast.error("Something wrong please try again");
            });
        }


        Notiflix.Confirm.init({
            width: '340px',
          
            titleColor: '#000',          
            messageColor: '#000',

            okButtonColor: localStorage.voteResultFavourHeaderTextColor,
            okButtonBackground: localStorage.voteResultFavourHeaderBgColor,

            cancelButtonColor: localStorage.voteResultAgainstHeaderTextColor,
            cancelButtonBackground: localStorage.voteResultAgainstHeaderBgColor,

            buttonsFontSize: '13px',
        });
        
        axios({
            method: 'post',
            url: localStorage.apiHost+"/check-voted-agenda",
            headers: {
                'key': this.key,
                'access-token': localStorage.token
            },
            data: {
                'program-id': localStorage.programId,
                'agenda-id': this.props.agenda.id
            }

        }).then(response => {
            
            // console.log(response.data);

            if (response.data.status === 200) {

                this.setState({
                    disabledButton: this.props.agenda.id
                });

            }

        }).catch((error) => {
            if(error.response){
                console.log(error.response);
            }
        });

    }

    checkVote = (e, agenda) => {

        if(document.querySelector("input[name=input_vote_"+agenda.id+"]:checked")){

            let voteType = document.querySelector("input[name=input_vote_"+agenda.id+"]:checked").value * 1;
            
            if(voteType === 2 && localStorage.againstVoteReason === "1"){

                this.enableReasonForm(e);

            }else{

                this.saveVote(e, agenda);

            }

        }else{

            toast.error("Please select an option to vote.");

        }

        e.preventDefault();
    }

    saveVote = (event, agenda) => {

        if (localStorage.userType === "otherMember") {
            toast.warning("Other Members are not allowed to give vote");
        } else {

            document.getElementById('closeConfirm').style.display = "block";

            Notiflix.Confirm.show(
                agenda.agenda_name,
                agenda.description,
                this.props.agendaFavorButton,
                this.props.agendaAgainstButton,
                function () {

                    document.getElementById('closeConfirm').style.display = "none";

                    if(localStorage.userType === "Shareholder" && localStorage.isProxy === "Proxy" && localStorage.proxy_tp === '1'){

                        this.setState({
                            favorproxy: [],
                            againstproxy: []
                        });

                        axios({
                            method: 'post',
                            url: localStorage.apiHost+"/check-proxy-votes",
                            headers: {
                                'key': this.key,
                                'access-token': localStorage.token
                            },
                            data: {
                                'program-id': localStorage.programId,
                                'agenda-id': agenda.id
                            }
                        }).then(response => {

                            // console.log(response);
                            
                            if (response.data.status === 200) {
            
                                this.setState({votedproxy: response.data.proxy});
            
                            } else {
    
                                toast.error(response.data.message);
    
                            }
                            
                        }).catch(err => {
                            toast.error("Something wrong please try again");
                        });

                        document.querySelectorAll('input[type=checkbox]').forEach( el => el.checked = false );

                        let voteModal = window.$('#chose_sh_modal_favor_'+agenda.id);
                        voteModal.modal('show');

                    }
                    else if(localStorage.userType === "Shareholder" && localStorage.isMultipleCompany === "isMultipleCompany" && localStorage.company_tp === '1'){

                        this.setState({
                            favorcompany: [],
                            againstcompany: []
                        });

                        axios({
                            method: 'post',
                            url: localStorage.apiHost+"/check-company-votes",
                            headers: {
                                'key': this.key,
                                'access-token': localStorage.token
                            },
                            data: {
                                'program-id': localStorage.programId,
                                'agenda-id': agenda.id
                            }
                        }).then(response => {

                            // console.log(response);
                            
                            if (response.data.status === 200) {
            
                                this.setState({votedcompany: response.data.company});
            
                            } else {
    
                                toast.error(response.data.message);
    
                            }
                            
                        }).catch(err => {
                            toast.error("Something wrong please try again");
                        });

                        document.querySelectorAll('input[type=checkbox]').forEach( el => el.checked = false );

                        let voteModal = window.$('#gp_multi_chose_sh_modal_favor_'+agenda.id);
                        voteModal.modal('show');

                    }
                    else{

                        axios({
                            method: 'post',
                            url: localStorage.userType === "Shareholder" ? `${localStorage.apiHost}/vote`: `${localStorage.apiHost}/save-bm-agenda-vote`,
                            headers: {
                                'key': this.key,
                                'access-token': localStorage.token
                            },
                            data: {
                                'program-id': localStorage.programId,
                                'vote': 1,
                                'agenda-id': agenda.id,
                                'reason': '',
                                'agm-type': this.props.buttonType,
                            }
                        }).then(response => {
                            
                            if (response.data.status === 200) {
    
                                toast.success("Vote Successfully Saved");
            
                                this.setState({
                                    disabledButton: agenda.id
                                });
            
                                this.props.onClick(agenda.id);
            
                            } else {
    
                                toast.error(response.data.message);
    
                            }
                            
                        }).catch(err => {
                            toast.error("Something wrong please try again");
                        });

                    }

                }.bind(this),
                function () {

                    
                    document.getElementById('closeConfirm').style.display = "none";

                    if(localStorage.userType === "Shareholder" && localStorage.isProxy === "Proxy" && localStorage.proxy_tp === '1'){

                        this.setState({
                            favorproxy: [],
                            againstproxy: []
                        });

                        axios({
                            method: 'post',
                            url: localStorage.apiHost+"/check-proxy-votes",
                            headers: {
                                'key': this.key,
                                'access-token': localStorage.token
                            },
                            data: {
                                'program-id': localStorage.programId,
                                'agenda-id': agenda.id
                            }
                        }).then(response => {

                            // console.log(response);
                            
                            if (response.data.status === 200) {
            
                                this.setState({votedproxy: response.data.proxy});
            
                            } else {
    
                                toast.error(response.data.message);
    
                            }
                            
                        }).catch(err => {
                            toast.error("Something wrong please try again");
                        });

                        document.querySelectorAll('input[type=checkbox]').forEach( el => el.checked = false );

                        let voteModal = window.$('#chose_sh_modal_against_'+agenda.id);
                        voteModal.modal('show');

                    }
                    else if(localStorage.userType === "Shareholder" && localStorage.isMultipleCompany === "isMultipleCompany" && localStorage.company_tp === '1'){
                        this.setState({
                            favorcompany: [],
                            againstcompany: []
                        });

                        axios({
                            method: 'post',
                            url: localStorage.apiHost+"/check-company-votes",
                            headers: {
                                'key': this.key,
                                'access-token': localStorage.token
                            },
                            data: {
                                'program-id': localStorage.programId,
                                'agenda-id': agenda.id
                            }
                        }).then(response => {

                            // console.log(response);
                            
                            if (response.data.status === 200) {
            
                                this.setState({votedcompany: response.data.company});
            
                            } else {
    
                                toast.error(response.data.message);
    
                            }
                            
                        }).catch(err => {
                            toast.error("Something wrong please try again");
                        });

                        document.querySelectorAll('input[type=checkbox]').forEach( el => el.checked = false );

                        let voteModal = window.$('#gp_multi_chose_sh_modal_against_'+agenda.id);
                        voteModal.modal('show');
                    }
                    else{

                        if (localStorage.againstVoteReason === "1") {

                            let reason = prompt("Please enter reason:", "");

                            if (reason === null || reason === '') {
                                toast.error('Please enter reason');
                            }else{
                                axios({
                                    method: 'post',
                                    url: localStorage.userType === "Shareholder" ? `${localStorage.apiHost}/vote`: `${localStorage.apiHost}/save-bm-agenda-vote`,
                                    headers: {
                                        'key': this.key,
                                        'access-token': localStorage.token
                                    },
                                    data: {
                                        'program-id': localStorage.programId,
                                        'vote': 2,
                                        'agenda-id': agenda.id,
                                        'reason': reason,
                                        'agm-type': this.props.buttonType,
                                    }
                                }).then(response => {
                                    // console.log(response.data);
                                    if (response.data.status === 200) {
                                        toast.success("Vote Successfully Saved");
                    
                                        this.setState({
                                            disabledButton: agenda.id
                                        });
                    
                                        this.props.onClick(agenda.id);
                    
                                    } else {
                                        toast.error(response.data.message);
                                    }
                                }).catch(err => {
                                    toast.error("Something wrong please try again");
                                });
                            }
                        }else{
                            axios({
                                method: 'post',
                                url: localStorage.userType === "Shareholder" ? `${localStorage.apiHost}/vote`: `${localStorage.apiHost}/save-bm-agenda-vote`,
                                headers: {
                                    'key': this.key,
                                    'access-token': localStorage.token
                                },
                                data: {
                                    'program-id': localStorage.programId,
                                    'vote': 2,
                                    'agenda-id': agenda.id,
                                    'reason': '',
                                    'agm-type': this.props.buttonType,
                                }
                            }).then(response => {
                                
                                if (response.data.status === 200) {
        
                                    toast.success("Vote Successfully Saved");
                
                                    this.setState({
                                        disabledButton: agenda.id
                                    });
                
                                    this.props.onClick(agenda.id);
                
                                } else {
        
                                    toast.error(response.data.message);
        
                                }
                                
                            }).catch(err => {
                                toast.error("Something wrong please try again");
                            });
                        }
                    }

                }.bind(this)
            );

            
        }
        // if(document.getElementById("modal-close-button")){
        //     document.getElementById("modal-close-button").click();
        // }
        this.setState({isReason: false, reason: ''});
        
        event.preventDefault();
    }

    enableReasonForm = (e) => {
        this.setState({isReason: true});
        e.preventDefault();
    }

    disableReasonForm = (e) => {
        this.setState({isReason: false, reason: ''});
        e.preventDefault();
    }

    handleChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        this.setState({
            [name]: value
        });
        event.preventDefault();
    }

    handleCheckboxChangeFavor = (event) => {

        const target = event.target;
        var value = target.value;

        if(target.checked){

            // this.state.favorproxy[value] = value;
            this.state.favorproxy.push(value);
            this.state.favorcompany.push(value);
            this.setState({
                favorproxy: this.state.favorproxy,
                favorcompany: this.state.favorcompany
            });

        }else{

            const index = this.state.favorproxy.indexOf(value);

            if (index > -1) {
                this.state.favorproxy.splice(index, 1);
            }

            const index2 = this.state.favorcompany.indexOf(value);

            if (index2 > -1) {
                this.state.favorcompany.splice(index, 1);
            }

            this.setState({
                favorproxy: this.state.favorproxy,
                favorcompany: this.state.favorcompany
            });

        }
    }

    handleCheckboxChangeAgainst = (event) => {

        const target = event.target;
        var value = target.value;

        if(target.checked){

            this.state.againstproxy.push(value);
            this.state.againstcompany.push(value);

            this.setState({
                againstproxy: this.state.againstproxy,
                againstcompany: this.state.againstcompany
            });

        }else{

            const index = this.state.againstproxy.indexOf(value);
            
            if (index > -1) {
                this.state.againstproxy.splice(index, 1);
            }

            const index2 = this.state.againstcompany.indexOf(value);

            if (index2 > -1) {
                this.state.againstcompany.splice(index, 1);
            }
            
            this.setState({
                againstproxy: this.state.againstproxy,
                againstcompany: this.state.againstcompany
            });

        }
    }

    checkAllFavor = (event) => {

        const target = event.target;
        var value = target.value;

        var elements = document.getElementsByClassName("favorCheckboxes");        

        if(target.checked){

            for (var i=0; i<elements.length; i++) {

                if(!elements[i].checked){
    
                    elements[i].click();
    
                }
            }

        }else{

            for (var i=0; i<elements.length; i++) {

                if(elements[i].checked){
    
                    elements[i].click();
    
                }
            }
            
            this.setState({favorproxy: []});
        }

    }

    checkAllAgainst = (event) => {

        const target = event.target;
        var value = target.value;

        var elements = document.getElementsByClassName("againstCheckboxes");        

        if(target.checked){

            for (var i=0; i<elements.length; i++) {

                if(!elements[i].checked){
    
                    elements[i].click();
    
                }
            }

        }else{

            for (var i=0; i<elements.length; i++) {

                if(elements[i].checked){
    
                    elements[i].click();
    
                }
            }
            
            this.setState({againstproxy: []});
        }

    }

    closeModal = (e) => {
        let voteModal = window.$('.modal');
        voteModal.modal('hide');
        e.preventDefault();
    }

    saveProxyVote = (event, agenda, votetype) => {

        let proxiesWillVote = ''; 

        if(votetype == 1) {
            proxiesWillVote = this.state.favorproxy;
        }else{
            proxiesWillVote = this.state.againstproxy;
        }

        if(proxiesWillVote.length > 0) {
            axios({
                method: 'post',
                url: `${localStorage.apiHost}/proxy-vote`,
                headers: {
                    'key': this.key,
                    'access-token': localStorage.token
                },
                data: {
                    'program-id': localStorage.programId,
                    'vote': votetype,
                    'agenda-id': agenda.id,
                    'reason': '',
                    'agm-type': this.props.buttonType,
                    'proxies': proxiesWillVote,
                }
            }).then(response => {

                // console.log(response.data);
                
                if (response.data.status === 200) {
    
                    toast.success("Vote Successfully Saved");

                    axios({
                        method: 'post',
                        url: localStorage.apiHost+"/check-proxy-votes",
                        headers: {
                            'key': this.key,
                            'access-token': localStorage.token
                        },
                        data: {
                            'program-id': localStorage.programId,
                            'agenda-id': agenda.id
                        }
                    }).then(response => {
        
                        // console.log(response);
                        
                        if (response.data.status === 200) {
        
                            this.setState({votedproxy: response.data.proxy});
        
                        } else {
        
                            toast.error(response.data.message);
        
                        }
                        
                    }).catch(err => {
                        toast.error("Something wrong please try again");
                    });
    
                } else {
    
                    toast.error(response.data.message);
    
                }

                let voteModal = window.$('.modal');
                voteModal.modal('hide');
                
            }).catch(err => {
                toast.error("Something wrong please try again");
            });

        }else{
            toast.error("Please select shareholder/s to vote");
        }
        
        
        event.preventDefault();
    }

    saveCompanyVote = (event, agenda, votetype) => {

        let companiesWillVote = ''; 

        if(votetype == 1) {
            companiesWillVote = this.state.favorcompany;
        }else{
            companiesWillVote = this.state.againstcompany;
        }

        if(companiesWillVote.length > 0) {
            axios({
                method: 'post',
                url: `${localStorage.apiHost}/company-vote`,
                headers: {
                    'key': this.key,
                    'access-token': localStorage.token
                },
                data: {
                    'program-id': localStorage.programId,
                    'vote': votetype,
                    'agenda-id': agenda.id,
                    'reason': '',
                    'agm-type': this.props.buttonType,
                    'companies': companiesWillVote,
                }
            }).then(response => {

                // console.log(response.data);
                
                if (response.data.status === 200) {
    
                    toast.success("Vote Successfully Saved");

                    axios({
                        method: 'post',
                        url: localStorage.apiHost+"/check-company-votes",
                        headers: {
                            'key': this.key,
                            'access-token': localStorage.token
                        },
                        data: {
                            'program-id': localStorage.programId,
                            'agenda-id': agenda.id
                        }
                    }).then(response => {
        
                        // console.log(response);
                        
                        if (response.data.status === 200) {
        
                            this.setState({votedcompany: response.data.company});
        
                        } else {
        
                            toast.error(response.data.message);
        
                        }
                        
                    }).catch(err => {
                        toast.error("Something wrong please try again");
                    });
    
                } else {
    
                    toast.error(response.data.message);
    
                }

                let voteModal = window.$('.modal');
                voteModal.modal('hide');
                
            }).catch(err => {
                toast.error("Something wrong please try again");
            });

        }else{
            toast.error("Please select shareholder/s to vote");
        }
        
        
        event.preventDefault();
    }

    render() {

        const regex = /(<([^>]+)>)/ig;

        let allowed_shareholders = [];
        let vote_allowed = false;

        if(this.props.agenda.allowed_shareholders && localStorage.userType === "Shareholder"){

            allowed_shareholders = this.props.agenda.allowed_shareholders.split(",");

            if(this.props.agenda.is_allowed === 1){

                if(allowed_shareholders.includes(localStorage.loginID)){
                    vote_allowed = true;
                }else{
                    vote_allowed = false;
                }

            }else{

                if(allowed_shareholders.includes(localStorage.loginID)){
                    vote_allowed = false;
                }else{
                    vote_allowed = true;
                }
                
            }

            

        }else{
            vote_allowed = true;
        }

        return (
            <div className="col-lg-12 text-center vote-given-blog-container">

                {this.props.agenda.vote_status === 1 || this.props.agenda.vote_result_status  === 1 ?

                <div className="vote-given-blog">

                    {this.props.buttonType === 'virtual' ?

                        <div className="vote-buttons-container row m-0">

                            {((this.props.agenda.vote_result_status === 1 && localStorage.userType === 'Shareholder') || (this.props.agenda.vote_result_status_bm === 1 && localStorage.userType === 'boardMember') || (this.props.agenda.vote_result_status_obm === 1 && localStorage.userType === 'otherMember')) &&

                            <div className="col-md-6 vote-selection-div">

                                <div style={{background:localStorage.voteResultFavourHeaderBgColor, color:localStorage.voteResultFavourHeaderTextColor}} className="vote-selection-container">

                                    <div>
                                        <div className="input-radio-label">
                                            {this.props.agendaFavorButton}

                                            {localStorage.vote_percentage_module === '1' && 
                                                <samp style={{background:localStorage.voteResultFavourNumberBgColor, color:localStorage.voteResultFavourNumberTextColor}} className="vote-percentage-value"> 
                                                    {this.props.vote_percentage_favor}%
                                                </samp>
                                            }
                                            
                                        </div>
                                        
                                        {localStorage.physical_voting === '1' ?

                                            <>
                                            {localStorage.show_total_voters_count === '1' ?
                                                <table className='table table-bordered vote_result_table' style={{color:localStorage.voteResultFavourHeaderTextColor}}>
                                                    <thead>
                                                    <tr>
                                                        <td>Type</td>
                                                        <td>Voters</td>
                                                        <td>Votes</td>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>Online</td>
                                                        <td>{this.props.agenda.favour_online_count ? this.props.agenda.favour_online_count.toLocaleString('en-IN') : 0}</td>
                                                        <td>{this.props.agenda.favour_online ? this.props.agenda.favour_online.toLocaleString('en-IN') : 0}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Physical</td>
                                                        <td>{this.props.agenda.favour_physical_count ? this.props.agenda.favour_physical_count.toLocaleString('en-IN') : 0}</td>
                                                        <td>{this.props.agenda.favour_physical ? this.props.agenda.favour_physical.toLocaleString('en-IN') : 0}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total</td>
                                                        <td>{this.props.agenda.favour_count ? this.props.agenda.favour_count.toLocaleString('en-IN') : 0}</td>
                                                        <td>{this.props.agenda.favour ? this.props.agenda.favour.toLocaleString('en-IN') : 0}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            :
                                                <span style={{color:localStorage.voteResultFavourNumberBgColor}} className="vote-result-value vote-result-value-pysical">
                                                    Online &nbsp;&nbsp;&nbsp;: {this.props.agenda.favour_online ? this.props.agenda.favour_online.toLocaleString('en-IN') : 0} <br></br>
                                                    Physical : {this.props.agenda.favour_physical ? this.props.agenda.favour_physical.toLocaleString('en-IN') : 0} <br></br>
                                                    Total &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {this.props.agenda.favour ? this.props.agenda.favour.toLocaleString('en-IN') : 0}
                                                </span>
                                            }
                                            </>

                                        
                                        :

                                            <div style={{color:localStorage.voteResultFavourNumberBgColor}} className="vote-result-value">

                                                {this.props.agenda.favour ? this.props.agenda.favour.toLocaleString('en-IN') : 0}

                                                {localStorage.show_total_voters_count === '1' && 
                                                    <>
                                                        <br></br>
                                                        {this.props.agenda.favour_count ? this.props.agenda.favour_count.toLocaleString('en-IN') : 0} Voter/s
                                                    </>
                                                }
                                                
                                            </div>

                                        }
                                            
                                    </div>
                                </div>
                            </div>
                            
                            }

                            {((this.props.agenda.vote_result_status === 1 && localStorage.userType === 'Shareholder') || (this.props.agenda.vote_result_status_bm === 1 && localStorage.userType === 'boardMember') || (this.props.agenda.vote_result_status_obm === 1 && localStorage.userType === 'otherMember')) &&

                            <div className="col-md-6 vote-selection-div">
                                <div style={{background:localStorage.voteResultAgainstHeaderBgColor,color:localStorage.voteResultAgainstHeaderTextColor}} className="vote-selection-container vote-selection-container-against">

                                    <div>
                                        <div className="input-radio-label">
                                            {this.props.agendaAgainstButton}
                                            {localStorage.vote_percentage_module === '1' &&
                                                <samp style={{background:localStorage.voteResultAgainstNumberBgColor, color:localStorage.voteResultAgainstNumberTextColor}} className="vote-percentage-value vote-percentage-value-against"> 
                                                    {this.props.vote_percentage_against}%
                                                </samp>
                                            }
                                        </div>

                                        {localStorage.physical_voting === '1' ?

                                            <>
                                            {localStorage.show_total_voters_count === '1' ?

                                                <table className='table table-bordered vote_result_table' style={{color:localStorage.voteResultFavourHeaderTextColor}}>
                                                    <thead>
                                                    <tr>
                                                        <td>Type</td>
                                                        <td>Voters</td>
                                                        <td>Votes</td>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>Online</td>
                                                        <td>{this.props.agenda.against_online_count ? this.props.agenda.against_online_count.toLocaleString('en-IN') : 0}</td>
                                                        <td>{this.props.agenda.against_online ? this.props.agenda.against_online.toLocaleString('en-IN') : 0}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Physical</td>
                                                        <td>{this.props.agenda.against_physical_count ? this.props.agenda.against_physical_count.toLocaleString('en-IN') : 0}</td>
                                                        <td>{this.props.agenda.against_physical ? this.props.agenda.against_physical.toLocaleString('en-IN') : 0}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total</td>
                                                        <td>{this.props.agenda.against_count ? this.props.agenda.against_count.toLocaleString('en-IN') : 0}</td>
                                                        <td>{this.props.agenda.against ? this.props.agenda.against.toLocaleString('en-IN') : 0}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            :
                                                <div style={{color:localStorage.voteResultAgainstNumberBgColor}} className="vote-result-value vote-result-value-pysical vote-result-value-against">
                                                    Online &nbsp;&nbsp;&nbsp;: {this.props.agenda.against_online ? this.props.agenda.against_online.toLocaleString('en-IN') : 0} <br></br>
                                                    Physical : {this.props.agenda.against_physical ? this.props.agenda.against_physical.toLocaleString('en-IN') : 0} <br></br>
                                                    Total &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {this.props.agenda.against ? this.props.agenda.against.toLocaleString('en-IN') : 0}
                                                </div>
                                            }
                                            </>

                                        
                                        
                                        :

                                        <div style={{color:localStorage.voteResultAgainstNumberBgColor}} className="vote-result-value vote-result-value-against">
                                            {this.props.agenda.against ? this.props.agenda.against.toLocaleString('en-IN') : 0}

                                            {localStorage.show_total_voters_count === '1' && 
                                                <>
                                                    <br></br>
                                                    {this.props.agenda.against_count ? this.props.agenda.against_count.toLocaleString('en-IN') : 0} Voter/s
                                                </>
                                            }
                                        </div>

                                        }
                                    </div>
                                </div>
                            </div>
                            
                            }

                            {this.props.agenda.vote_status === 1 && (localStorage.userType === "Shareholder" || localStorage.userType === "boardMember"  || (localStorage.userType === "otherMember")) ?

                                <>
                                {vote_allowed &&
                                <div className="col-md-6 vote-selection-div mt-2" style={{margin: '10px auto'}}>

                                    {localStorage.userType === "Shareholder" && localStorage.isProxy === "Proxy" && localStorage.proxy_tp === '1'
                                    
                                    ?

                                        <>
                                            {this.state.votedproxy.length === this.state.proxylist.length ?

                                                <button 
                                                style={{background: localStorage.voteCastedButtonColor, color: localStorage.voteCastedButtonTextColor, fontSize: localStorage.voteCastedButtonFontSize}}
                                                className="vote-submit-button"
                                                disabled={this.state.votedproxy.length === this.state.proxylist.length}
                                                >
                                                    <i className='fa fa-check-circle'></i> {this.props.agendaVoteCastedButton}
                                                </button>

                                                :
                                                
                                                <button 
                                                style={{background: localStorage.voteHereButtonColor, color: localStorage.voteHereButtonTextColor, fontSize: localStorage.voteButtonFontSize}}
                                                disabled={this.state.votedproxy.length === this.state.proxylist.length}
                                                className="vote-submit-button"
                                                onClick={(e) => this.saveVote(e, this.props.agenda)}
                                                >
                                                    <img src="voteIcon.png"></img> {this.props.agendaVoteHereButton}
                                                </button>
                                            }
                                        </>

                                    :
                                        <>

                                        {localStorage.userType === "Shareholder" && localStorage.isMultipleCompany === "isMultipleCompany" && localStorage.company_tp === '1' ?

                                        <>
                                            {this.state.votedcompany.length === this.state.companylist.length ?

                                                <button 
                                                style={{background: localStorage.voteCastedButtonColor, color: localStorage.voteCastedButtonTextColor, fontSize: localStorage.voteCastedButtonFontSize}}
                                                className="vote-submit-button"
                                                disabled={this.state.votedcompany.length === this.state.companylist.length}
                                                >
                                                    <i className='fa fa-check-circle'></i> {this.props.agendaVoteCastedButton}
                                                </button>

                                                :
                                                
                                                <button 
                                                style={{background: localStorage.voteHereButtonColor, color: localStorage.voteHereButtonTextColor, fontSize: localStorage.voteButtonFontSize}}
                                                disabled={this.state.votedcompany.length === this.state.companylist.length}
                                                className="vote-submit-button"
                                                onClick={(e) => this.saveVote(e, this.props.agenda)}
                                                >
                                                    <img src="voteIcon.png"></img> {this.props.agendaVoteHereButton}
                                                </button>
                                            }
                                        </>

                                        :

                                        <>
                                        {this.state.disabledButton === this.props.agenda.id ?
                                        
                                        <button 
                                        style={{background: localStorage.voteCastedButtonColor, color: localStorage.voteCastedButtonTextColor, fontSize: localStorage.voteCastedButtonFontSize}}
                                        className="vote-submit-button"
                                        disabled={(this.state.disabledButton === this.props.agenda.id) || (localStorage.userType === "otherMember") || ((localStorage.userType === "boardMember") && localStorage.bmVote === "0")}
                                        onClick={(e) => this.saveVote(e, this.props.agenda)}
                                        >
                                            <i className='fa fa-check-circle'></i> {this.props.agendaVoteCastedButton}
                                        </button>
                                        :
                                        <button 
                                        style={{background: localStorage.voteHereButtonColor, color: localStorage.voteHereButtonTextColor, fontSize: localStorage.voteButtonFontSize}}
                                        className="vote-submit-button"
                                        disabled={(this.state.disabledButton === this.props.agenda.id) || (localStorage.userType === "otherMember") || ((localStorage.userType === "boardMember") && localStorage.bmVote === "0")}
                                        onClick={(e) => this.saveVote(e, this.props.agenda)}
                                        >
                                            <img src="voteIcon.png"></img> {this.props.agendaVoteHereButton}
                                        </button>
                                        }

                                        </>

                                        }
                                        </>
                                    }
                                </div>
                                }

                                </>

                            :
                                ""
                            }
                            
                            
                        </div>

                    :
                        <>

                        {localStorage.show_agenda_result_physical_portal === '1' &&

                        <div className='row'>
                            
                            {((this.props.agenda.vote_result_status === 1 && localStorage.userType === 'Shareholder') || (this.props.agenda.vote_result_status_bm === 1 && localStorage.userType === 'boardMember') || (this.props.agenda.vote_result_status_obm === 1 && localStorage.userType === 'otherMember')) &&

                            <div className="col-md-6 vote-selection-div">

                                <div style={{background:localStorage.voteResultFavourHeaderBgColor, color:localStorage.voteResultFavourHeaderTextColor}} className="vote-selection-container">

                                    <div>
                                        <div className="input-radio-label">
                                            {this.props.agendaFavorButton}

                                            {localStorage.vote_percentage_module === '1' && 
                                                <samp style={{background:localStorage.voteResultFavourNumberBgColor, color:localStorage.voteResultFavourNumberTextColor}} className="vote-percentage-value"> 
                                                    {this.props.vote_percentage_favor}%
                                                </samp>
                                            }
                                            
                                        </div>
                                        
                                        {localStorage.physical_voting === '1' ?

                                            <>
                                            {localStorage.show_total_voters_count === '1' ?
                                                <table className='table table-bordered vote_result_table' style={{color:localStorage.voteResultFavourHeaderTextColor}}>
                                                    <thead>
                                                    <tr>
                                                        <td>Type</td>
                                                        <td>Voters</td>
                                                        <td>Votes</td>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>Online</td>
                                                        <td>{this.props.agenda.favour_online_count ? this.props.agenda.favour_online_count.toLocaleString('en-IN') : 0}</td>
                                                        <td>{this.props.agenda.favour_online ? this.props.agenda.favour_online.toLocaleString('en-IN') : 0}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Physical</td>
                                                        <td>{this.props.agenda.favour_physical_count ? this.props.agenda.favour_physical_count.toLocaleString('en-IN') : 0}</td>
                                                        <td>{this.props.agenda.favour_physical ? this.props.agenda.favour_physical.toLocaleString('en-IN') : 0}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total</td>
                                                        <td>{this.props.agenda.favour_count ? this.props.agenda.favour_count.toLocaleString('en-IN') : 0}</td>
                                                        <td>{this.props.agenda.favour ? this.props.agenda.favour.toLocaleString('en-IN') : 0}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            :
                                                <span style={{color:localStorage.voteResultFavourNumberBgColor}} className="vote-result-value vote-result-value-pysical">
                                                    Online &nbsp;&nbsp;&nbsp;: {this.props.agenda.favour_online ? this.props.agenda.favour_online.toLocaleString('en-IN') : 0} <br></br>
                                                    Physical : {this.props.agenda.favour_physical ? this.props.agenda.favour_physical.toLocaleString('en-IN') : 0} <br></br>
                                                    Total &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {this.props.agenda.favour ? this.props.agenda.favour.toLocaleString('en-IN') : 0}
                                                </span>
                                            }
                                            </>

                                        
                                        :

                                            <div style={{color:localStorage.voteResultFavourNumberBgColor}} className="vote-result-value">

                                                {this.props.agenda.favour ? this.props.agenda.favour.toLocaleString('en-IN') : 0}

                                                {localStorage.show_total_voters_count === '1' && 
                                                    <>
                                                        <br></br>
                                                        {this.props.agenda.favour_count ? this.props.agenda.favour_count.toLocaleString('en-IN') : 0} Voter/s
                                                    </>
                                                }
                                                
                                            </div>

                                        }
                                            
                                    </div>
                                </div>
                            </div>
                            
                            }

                            {((this.props.agenda.vote_result_status === 1 && localStorage.userType === 'Shareholder') || (this.props.agenda.vote_result_status_bm === 1 && localStorage.userType === 'boardMember') || (this.props.agenda.vote_result_status_obm === 1 && localStorage.userType === 'otherMember')) &&

                            <div className="col-md-6 vote-selection-div">
                                <div style={{background:localStorage.voteResultAgainstHeaderBgColor,color:localStorage.voteResultAgainstHeaderTextColor}} className="vote-selection-container vote-selection-container-against">

                                    <div>
                                        <div className="input-radio-label">
                                            {this.props.agendaAgainstButton}
                                            {localStorage.vote_percentage_module === '1' &&
                                                <samp style={{background:localStorage.voteResultAgainstNumberBgColor, color:localStorage.voteResultAgainstNumberTextColor}} className="vote-percentage-value vote-percentage-value-against"> 
                                                    {this.props.vote_percentage_against}%
                                                </samp>
                                            }
                                        </div>

                                        {localStorage.physical_voting === '1' ?

                                            <>
                                            {localStorage.show_total_voters_count === '1' ?

                                                <table className='table table-bordered vote_result_table' style={{color:localStorage.voteResultFavourHeaderTextColor}}>
                                                    <thead>
                                                    <tr>
                                                        <td>Type</td>
                                                        <td>Voters</td>
                                                        <td>Votes</td>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>Online</td>
                                                        <td>{this.props.agenda.against_online_count ? this.props.agenda.against_online_count.toLocaleString('en-IN') : 0}</td>
                                                        <td>{this.props.agenda.against_online ? this.props.agenda.against_online.toLocaleString('en-IN') : 0}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Physical</td>
                                                        <td>{this.props.agenda.against_physical_count ? this.props.agenda.against_physical_count.toLocaleString('en-IN') : 0}</td>
                                                        <td>{this.props.agenda.against_physical ? this.props.agenda.against_physical.toLocaleString('en-IN') : 0}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total</td>
                                                        <td>{this.props.agenda.against_count ? this.props.agenda.against_count.toLocaleString('en-IN') : 0}</td>
                                                        <td>{this.props.agenda.against ? this.props.agenda.against.toLocaleString('en-IN') : 0}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            :
                                                <div style={{color:localStorage.voteResultAgainstNumberBgColor}} className="vote-result-value vote-result-value-pysical vote-result-value-against">
                                                    Online &nbsp;&nbsp;&nbsp;: {this.props.agenda.against_online ? this.props.agenda.against_online.toLocaleString('en-IN') : 0} <br></br>
                                                    Physical : {this.props.agenda.against_physical ? this.props.agenda.against_physical.toLocaleString('en-IN') : 0} <br></br>
                                                    Total &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {this.props.agenda.against ? this.props.agenda.against.toLocaleString('en-IN') : 0}
                                                </div>
                                            }
                                            </>

                                        
                                        
                                        :

                                        <div style={{color:localStorage.voteResultAgainstNumberBgColor}} className="vote-result-value vote-result-value-against">
                                            {this.props.agenda.against ? this.props.agenda.against.toLocaleString('en-IN') : 0}

                                            {localStorage.show_total_voters_count === '1' && 
                                                <>
                                                    <br></br>
                                                    {this.props.agenda.against_count ? this.props.agenda.against_count.toLocaleString('en-IN') : 0} Voter/s
                                                </>
                                            }
                                        </div>

                                        }
                                    </div>
                                </div>
                            </div>
                            
                            }
                        </div>

                        }


                        {this.props.agenda.vote_status === 1 && (localStorage.userType === "Shareholder" || localStorage.userType === "boardMember"  || (localStorage.userType === "otherMember")) && !this.urlParams.has('projector') ?

                            <>

                            {vote_allowed && 

                            <div className="col-md-6 vote-selection-div mt-2" style={{margin: '10px auto'}}>

                                {localStorage.userType === "Shareholder" && localStorage.isProxy === "Proxy" && localStorage.proxy_tp === '1'
                                
                                ?

                                    <>
                                        {this.state.votedproxy.length === this.state.proxylist.length ?

                                            <button 
                                            style={{background: localStorage.voteCastedButtonColor, color: localStorage.voteCastedButtonTextColor, fontSize: localStorage.voteCastedButtonFontSize}}
                                            className="vote-submit-button"
                                            disabled={this.state.votedproxy.length === this.state.proxylist.length}
                                            >
                                                <i className='fa fa-check-circle'></i> {this.props.agendaVoteCastedButton}
                                            </button>

                                            :
                                            
                                            <button 
                                            style={{background: localStorage.voteHereButtonColor, color: localStorage.voteHereButtonTextColor, fontSize: localStorage.voteButtonFontSize}}
                                            disabled={this.state.votedproxy.length === this.state.proxylist.length}
                                            className="vote-submit-button"
                                            onClick={(e) => this.saveVote(e, this.props.agenda)}
                                            >
                                                <img src="voteIcon.png"></img> {this.props.agendaVoteHereButton}
                                            </button>
                                        }
                                    </>

                                :
                                    <>
                                    {localStorage.userType === "Shareholder" && localStorage.isMultipleCompany === "isMultipleCompany" && localStorage.company_tp === '1' ?

                                    <>
                                        {this.state.votedcompany.length === this.state.companylist.length ?

                                            <button 
                                            style={{background: localStorage.voteCastedButtonColor, color: localStorage.voteCastedButtonTextColor, fontSize: localStorage.voteCastedButtonFontSize}}
                                            className="vote-submit-button"
                                            disabled={this.state.votedcompany.length === this.state.companylist.length}
                                            >
                                                <i className='fa fa-check-circle'></i> {this.props.agendaVoteCastedButton}
                                            </button>

                                            :
                                            
                                            <button 
                                            style={{background: localStorage.voteHereButtonColor, color: localStorage.voteHereButtonTextColor, fontSize: localStorage.voteButtonFontSize}}
                                            disabled={this.state.votedcompany.length === this.state.companylist.length}
                                            className="vote-submit-button"
                                            onClick={(e) => this.saveVote(e, this.props.agenda)}
                                            >
                                                <img src="voteIcon.png"></img> {this.props.agendaVoteHereButton}
                                            </button>
                                        }
                                    </>

                                    :

                                    <>
                                    {this.state.disabledButton === this.props.agenda.id ?
                                    
                                    <button 
                                    style={{background: localStorage.voteCastedButtonColor, color: localStorage.voteCastedButtonTextColor, fontSize: localStorage.voteCastedButtonFontSize}}
                                    className="vote-submit-button"
                                    disabled={(this.state.disabledButton === this.props.agenda.id) || (localStorage.userType === "otherMember") || ((localStorage.userType === "boardMember") && localStorage.bmVote === "0")}
                                    onClick={(e) => this.saveVote(e, this.props.agenda)}
                                    >
                                        <i className='fa fa-check-circle'></i> {this.props.agendaVoteCastedButton}
                                    </button>
                                    :
                                    <button 
                                    style={{background: localStorage.voteHereButtonColor, color: localStorage.voteHereButtonTextColor, fontSize: localStorage.voteButtonFontSize}}
                                    className="vote-submit-button"
                                    disabled={(this.state.disabledButton === this.props.agenda.id) || (localStorage.userType === "otherMember") || ((localStorage.userType === "boardMember") && localStorage.bmVote === "0")}
                                    onClick={(e) => this.saveVote(e, this.props.agenda)}
                                    >
                                        <img src="voteIcon.png"></img> {this.props.agendaVoteHereButton}
                                    </button>
                                    }

                                    </>

                                    }
                                    </>
                                }
                                
                            </div>

                            }

                            </>

                        :
                        ""
                        }

                        </>
                    }

                </div>
                
                :
                ""
                }

                {
                    this.state.isReason  && this.state.disabledButton !== this.props.agenda.id && 
                    <div className="my-4">
                        <div className="input-group pl-2 pr-2">
                            <input type="text" className="form-control"
                                   name="reason"
                                   value={this.state.reason}
                                   onChange={(e) => this.handleChange(e)}
                                   placeholder="Please enter the reason for giving against vote"/>
                            <div className="input-group-append">
                                <button className="btn btn-sm border"
                                        onClick={(e) => this.disableReasonForm(e)}
                                        type="button">
                                    <i className='fa fa-times' style={{color: 'red'}}/>
                                </button>
                                <button className="btn btn-sm border"
                                        value="2"
                                        type="button">
                                    <i className='fa fa-check'
                                       onClick={(e) => this.saveVote(e, this.props.agenda, 2)}
                                       style={{color: 'green'}}/>
                                </button>
                            </div>
                        </div>
                    </div>
                }

                {localStorage.userType === "Shareholder" && localStorage.isProxy === "Proxy" && localStorage.proxy_tp === '1' &&
                <>
                <div className="modal fade" id={`chose_sh_modal_favor_`+this.props.agenda.id} tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-md modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-center" id="staticBackdropLabel">{this.props.agenda.agenda_name.replace(regex, '')}</h5>
                                <button type="button" className="close" id="modal-close-button" onClick={(e) => this.closeModal(e)} aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="model-blog p-0 pl-2 pr-2 mb-2">
                                    <h6 className='text-left'>{this.props.agenda.description.replace(regex, '')}</h6>
                                    <p className='text-danger'>Note: Vote will count for the following checked/marked companies.</p>
                                    <table className='table table-bordered'>
                                        <thead>
                                            <tr>
                                                <th>SL</th>
                                                <th>Name</th>
                                                <th><input type='checkbox' name="check_all" value="1" onChange={(e) => this.checkAllFavor(e)}></input> All</th>
                                            </tr>
                                        </thead>
                                    <tbody>
                                    {
                                    this.state.proxylist.map((proxy, index) => {
                                        if(this.state.votedproxy.includes(proxy.id)){
                                            return (
                                                <tr key={index} style={{color:'grey'}}>
                                                    <td>{index+1}</td>
                                                    <td>
                                                        {proxy.name} <br></br>
                                                        {proxy.boid_full}
                                                    </td>
                                                    <td>
                                                        <input type='checkbox' disabled></input>
                                                    </td>
                                                </tr>
                                            )
                                        }else{
                                            return (
                                                <tr key={index}>
                                                    <td>{index+1}</td>
                                                    <td>
                                                        {proxy.name} <br></br>
                                                        {proxy.boid_full}<br></br>
                                                        {proxy.total_share}
                                                    </td>
                                                    <td>
                                                        <input type='checkbox' className='favorCheckboxes' onChange={(e) => this.handleCheckboxChangeFavor(e)} value={proxy.id}></input>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    })
                                    }
                                    </tbody>
                                    </table>
                                    
                                    <button 
                                        style={{background: localStorage.voteResultFavourHeaderBgColor, color: localStorage.voteResultFavourHeaderTextColor, fontSize: localStorage.voteButtonFontSize}}
                                        className="vote-submit-button"
                                        onClick={(e) => this.saveProxyVote(e, this.props.agenda, 1)}
                                    >
                                        {this.props.agendaFavorButton}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id={`chose_sh_modal_against_`+this.props.agenda.id} tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-md modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-center" id="staticBackdropLabel">{this.props.agenda.agenda_name.replace(regex, '')}</h5>
                                <button type="button" className="close" id="modal-close-button" onClick={(e) => this.closeModal(e)} aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="model-blog p-0 pl-2 pr-2 mb-2">
                                    <h6 className='text-left'>{this.props.agenda.description.replace(regex, '')}</h6>
                                    <p className='text-danger'>Note: Vote will count for the following checked/marked companies.</p>
                                    <table className='table table-bordered'>
                                    <thead>
                                        <tr>
                                            <th>SL</th>
                                            <th>Name</th>
                                            <th><input type='checkbox' name="check_all" value="1" onChange={(e) => this.checkAllAgainst(e)}></input> All</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                    this.state.proxylist.map((proxy, index) => {
                                        if(this.state.votedproxy.includes(proxy.id)){
                                            return (
                                                <tr key={index} style={{color:'grey'}}>
                                                    <td>{index+1}</td>
                                                    <td>
                                                        {proxy.name} <br></br>
                                                        {proxy.boid_full}
                                                    </td>
                                                    <td>
                                                        <input type='checkbox' disabled></input>
                                                    </td>
                                                </tr>
                                            )
                                        }else{
                                            return (
                                                <tr key={index}>
                                                    <td>{index+1}</td>
                                                    <td>
                                                        {proxy.name} <br></br>
                                                        {proxy.boid_full}<br></br>
                                                        {proxy.total_share}
                                                    </td>
                                                    <td>
                                                        <input type='checkbox' className='againstCheckboxes' onChange={(e) => this.handleCheckboxChangeAgainst(e)} value={proxy.id}></input>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        
                                    })
                                    }
                                    </tbody>
                                    </table>
                                    
                                    <button 
                                        style={{background: localStorage.voteResultAgainstHeaderBgColor, color: localStorage.voteResultAgainstHeaderTextColor, fontSize: localStorage.voteButtonFontSize}}
                                        className="vote-submit-button"
                                        onClick={(e) => this.saveProxyVote(e, this.props.agenda, 2)}
                                    >
                                        {this.props.agendaAgainstButton}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                </>
                }

                {localStorage.userType === "Shareholder" && localStorage.isMultipleCompany === "isMultipleCompany" && localStorage.company_tp === '1' &&
                <>
                <div className="modal fade" id={`gp_multi_chose_sh_modal_favor_`+this.props.agenda.id} tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-md modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-center" id="staticBackdropLabel">{this.props.agenda.agenda_name.replace(regex, '')}</h5>
                                <button type="button" className="close" id="modal-close-button" onClick={(e) => this.closeModal(e)} aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="model-blog p-0 pl-2 pr-2 mb-2">
                                    <h6 className='text-left'>{this.props.agenda.description.replace(regex, '')}</h6>
                                    <p className='text-danger'>Note: Vote will count for the following checked/marked companies.</p>
                                    <table className='table table-bordered'>
                                        <thead>
                                            <tr>
                                                <th>SL</th>
                                                <th>Name</th>
                                                <th><input type='checkbox' name="check_all" value="1" onChange={(e) => this.checkAllFavor(e)}></input> All</th>
                                            </tr>
                                        </thead>
                                    <tbody>
                                    {
                                    this.state.companylist.map((company, index) => {
                                        if(this.state.votedcompany.includes(company.id)){
                                            return (
                                                <tr key={index} style={{color:'grey'}}>
                                                    <td>{index+1}</td>
                                                    <td>
                                                        {company.name} <br></br>
                                                        {company.boid_full}<br></br>
                                                        {company.total_share}
                                                    </td>
                                                    <td>
                                                        <input type='checkbox' disabled></input>
                                                    </td>
                                                </tr>
                                            )
                                        }else{
                                            return (
                                                <tr key={index}>
                                                    <td>{index+1}</td>
                                                    <td>
                                                        {company.name} <br></br>
                                                        {company.boid_full}<br></br>
                                                        {company.total_share}
                                                    </td>
                                                    <td>
                                                        <input type='checkbox' className='favorCheckboxes' onChange={(e) => this.handleCheckboxChangeFavor(e)} value={company.id}></input>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    })
                                    }
                                    </tbody>
                                    </table>
                                    
                                    <button 
                                        style={{background: localStorage.voteResultFavourHeaderBgColor, color: localStorage.voteResultFavourHeaderTextColor, fontSize: localStorage.voteButtonFontSize}}
                                        className="vote-submit-button"
                                        onClick={(e) => this.saveCompanyVote(e, this.props.agenda, 1)}
                                    >
                                        {this.props.agendaFavorButton}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id={`gp_multi_chose_sh_modal_against_`+this.props.agenda.id} tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-md modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-center" id="staticBackdropLabel">{this.props.agenda.agenda_name.replace(regex, '')}</h5>
                                <button type="button" className="close" id="modal-close-button" onClick={(e) => this.closeModal(e)} aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="model-blog p-0 pl-2 pr-2 mb-2">
                                    <h6 className='text-left'>{this.props.agenda.description.replace(regex, '')}</h6>
                                    <p className='text-danger'>Note: Vote will count for the following checked/marked companies.</p>
                                    <table className='table table-bordered'>
                                    <thead>
                                        <tr>
                                            <th>SL</th>
                                            <th>Name</th>
                                            <th><input type='checkbox' name="check_all" value="1" onChange={(e) => this.checkAllAgainst(e)}></input> All</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                    this.state.companylist.map((company, index) => {
                                        if(this.state.votedcompany.includes(company.id)){
                                            return (
                                                <tr key={index} style={{color:'grey'}}>
                                                    <td>{index+1}</td>
                                                    <td>
                                                        {company.name} <br></br>
                                                        {company.boid_full}<br></br>
                                                        {company.total_share}
                                                    </td>
                                                    <td>
                                                        <input type='checkbox' disabled></input>
                                                    </td>
                                                </tr>
                                            )
                                        }else{
                                            return (
                                                <tr key={index}>
                                                    <td>{index+1}</td>
                                                    <td>
                                                        {company.name} <br></br>
                                                        {company.boid_full}<br></br>
                                                        {company.total_share}
                                                    </td>
                                                    <td>
                                                        <input type='checkbox' className='againstCheckboxes' onChange={(e) => this.handleCheckboxChangeAgainst(e)} value={company.id}></input>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        
                                    })
                                    }
                                    </tbody>
                                    </table>
                                    
                                    <button 
                                        style={{background: localStorage.voteResultAgainstHeaderBgColor, color: localStorage.voteResultAgainstHeaderTextColor, fontSize: localStorage.voteButtonFontSize}}
                                        className="vote-submit-button"
                                        onClick={(e) => this.saveCompanyVote(e, this.props.agenda, 2)}
                                    >
                                        {this.props.agendaAgainstButton}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                </>
                }
            </div>
        );
    }

}

export default VoteButton;