import React, {Component} from 'react';
import axios from "axios";

class NavBarLoginJs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalShareHolders: 0,
            totalShare: 0,
            onlineShareHolders: 0,
            online_shares: 0,
        }
        this.key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj';
    }


    componentDidMount = () => {

        axios({
            method: 'post',
            url: `${localStorage.apiHost}/shareholder-information`,
            headers: {
                'key': this.key
            },
            data: {
                'program-id': localStorage.programId
            }
        }).then(response => {
            if (response.data.status === 200) {
                this.setState({
                    totalShareHolders: response.data.total_shareholder,
                    totalShare: response.data.total_share,
                    onlineShareHolders: response.data.online_shareholders,
                    online_shares: response.data.online_shares
                });
            }
        }).catch((error) => {
            if(error.response){
                console.log(error.response);
            }
        });
    }

    render() {
        return (

            <div className="col-md-12 no-sm-h-padding mt-2 mb-2">
                <div className="col-md-12 viewer-section loginNav" style={{background: localStorage.navigationBarBgColor}}>
                    <div className="row mb-2 mt-2 pt-2 pb-2">         
                        <div className="col-md-12 col-sm-12 col-xs-12 mt-auto mb-auto xs_padding_5">
                            <p style={{color: localStorage.navigationBarTextColor, fontSize:localStorage.navbarFontSize}}>

                                {localStorage.total_attendance_status === '1' && 
                                <span><b>{localStorage.totalAttendedShareholdersText}:</b>  {this.state.totalShareHolders} 
                                </span>
                                }{localStorage.total_share_status === '1' && 
                                <span><b className='ml-2'>{localStorage.totalSharesText}:</b> {this.state.totalShare}
                                </span>
                                }
                                {localStorage.online_shareholder_attendance_status === '1' && 
                                <span><b className='ml-2'>Total Shareholder's Attendance:</b>  {this.state.onlineShareHolders} 
                                </span>
                                }
                                {localStorage.online_shareholder_attendance_status === '1' && 
                                <span><b className='ml-2'>Total Shares:</b>  {this.state.online_shares}
                                </span>
                                }
                            </p>
                        </div>
                                   
                    </div> 
                        
                </div>
            </div>
        );
    }
}

export default NavBarLoginJs;