import React from 'react'
import MicRecorder from 'mic-recorder-to-mp3';
import axios from 'axios';
import {toast} from "react-toastify";

const Mp3Recorder = new MicRecorder({bitRate: 128});

class Audio extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isRecording: false,
            blobURL: '',
            isBlocked: false,
            time: 0,
        }
        this.timer = null;
    }

    async componentDidMount() {

        if(localStorage.voiceBlockShow === '1' && (localStorage.userType === 'boardMember' || localStorage.userType === "Shareholder")){
            try {

                const stream = await navigator.mediaDevices.getUserMedia({audio: true});

                if(stream){

                    //toast.success("Microphone Allowed.");
                    this.setState({isBlocked: false});

                }else{

                    toast.error("Microphone permission denied. Please reload your browser allow audio permission.");
                    this.setState({isBlocked: true});

                }
                
            } catch(err) {

                toast.error("Microphone permission denied. Please reload your browser & allow audio permission.");
                this.setState({isBlocked: true});
                
            }
        }

        // await navigator.mediaDevices.getUserMedia({audio: true},
        //     () => {
        //         console.log("have audio access")
        //         this.setState({isBlocked: false});
        //     },
        //     () => {
        //         console.log("does not have audio access")
        //         this.setState({isBlocked: true})
        //     },
        // )
    }

    startTimer = () => {
        let sec = 0;
        this.timer = setInterval(() => {
            if (sec >= 60 * 2) {
                this.stop();
            }
            sec++;
        }, 1000);
    }

    start = () => {

        if(localStorage.userType ==="Shareholder"){

            this.startTimer();
            this.setState({voiceSent: false});
            if (this.state.isBlocked) {
                toast.error("Microphone permission denied. Please reload your browser & allow audio permission.");
            } else {
                Mp3Recorder
                    .start()
                    .then(() => {
                        this.setState({isRecording: true});
                    }).catch((e) => console.error(e));
            }
        }
    }

    stop = () => {
        clearInterval(this.timer);
        const key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj';
        const url = `${localStorage.apiHost}/voice-upload`;

        Mp3Recorder
            .stop()
            .getMp3()
            .then(([buffer, blob]) => {
                let reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    axios({
                        url: url,
                        method: 'post',
                        data: {
                            'program-id': localStorage.programId,
                            'voice': reader.result
                        },
                        headers: {
                            'key': key,
                            'access-token': localStorage.token
                        }
                    }).then(response => {
                        if (response.data.status === 200) {
                            toast.success("Voice Sent Successfully!");
                        }else{
                            toast.error(response.data.message);
                        }
                    }).catch((error) => {
                        if(error.response){
                            console.log(error.response);
                        }
                    });
                }
                this.setState({isRecording: false});
            }).catch((e) => console.log(e));
    }

    render() {
        let element = "";
        if (this.state.isRecording) {
            element = <a onClick={this.stop} className="btn" id="stopButton"
                         style={{backgroundColor: 'red'}}>{this.props.voiceButtonStop}</a>
        } else {
            element = <a onClick={this.start} className="btn" id="recordButton"
                        style={{
                             background: localStorage.recordButtonColor,
                             color: localStorage.recordButtonTextColor,
                             fontSize:localStorage.recordButtonFontSize
                        }}>
                {this.props.voiceButtonRecord}</a>
        }

        return (<div className="col-lg-12 col-md-12 col-sm-12">

                <div className="record-blog btrcc-record-blog mt-2">
                    {/* <p style={{fontSize:localStorage.recordBlockTextFontSize}}>Click <span className="text-dark">"Record"</span> to record your voice and send question</p> */}

                    <p style={{fontSize:localStorage.recordBlockTextFontSize}}>{this.props.voiceNote}</p>
                    <p>{element}</p>
                    {/* Broadcast Audio here */}
                    {
                        this.props.broadCastedAudio.length > 0 &&
                        <div className="mt-2 center">
                            <p>Audio broadcast started</p>
                            <audio src={this.props.broadCastedAudio} controls="controls"/>
                        </div>
                    }
                </div>
            </div>

            // <div className="panel mt20">
            //     <div className="center m10 pt20">
            //         <h6> Click here to start <span className="c3">record</span> and send your question.</h6>
            //     </div>
            //     <div className="center">
            //         {element}
            //     </div>
            //     {this.state.voiceSent &&
            //     <p className="center badge badge-light" id="voice_sent">Voice Sent Successfully!</p>
            //     }

            //     {/* Broadcast Audio here */}
            //     {
            //         this.props.broadCastedAudio.length > 0 &&
            //         <div className="mt-2 center">
            //             <p>Audio broadcast started</p>
            //             <audio src={this.props.broadCastedAudio} controls="controls"/>
            //         </div>
            //     }
            // </div>


        );
    }

}

export default Audio;
