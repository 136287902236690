import React from 'react';
import axios from 'axios';

class Header extends React.Component {

    changeLoginState = (event) => {
        const key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj';
        let url = "";
        if (localStorage.getItem('userType') != null) {
            if (localStorage.userType === "Shareholder") {
                url = `${localStorage.apiHost}/logout-shareholder`;
            } else {
                url = `${localStorage.apiHost}/logout-board-member`;
            }
        }
        axios({
            method: 'post',
            url: url,
            headers: {
                'key': key,
                'access-token': localStorage.token
            }
        }).then(response => {
            localStorage.isLogin = "false";
            localStorage.removeItem("token");
            localStorage.removeItem("userName");
            localStorage.removeItem("userType");
            localStorage.removeItem("moderator_login_id");
            this.props.onClick("false");
        }).catch((error) => {
            if(error.response){
                console.log(error.response);
            }
        });

        event.preventDefault();
    }

    render() {

        return (

            <div className="col-md-12 no-sm-h-padding">
                <div className="row mt-2 mb-2">
                    <div className="col-lg-12 col-md-12 col-sm-12" >
                        <div className="program-logo p0 "> 
                            {
                                typeof localStorage.programLogo !== undefined &&
                                <>
                                    
                                    <img src={`data:image/jpeg;base64,${localStorage.programLogo}`} alt="logo" style={{width:'100%', height: 'auto'}}/>
                                    
                                    <img className='mobile_banner' src={`data:image/jpeg;base64,${localStorage.programMobileBanner}`} alt="logo" style={{width:'100%', height: 'auto'}}/>
                                </>
                            }
                        </div>
                    </div>
                    
                </div>
            </div>

            // <div className="col-md-12 p0">
            //     <div className="row" style={{background: localStorage.topHeaderBgColor}}>

            //         <div className="col-md-4 col-sm-4 col-xs-5" style={{fontFamily: 'custom-font-en'}}>
            //             <div className="mt20"></div>
            //             <h4 className="en-title tc" style={{
            //                 color: localStorage.programNameTextColor,
            //                 fontSize: localStorage.headerProgramNameFontSizeEn,
            //                 textAlign: localStorage.englishHeaderAlignment
            //             }}
            //                 dangerouslySetInnerHTML={{__html: localStorage.programName}}
            //             />
            //             <h4 className="en-title tc" style={{
            //                 color: localStorage.companyNameTextColor,
            //                 fontSize: localStorage.headerCompanyNameFontSizeEn,
            //                 textAlign: localStorage.englishHeaderAlignment
            //             }}
            //                 dangerouslySetInnerHTML={{__html: localStorage.companyName}}
            //             />
            //             <h5 className="en-date tc" style={{
            //                 color: localStorage.dateTextColor,
            //                 fontSize: localStorage.headerDateFontSizeEn,
            //                 textAlign: localStorage.englishHeaderAlignment
            //             }}>{localStorage.programDate === "null" ? '' :
            //                 <span dangerouslySetInnerHTML={{__html: localStorage.programDate}}/>}
            //             </h5>
            //             <div className="mb10"></div>
            //         </div>

            //         <div className="col-md-4 col-sm-4 col-xs-2 text-center">
            //             <div className="mt20"></div>
            //             {
            //                 typeof localStorage.programLogo !== undefined &&
            //                 <a href="#" className="logo-img"><img
            //                     src={`data:image/jpeg;base64,${localStorage.programLogo}`} alt="logo" style={{
            //                     width: localStorage.logoWidth + 'px',
            //                     height: `${localStorage.logoHeight === 'null' ? 'auto' : localStorage.logoHeight + 'px'}`
            //                 }}/></a>
            //             }
            //             <div className="mb10"></div>
            //         </div>

            //         <div className="col-md-4 col-sm-4 col-xs-5" style={{fontFamily: 'custom-font-en'}}>
            //             <div className="mt20"></div>
            //             <h4 className="bn-title tc" style={{
            //                 color: localStorage.programNameTextColor,
            //                 fontSize: localStorage.headerProgramNameFontSizeBn,
            //                 textAlign: localStorage.banglaHeaderAlignment,
            //                 fontFamily: 'custom-font-bn'
            //             }}
            //                 dangerouslySetInnerHTML={{__html: localStorage.programNameBn}}
            //             />
            //             <h4 className="bn-title tc" style={{
            //                 color: localStorage.companyNameTextColor,
            //                 fontSize: localStorage.headerCompanyNameFontSizeBn,
            //                 textAlign: localStorage.banglaHeaderAlignment,
            //                 fontFamily: 'custom-font-bn'
            //             }}
            //                 dangerouslySetInnerHTML={{__html: localStorage.companyNameBn}}
            //             />
            //             <h5 className="bn-date  tc" style={{
            //                 color: localStorage.dateTextColor,
            //                 fontSize: localStorage.headerDateFontSizeBn,
            //                 textAlign: localStorage.banglaHeaderAlignment,
            //                 fontFamily: 'custom-font-bn'
            //             }}>{localStorage.programDateBn === "null" ? '' :
            //                 <span dangerouslySetInnerHTML={{__html: localStorage.programDateBn}}/>}</h5>
            //             <div className="mb10"></div>
            //         </div>

            //     </div>
            // </div>
        );
    }
}

export default Header;