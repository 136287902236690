import React from "react";
import axios from 'axios';
import {toast} from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Notiflix from 'notiflix';
import "notiflix/dist/notiflix-3.2.6.min.css";
import {browserName, fullBrowserVersion, engineName, engineVersion, deviceType, osName, osVersion} from 'react-device-detect';

class CompanyLogin extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			numberOfBoId: 0,
			name: null,
			authorized_person: null,
			bo_id: null,
			bo_id_1: null,
			bo_id_2: null,
			bo_id_3: null,
			bo_id_4: null,
			total_share: null,
			total_share_1: null,
			total_share_2: null,
			total_share_3: null,
			total_share_4: null,
			mobile: null,
            otp_for: 'mobile',
            otp_email: null,
			email: null,
            nid_number: null,
            dob: new Date(),
            password: '',
            protection: false,
            companyusername: null,
            companypassword: null,
            company_tp:0
		}
		this.element = [];
		this.key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj';
	}

	radioHandler = (value) => {
        this.setState({
            company_tp: value
        });
    }

	incrementBoId = (event) => {
		if (this.state.numberOfBoId < 4) {

			this.setState({
				numberOfBoId: this.state.numberOfBoId + 1
			});

			this.element.push(

				<div className="customer_records row" key={this.state.numberOfBoId}>

					<div className={`${localStorage.login_company_share == 1 ? "col-sm-6":"col-sm-12" }`}>

						<label className="font-weight-bold" style={{fontSize:localStorage.loginLabelFontSize, color:localStorage.loginLabelColor}}>Another &nbsp;
							{localStorage.boidInputLabel === "null" ? 'BO ID / Folio ID' : localStorage.boidInputLabel}:
						</label>

						<input
						type="text"
						onChange={this.handleChange}
						name={`bo_id_${this.state.numberOfBoId + 1}`}
						className="form-control"
						id={`boId${this.state.numberOfBoId}`}
						placeholder={`Enter Your ${localStorage.boidInputLabel === "null" ? "BO ID / Folio ID" : localStorage.boidInputLabel}`}
						required
						/>

					</div>
					{localStorage.login_company_share == 1 &&
					<div className="form-group col-sm-6">

						<label className="font-weight-bold" style={{fontSize:localStorage.loginLabelFontSize, color:localStorage.loginLabelColor}}>
							{localStorage.totalShareInputLabel === "null" ? 'Total Share' : localStorage.totalShareInputLabel}:
						</label>

						<input
						type="text"
						onChange={this.handleChange}
						name={`total_share_${this.state.numberOfBoId + 1}`}
						className="form-control"
						id={`total_share_c${this.state.numberOfBoId}`}
						placeholder={`Enter Your ${localStorage.totalShareInputLabel === "null" ? "Total Share" : localStorage.totalShareInputLabel}`}
						/>

					</div>
					}

				</div>
			);
		}
		event.preventDefault();
	}

	decrementBoId = (event) => {

		if(this.state.numberOfBoId > 0){

			let stateNameTotalShare = "total_share_"+this.state.numberOfBoId;
			let stateNameBOID = "bo_id_"+this.state.numberOfBoId;

			this.setState({
				numberOfBoId: this.state.numberOfBoId - 1,
				[stateNameTotalShare]: null,
				[stateNameBOID]: null,
			});

			this.element.pop();
		}

		event.preventDefault();
	}

	handleChange = (event) => {
		const value = event.target.value;
		const name = event.target.name;

        if(localStorage.bank_asia === '1'){

            this.setState({

                [name]: value

            }, () => {

                this.getName();

            });

        }else{

            if(name === 'bo_id'){

                // console.log(name);

                this.setState({

                    [name]: value

                }, () => {

                    this.getName();

                });

            }else{

                this.setState({

                    [name]: value

                });
            }

        }
	}

	handleChange2 = (value) => {
        this.setState({
            dob: value
        });
    }
	getName = (e) => {

        if(localStorage.bank_asia === '1'){

			const key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj';
			const url = `${localStorage.apiHost}/get-name-ba`;

			axios({
				method: 'post',
				url: url,
				data: {
					'type': 2,
					'type-status': 1,
					'boid': this.state.bo_id,
					'dob': this.state.dob,
					'total-share': this.state.total_share,
					'program-id': localStorage.programId,
				},
				headers: {
					'key': key
				}

			}).then(response => {

				if (response.data.status === 200) {

					document.getElementById("CName").value = response.data.name;

					// document.getElementById("CAuthorizedPersonName").value = response.data.authorized_person_name;
					// document.getElementById("total_share_c").focus();

					this.setState({
						name: response.data.name,
						// authorized_person: response.data.authorized_person_name
					});

				} else {

					document.getElementById("CName").value = '';
					// document.getElementById("CAuthorizedPersonName").value = '';

				}

			}).catch((error) => {
				if(error.response){
					console.log(error.response);
				}
			});

		}else{

			const key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj';
			const url = `${localStorage.apiHost}/get-name`;

			axios({
				method: 'post',
				url: url,
				data: {
					'boid': this.state.bo_id,
					'program-id': localStorage.programId,
				},
				headers: {
					'key': key
				}

			}).then(response => {

				if (response.data.status === 200) {

					if(response.data.type === 2){

						if(response.data.protected === 1){
							this.setState({
								protection: true
							});
						}

						if(document.getElementById("CName")){
							document.getElementById("CName").value = response.data.name;
							this.setState({
								name: response.data.name
							});
						}

						if(document.getElementById("ChiddenName")){
							document.getElementById("ChiddenName").value = response.data.name;
						}

						if(document.getElementById("CAuthorizedPersonName")){

							if(response.data.authorized_person_name){
								document.getElementById("CAuthorizedPersonName").value = response.data.authorized_person_name;
								this.setState({
									authorized_person: response.data.authorized_person_name
								});
							}


						}

						// document.getElementById("total_share_c").focus();



					}else{

						this.setState({
							protection: false
						});

						if(response.data.type === 1){

							toast.error('Please enter your BO/Folio in individual tab. You have entered your BO/Folio in wrong tab. ');

						} else if(response.data.type === 3){

							toast.error('Please enter your BO/Folio in proxy tab. You have entered your BO/Folio in wrong tab.');

						}

					}

				} else {

					this.setState({
						protection: false,
                        name: null
					});

					if(document.getElementById("CName")){
						document.getElementById("CName").value = '';
					}

					if(document.getElementById("CAuthorizedPersonName")){
						document.getElementById("CAuthorizedPersonName").value = '';
					}


				}
			}).catch((error) => {
				if(error.response){
					console.log(error.response);
				}
			});

			// e.preventDefault();

		}
    }

	handleSubmit = (event) => {

        localStorage.isProxy = "";
        localStorage.proxy_tp = "";

		localStorage.isMultipleCompany = "isMultipleCompany";
        localStorage.company_tp = this.state.company_tp;

        let userAgent = browserName+' '+fullBrowserVersion+' '+deviceType+' '+engineName+' '+engineVersion+' '+osName+' '+osVersion+' ';

		const key = this.key;
		const url = `${localStorage.apiHost}/shareholder-login`;

		axios({
			method: 'post',
			url: url,
			data: {
				'name': this.state.name,
				'password': this.state.password,
				'bo-id': this.state.bo_id,
				'bo-id-1': this.state.bo_id_1,
				'bo-id-2': this.state.bo_id_2,
				'bo-id-3': this.state.bo_id_3,
				'bo-id-4': this.state.bo_id_4,
				'total-share': this.state.total_share,
				'total-share-1': this.state.total_share_1,
				'total-share-2': this.state.total_share_2,
				'total-share-3': this.state.total_share_3,
				'total-share-4': this.state.total_share_4,
				'type': '2',
				'phone': this.state.mobile,
                'otp_for': this.state.otp_for,
                'otp_email': this.state.otp_email,
				'program-id': localStorage.programId,
				'authorized-person':this.state.authorized_person,
				'email': localStorage.enableEmail === 1 ? this.state.email : '',
				'nid-number': localStorage.enableNid === 1 ? this.state.nid_number : '',
				'dob': localStorage.enableDob === 1 ? this.state.dob : '',
				'agm-type': this.props.agmType,
				'ip': localStorage.ip,
				'user-agent': userAgent,
                'companyusername': this.state.companyusername,
                'companypassword': this.state.companypassword,
                'company_tp': this.state.company_tp
			},
			headers: {
				'key': key
			}

		}).then(response => {

			// console.log(response.data);

			if (response.data.status === 200) {

				if(this.state.company_tp === 1){
					Notiflix.Report.success(
						'Shareholders',
						response.data.multi_shareholders,
						'Continue',
						function(){

							this.proceedLogin(response);

						}.bind(this, response),
						{
							plainText: false,
                            messageMaxLength: 5000
						}
					);
				}else{
					this.proceedLogin(response);
				}

			} else {
				toast.error(response.data.message);
			}
		}).catch((error) => {
            if(error.response){
                console.log(error.response);
            }
        });
		event.preventDefault();
	}
	proceedLogin = (response) => {
		if (response.data.online_login_status == 0) {

			if (this.props.otp === "false") {

				localStorage.loginID = response.data.id;
				localStorage.token = response.headers.token;
				localStorage.isLogin = "true";
				localStorage.userName = response.data.name;
				localStorage.userType = "Shareholder"
				this.props.onClick("true");
				toast.success(response.data.message);

			} else {

				localStorage.otp_token = response.headers.token;
				localStorage.isLogin = "false";
				localStorage.otpPhone = this.state.mobile;
				this.props.onClick("false");

			}

		}else{

			if(localStorage.only_one_device_login === '1'){
				Notiflix.Report.warning(
					localStorage.logoutConfirmationHeading,
					localStorage.only_one_device_login_text,
					'Close'
				);
			}else{

				if (response.data.online_login_status == 0) {

					if (this.props.otp === "false") {

						localStorage.loginID = response.data.id;
						localStorage.token = response.headers.token;
						localStorage.isLogin = "true";
						localStorage.userName = response.data.name;
						localStorage.userType = "Shareholder"
						this.props.onClick("true");
						toast.success(response.data.message);

					} else {

						localStorage.otp_token = response.headers.token;
						localStorage.isLogin = "false";
						localStorage.otpPhone = this.state.mobile;
						this.props.onClick("false");

					}

				}else{

					if(localStorage.only_one_device_login === '1'){
						Notiflix.Report.warning(
							localStorage.logoutConfirmationHeading,
							localStorage.only_one_device_login_text,
							'Close'
						);
					}else{

						Notiflix.Confirm.show(
							localStorage.logoutConfirmationHeading,
							localStorage.logoutConfirmationText,
							localStorage.loginAnywayButton,
							localStorage.goBackButton,
							function(){

								// Login Anyway button callback

								const key = this.key;
								const url = `${localStorage.apiHost}/shareholder-logout-other-device`;

								axios({

									method: 'post',
									url: url,
									headers: {
										'key': key,
										'token': response.headers.token,
									}

								}).then(response2 => {

									// console.log(response2.data);

									if (response2.data.status === 200) {

										toast.success(response2.data.message);

										if (this.props.otp === "false") {


											localStorage.loginID = response.data.id;
											localStorage.token = response2.headers.token;
											localStorage.isLogin = "true";
											localStorage.userName = response2.data.name;
											localStorage.userType = "Shareholder"
											this.props.onClick("true");

										} else {

											localStorage.otp_token = response2.headers.token;
											localStorage.isLogin = "false";
											localStorage.otpPhone = this.state.mobile;
											this.props.onClick("false");

										}

									}else{

										toast.error(response2.data.message);

									}

								}).catch((error) => {
									if(error.response){
										console.log(error.response);
									}
								});

							}.bind(this, response),
							false
						);
					}
				}
			}
		}
	}

	render() {
		return (
			<div className="tab-pane fade show active" id="nav-company" role="tabpanel" aria-labelledby="nav-company-tab">
				<div className="company-form px-4 py-1">
					<form onSubmit={this.handleSubmit}>

						{localStorage.gp_multiple_company === '1' &&
                        <>
                            <label><input type="radio" checked={this.state.company_tp === 0} name="company_tp" value="0" onChange={(e) => this.radioHandler(0)}></input> Single Company</label> &nbsp;

                            <label><input type="radio" checked={this.state.company_tp === 1} name="company_tp" value="1" onChange={(e) => this.radioHandler(1)}></input> Multiple Company</label><br></br>
                        </>
                        }

						{localStorage.gp_multiple_company === '1' && this.state.company_tp === 1 &&

							<div className="multi_company_container">
								<div className="form-group">
									<label className="font-weight-bold" style={{fontSize:localStorage.loginLabelFontSize, color:localStorage.loginLabelColor}}>
										Username:
									</label>
									<input
									onChange={e => { this.handleChange(e) }}
									type="text"
									name="companyusername"
									className="form-control"
									placeholder={`Enter Username`} required />
								</div>

								<div className="form-group">
									<label className="font-weight-bold" style={{fontSize:localStorage.loginLabelFontSize, color:localStorage.loginLabelColor}}>
										Password:
									</label>
									<input
									onChange={e => { this.handleChange(e) }}
									type="password"
									name="companypassword"
									className="form-control"
									placeholder={`Enter Password`} required />
								</div>
							</div>

                        }

						{this.state.company_tp === 0 &&
						<>
							<div className="customer_records row">

								<div className={`${localStorage.login_company_share == 1 ? "col-sm-6":"col-sm-12" }`}>

									<label className="font-weight-bold" style={{fontSize:localStorage.loginLabelFontSize, color:localStorage.loginLabelColor}}>
										{localStorage.boidInputLabel === "null" ? 'BO ID / Folio ID' : localStorage.boidInputLabel}:
									</label>

									<input
									type="text"
									onChange={e => { this.handleChange(e)}}
									name="bo_id"
									className="form-control"
									id={`boId${this.state.numberOfBoId}`}
									placeholder={`Enter Your ${localStorage.boidInputLabel === "null" ? "BO ID / Folio ID" : localStorage.boidInputLabel}`}
									required
									/>

									{/* <a onClick={this.incrementBoId} className="ml-1 extra-fields-customer" href="/"><i className='fa fa-plus' ></i></a>

									<a onClick={this.decrementBoId} className="ml-1 extra-fields-customer" href="/"><i className='fa fa-minus' ></i> </a> */}

								</div>

								{localStorage.login_company_share == 1 &&
								<div className="form-group col-sm-6">

									<label className="font-weight-bold" style={{fontSize:localStorage.loginLabelFontSize, color:localStorage.loginLabelColor}}>
										{localStorage.totalShareInputLabel === "null" ? 'Total Share' : localStorage.totalShareInputLabel}:
									</label>

									<input
									type="text"
									onChange={this.handleChange}
									name="total_share"
									className="form-control"
									id={`total_share_c${this.state.numberOfBoId}`}
									placeholder={`Enter Your ${localStorage.totalShareInputLabel === "null" ? "Total Share" : localStorage.totalShareInputLabel}`}
									/>

								</div>
								}

							</div>

							<div className="customer_records_dynamic">{this.element}</div>

							{localStorage.login_company_name == 1 &&
							<div className="form-group">

								<label className="font-weight-bold" style={{fontSize:localStorage.loginLabelFontSize, color:localStorage.loginLabelColor}}>
									{localStorage.companyNameInputLabel === "null" ? 'Company Name' : localStorage.companyNameInputLabel}:
								</label>

								<input
								type="text"
								disabled
								onChange={this.handleChange}
								className="form-control"
								id="CName"
								placeholder={`${localStorage.companyNameInputLabel === "null" ? "Name" : localStorage.companyNameInputLabel}`}
								required
								/>

								<input
								type="hidden"
								onChange={this.handleChange}
								name="name"
								className="form-control"
								id="ChiddenName"
								placeholder={`${localStorage.companyNameInputLabel === "null" ? "Name" : localStorage.companyNameInputLabel}`}
								required
								/>

							</div>
							}

							{localStorage.login_company_authorized_name == 1 &&
							<div className="form-group">
								<label className="font-weight-bold" style={{fontSize:localStorage.loginLabelFontSize, color:localStorage.loginLabelColor}}>
									{localStorage.authorizedPersonNameInputLabel === "null" ? 'Authorized person Name' : localStorage.authorizedPersonNameInputLabel}:
								</label>
								<input type="text" onChange={this.handleChange} name="authorized_person" className="form-control" id="CAuthorizedPersonName" placeholder={`Enter Your ${localStorage.authorizedPersonNameInputLabel === "null" ? "Authorized person Name" : localStorage.authorizedPersonNameInputLabel}`} required/>
							</div>
							}


							{this.state.protection &&
							<div className="form-group">
								<label className="font-weight-bold" style={{fontSize:localStorage.loginLabelFontSize, color:localStorage.loginLabelColor}}>
									Password:
								</label>
								<input onChange={this.handleChange} type="password" name="password" className="form-control" placeholder={`Enter Password`}required />
							</div>
							}

							{/* <div className="form-group">
								<label className="font-weight-bold" style={{fontSize:localStorage.loginLabelFontSize, color:localStorage.loginLabelColor}}>
									{localStorage.totalShareInputLabel === "null" ? 'Total Share' : localStorage.totalShareInputLabel}:
								</label>
								<input type="text" onChange={this.handleChange} name="total_share" className="form-control" id="total_share_c"
									placeholder={`Enter Your ${localStorage.totalShareInputLabel === "null" ? "Total Share" : localStorage.totalShareInputLabel}`}
								/>
							</div> */}

							{localStorage.enableEmail === 1 &&
								<div className="form-group">
									<label className="font-weight-bold" style={{fontSize:localStorage.loginLabelFontSize, color:localStorage.loginLabelColor}}>
										{localStorage.emailInputLabel === "null" ? 'Email' : localStorage.emailInputLabel}:
									</label>
									<input onChange={this.handleChange} type="email" name="email" className="form-control" id="email" placeholder="Enter Your Email" />
								</div>
							}

							{localStorage.enableNid === 1 &&
								<div className="form-group">
									<label className="font-weight-bold" style={{fontSize:localStorage.loginLabelFontSize, color:localStorage.loginLabelColor}}>
										{localStorage.nidInputLabel === "null" ? 'NID Number' : localStorage.nidInputLabel}:
									</label>
									<input onChange={this.handleChange} type="text" name="nid_number" className="form-control" id="nid_number" placeholder="Enter Your NID Number" maxLength="16"/>
								</div>
							}

							{localStorage.enableDob === 1 &&
								<div className="form-group">
									<label className="font-weight-bold" style={{fontSize:localStorage.loginLabelFontSize, color:localStorage.loginLabelColor}}>
										{localStorage.dateOfBirthInputLabel === "null" ? 'Date of Birth' : localStorage.dateOfBirthInputLabel}:
									</label> <br></br>

									<DatePicker
										className="form-control"
										placeholderText="Enter Your Date of Birth"
										selected={ this.state.dob }
										onChange={ this.handleChange2 }
										name="dob"
										dateFormat="d MMM, yyyy"
										value={this.state.dob}
									/>
								</div>
							}

						</>
						}

						{this.props.otp === "true" &&
							<div className="form-group">
								<label className="font-weight-bold" style={{fontSize:localStorage.loginLabelFontSize, color:localStorage.loginLabelColor}}>
									{/* {localStorage.mobileInputLabel === "null" ? 'Mobile Number' : localStorage.mobileInputLabel}: */}
                                    {this.state.otp_for == 'mobile' ? 'Mobile Number':'Email Address'}:

                                    {/* <div className="btn-group" style={{ marginLeft:'10px', marginTop: '8px' }} data-toggle="buttons">

										<label className="btn btn-default">
											<input type="radio" onClick={() => {this.setState({otp_for:'mobile'}); localStorage.mobileInputLabel = null; localStorage.otp_for = 'mobile'}} name="otpFor" value="1" checked={this.state.otp_for == 'mobile' ? true : false} /> &nbsp; Mobile &nbsp;
										</label>

										<label className="btn btn-default active">
											<input type="radio" onClick={() => {this.setState({otp_for:'email'}); localStorage.mobileInputLabel = 'Email Address'; localStorage.otp_for = 'email'}} name="otpFor" checked={this.state.otp_for == 'email' ? true : false} value="0" /> &nbsp; Email
										</label>

									</div> */}
								</label>
								{/* <input onChange={this.handleChange} type="text" name="mobile" className="form-control" id="mobile"
									   placeholder={`${localStorage.mobileInputPlaceholder === "null" ? "e.g 017xxxxxxx" : localStorage.mobileInputPlaceholder}`}
									   minLength="11" required /> */}
                                       {this.state.otp_for == 'mobile' ?
                                            (<input onChange={this.handleChange} type="number" name="mobile" className="form-control" id="mobile"
                                                placeholder={`${localStorage.mobileInputPlaceholder === "null" ? "e.g 017xxxxxxx" : localStorage.mobileInputPlaceholder}`}
                                                minLength="11"
                                                required />)
                                            : (
                                                <input onChange={this.handleChange} type="email" name="otp_email" className="form-control" id="otp_email" placeholder="Enter Your Email" />
                                            )
                                        }
							</div>
						}

						<button type="submit" className="mt-1 btn btn-login"
								style={{background:localStorage.loginButtonBgColor, color:localStorage.loginButtonTextColor}}
						>
							{localStorage.loginButton === "null" ? 'Login' : localStorage.loginButton}
						</button>
					</form>
				</div>
			</div>
		);
	}
}

export default CompanyLogin;
