import React, {Component} from 'react';
import axios from 'axios';
import {toast} from "react-toastify";

class ProposedSeconded extends Component {
    constructor(props) {
        super(props);
        this.key = "YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj";
    }

    psdVote = (event, agendaId, voteType) => {
        axios({
            method: 'post',
            url: `${localStorage.apiHost}/proposed-seconded-demanded-vote`,
            data: {
                'program-id': localStorage.programId,
                'vote': voteType,
                'agenda-id': agendaId
            },
            headers: {
                'key': this.key,
                'access-token': localStorage.token
            }
        }).then(response => {
            if (response.data.status === 200) {
                toast.success(response.data.message)
            } else {
                toast.error(response.data.message)
            }
        }).catch((error) => {
            if(error.response){
                console.log(error.response);
            }
        });
        event.preventDefault();
    }

    render() {
        console.log('psd agenda list here',this.props.psdAgenda);
        let agName = this.props.psdAgenda.agenda_name.replace(/<\/?[^>]+(>|$)/g, "");
        return (
            <div>
                {/* {this.props.psdAgenda.proposed_sh === 1 &&
                <h1>hello</h1>
                } */}
                <div className="poll-propose-secanded-blog mt-2">
                    <p className='m0'>{agName}</p>
                    <div className="pssecblock row m-0">
                        {
                        this.props.psdAgenda.proposed_sh === 1 &&

                            <div className={`${this.props.psdAgenda.demanded_sh === 1 ?"col-lg-4 col-md-4 col-sm-12 p-0":"col-lg-6 col-md-6 col-sm-12 p-0" }`}>
                            {/* <div className="col-lg-4 col-md-4 col-sm-12 p-0"> */}
                        
                                <p>
                                <button onClick={(e) => this.psdVote(e, this.props.psdAgenda.id, 1)}
                                        disabled={!this.props.psdAgenda.proposed_ed || localStorage.userType==="boardMember" || localStorage.userType ==="otherMember"}
                                        style={{
                                            background: localStorage.proposedButtonColor,
                                            color: localStorage.proposedButtonTextColor
                                        }}
                                        className="btn btn-proposeed">{this.props.agendaProposedButton}</button>

                                <a className="prposed_seconded_result">
                                        <b>{this.props.agendaProposedName}: </b>{this.props.psdAgenda.proposed_shareholder_name ?? ''} <br></br>
                                        <b>{this.props.agendaProposedBoId}: </b>{this.props.psdAgenda.proposed_shareholder_boid ?? ''}
                                </a>
                                </p>
                            </div>
                        }
                        
                        {
                            this.props.psdAgenda.seconded_sh === 1 &&
                            <div className={`${this.props.psdAgenda.demanded_sh === 1 ?"col-lg-4 col-md-4 col-sm-12 p-0":"col-lg-6 col-md-6 col-sm-12 p-0" }`}>
                            {/* <div className="col-lg-4 col-md-4 col-sm-12 p-0"> */}
                            <p>
                            <button onClick={(e) => this.psdVote(e, this.props.psdAgenda.id, 2)}
                                    disabled={!this.props.psdAgenda.seconded_ed || localStorage.userType==="boardMember" || localStorage.userType ==="otherMember"}
                                    style={{
                                        background: localStorage.secondedButtonColor,
                                        color: localStorage.secondedButtonTextColor
                                    }}
                                    className="btn btn-secanded">{this.props.agendaSecondedButton}</button>
                            <a className="prposed_seconded_result">
                                <b>{this.props.agendaSecondedName}: </b> {this.props.psdAgenda.seconded_shareholder_name ?? ''} <br></br>
                                <span><b>{this.props.agendaSecondedBoId}: </b>{this.props.psdAgenda.seconded_shareholder_boid ?? ''}</span>
                            </a>
                            </p>
                            </div>
                        }
                            {
                                this.props.psdAgenda.demanded_sh === 1 &&

                                <div className="col-lg-4 col-md-4 col-sm-12 p-0">
                                    <p>
                                    <button onClick={(e) => this.psdVote(e, this.props.psdAgenda.id, 3)}
                                            disabled={!this.props.psdAgenda.demanded_ed || localStorage.userType==="boardMember" || localStorage.userType ==="otherMember"}
                                            style={{
                                                background: localStorage.demandButtonColor,
                                                color: localStorage.demandButtonTextColor
                                            }}
                                            className="btn btn-poll">{this.props.agendaDemamdedButton}</button>
                                        <a className="prposed_seconded_result"><br></br><span className="total_span"><b>{this.props.agendaDemamdedTotal}: </b>{this.props.psdAgenda.total_demanded ?? ''}</span>
                                        </a>
                                    </p>
                                </div>
                            }
                    </div>
                    {/* <div className="poll-propose-secanded-user-name-blog">
                        <p className="text-center">
                            {
                                this.props.psdAgenda.proposed_sh === 1 &&
                                <a className="prposed_seconded_result">
                                    <b>Name: </b>{this.props.psdAgenda.proposed_shareholder_name ?? ''} <br></br>
                                    <b>BO ID: </b>{this.props.psdAgenda.proposed_shareholder_boid ?? ''}
                                </a>
                            }
                            {
                                this.props.psdAgenda.seconded_sh === 1 &&
                                <a className="prposed_seconded_result">
                                    <b>Name: </b> {this.props.psdAgenda.seconded_shareholder_name ?? ''} <br></br>
                                    <span><b>BO ID : </b>{this.props.psdAgenda.seconded_shareholder_boid ?? ''}</span>
                                </a>
                            }
                            {
                                this.props.psdAgenda.demanded_sh === 1 &&
                                <a className="prposed_seconded_result"><br></br><span
                                    className="total_span"><b>Total: </b>{this.props.psdAgenda.total_demanded ?? ''}</span>
                                </a>

                            }
                        </p>
                    </div> */}
                </div>
            </div>


            // <div className="panel">
            //     <div className="btn-box">
            //         {
            //             this.props.psdAgenda.proposed_sh === 1 &&
            //             <div className="p10">
            //                 <button type="button"
            //                         onClick={(e) => this.psdVote(e, this.props.psdAgenda.id, 1)}
            //                         disabled={!this.props.psdAgenda.proposed_ed}
            //                         className="new-btn-l p20 br5" style={{background:localStorage.proposedButtonColor,color:localStorage.proposedButtonTextColor}}>Proposed
            //                 </button>
            //             </div>
            //         }
            //         {
            //             this.props.psdAgenda.seconded_sh === 1 &&
            //             <div className="p10">
            //                 <button type="button"
            //                         onClick={(e) => this.psdVote(e, this.props.psdAgenda.id, 2)}
            //                         disabled={!this.props.psdAgenda.seconded_ed}
            //                         className="new-btn-l p20 br5" style={{background:localStorage.secondedButtonColor, color:localStorage.secondedButtonTextColor}}>Seconded
            //                 </button>
            //             </div>
            //         }
            //         {
            //             this.props.psdAgenda.demanded_sh === 1 &&
            //             <div className="p10">
            //                 <button type="button"
            //                         onClick={(e) => this.psdVote(e, this.props.psdAgenda.id, 3)}
            //                         disabled={!this.props.psdAgenda.demanded_ed}
            //                         className="new-btn-l p20 br5" style={{background:localStorage.demandButtonColor, color:localStorage.demandButtonTextColor}}>Poll
            //                 </button>
            //             </div>
            //         }

            //     </div>
            //     <div className="btn-box">
            //         {
            //             this.props.psdAgenda.proposed_sh === 1 &&
            //             <div className="panel-btn-info">
            //                 <span className="center">{this.props.psdAgenda.proposed_shareholder_name ?? ''}</span>
            //                 <span className="center">VOID: {this.props.psdAgenda.proposed_shareholder_boid ?? ''}</span>
            //             </div>
            //         }
            //         {
            //             this.props.psdAgenda.seconded_sh === 1 &&
            //             <div className="panel-btn-info">
            //                 <span className="center">Name: {this.props.psdAgenda.seconded_shareholder_name ?? ''}</span>
            //                 <span className="center">VOID: {this.props.psdAgenda.seconded_shareholder_boid ?? ''}</span>
            //             </div>
            //         }
            //         {
            //             this.props.psdAgenda.demanded_sh === 1 &&
            //             <div className="panel-btn-info">
            //                 <span className="center">{this.props.psdAgenda.total_demanded ?? ''}</span>
            //             </div>
            //         }

            //     </div>
            // </div>
        );
    }
}

export default ProposedSeconded;