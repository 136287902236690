import React from 'react'
// import ReactHlsPlayer from 'react-hls-player';
// import { Replay } from 'vimond-replay';
import 'vimond-replay/index.css';
// import HlsjsVideoStreamer from 'vimond-replay/video-streamer/hlsjs';
// import ReactDOM from 'react-dom';
// import ReactJWPlayer from 'react-jw-player';
import JWPlayer from '@jwplayer/jwplayer-react';

class BroadCastVideo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            videoLoad: false
        }
        this.timer = null;
        this.isHlsLink = true;
    }

    componentDidMount() {
        this.startTimer();
    }

    startTimer = () => {
        let sec = 0;
        this.timer = setInterval(() => {
            if (sec >= 4) {
                this.stop();
            }
            sec++;
        }, 1000);
    }
    stop = () => {
        clearInterval(this.timer);
        this.setState({videoLoad: true});
    }
    

    render() {
        if (localStorage.streaming_link !== 'null') {
            let streamingUrl = localStorage.streaming_link;
            if (streamingUrl.search(".m3u8") < 0) {
                this.isHlsLink = false;
            }
        }
        // const replayOptions = {
        //     videoStreamer: {
        //       hlsjs: {
        //         customConfiguration: {
        //           capLevelToPlayerSize: true,
        //           maxBufferLength: 45,
        //           autoplay:true,
        //           controls:true,
        //         }
        //       }
        //     }
        // };
        return (
            <div>
                {localStorage.streaming_link !== 'null' && this.state.videoLoad
                    ?
                        this.isHlsLink ?

                            // <ReactHlsPlayer
                            //     url={localStorage.streaming_link}
                            //     autoplay={true}
                            //     controls={true}
                            //     width="100%"
                            //     height="auto"
                            // />                            

                            // <Replay
                            // source={localStorage.streaming_link}
                            // options={replayOptions}
                            // >
                            // <HlsjsVideoStreamer />
                            // </Replay>

                            <JWPlayer
                                file={localStorage.streaming_link}
                                // playerId='my-id-2231' // bring in the randomly generated playerId
                                library='https://cdn.jwplayer.com/libraries/roy564eO.js'
                            />

                        :
                            <iframe 
                                width="560" height="430"
                                title="Broadcast Video"
                                src={localStorage.streaming_link}
                                frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                    : 

                    ''
                }

                {localStorage.streaming_link === 'null' &&
                    <div className="p-5 text-danger">Conference Link Not Found</div>
                }

            </div>
        );
    }

}

export default BroadCastVideo;
