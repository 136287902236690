import React from 'react';
import axios from 'axios';
import {toast} from "react-toastify";
import Notiflix from 'notiflix';
import "notiflix/dist/notiflix-3.2.6.min.css";


class UserRegister extends React.Component {

    constructor(props) {
        super(props)
        this.state = {   
            name: '',
            bo_id: '',
            total_share: '',
            mobile: '',
        }
    }

    handleChange = (event) => {

        const value = event.target.value;
        const name = event.target.name;

        if(name === 'bo_id'){

            this.setState({

                [name]: value
    
            }, () => { 
                
                this.getShareholder();
    
            });

        }else{

            this.setState({
                [name]: value
            });

        }
    }


    
    getShareholder() {

        const key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj';
        const url = `${localStorage.apiHost}/get-name-register`;

        let data = {
            'bo-id': this.state.bo_id,
            'program-id': localStorage.programId,
        };
        
        axios({
            method: 'post',
            url: url,
            data: data,
            headers: {
                'key': key
            }

        }).then(response => {
            // console.log(response.data.name);
            document.getElementById("inputname").value = response.data.name;
            this.setState({
                'name': response.data.name
            });
            
        }).catch((error) => {
            if(error.response){
                console.log(error.response);
            }
        });

    }

    

    handleSubmit = (event) => {
			
        localStorage.isProxy = "";
        localStorage.proxy_tp = "";

		localStorage.isMultipleCompany = "";
        localStorage.company_tp = '';
        
        const key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj';
        const url = `${localStorage.apiHost}/shareholder-registration`;
        let data = JSON.stringify({
            'name': this.state.name,
            'bo-id': this.state.bo_id,
            'total-share': this.state.total_share,
            'mobile': this.state.mobile,
            'program-id': localStorage.programId,
        });
        axios({
            method: 'post',
            url: url,
            data: data,
            headers: {
                'key': key,
                "Content-Type" : "application/json"
            }

        }).then(response => {

            // console.log(response.data);

            if (response.data.status === 200) {
                let reg = document.getElementById('registerClose');
                if(reg){reg.click()}
                // localStorage.token = response.headers.token;
                // localStorage.isLogin = "true";
                // localStorage.userName = response.data.name;
                // this.props.onClick('true', response.data.name);
                toast.success(response.data.message);

                Notiflix.Confirm.ask(
                    'Submit OTP',
                    'Enter OTP and click submit.',
                    ''+response.data.otp_code+'',
                    'Submit',
                    'Cancel',
                    function(){

                        // console.log(response);
                        const url = `${localStorage.apiHost}/shareholder-registration-otp-submission`;

                        let data2 = JSON.stringify({
                            'id': response.data.id,
                            'mobile': response.data.mobile,
                            'otp': response.data.otp_code
                        });
                        // console.log(data2);

                        axios({

                            method: 'post',
                            url: url,
                            data: data2,
                            headers: {
                                'key': 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj',
                                "Content-Type" : "application/json"
                            }

                        }).then(response2 => {
                            
                            // console.log(response2.data);

                            if (response2.data.status === 200) {
                                
                                toast.success(response2.data.message);

                            }else{

                                toast.error(response2.data.message);

                            }

                        }).catch((error) => {
                            if(error.response){
                                console.log(error.response);
                            }
                        });

                    }.bind(this, response),
                    false 
                );
                    
            }else{
                toast.error(response.data.message);
                // localStorage.isLogin = "false";
                // this.props.onClick('false');
            }

        }).catch((error) => {
            if(error.response){
                console.log(error.response);
            }
        });

        event.preventDefault();
    }


    render() {

        return (
                <div className="px-4 py-1">
                   <form onSubmit={this.handleSubmit}>

                        <div className="form-group mt-3">
                            <label>BO ID  <span className="text-danger">*</span></label>
                            <input id="bo-id" type="text" onChange={this.handleChange}className="form-control" name="bo_id" placeholder="Enter Your BO ID" required></input>
                        </div>              
                        
                        <div className="form-group mt-3">
                            <label>Total Share</label>
                            <input onChange={this.handleChange} id="total-share" type="number" className="form-control" name="total_share" placeholder="Enter Your Total Share"></input>
                        </div>

                        <div className="form-group mt-3">
                            <label>Shareholder Name:  <span className="text-danger">*</span></label>
                            <input id="inputname" onChange={this.handleChange} type="text" className="form-control" name="name" placeholder="Enter Your Name" required></input>
                        </div>
                        
                        <div className="form-group mt-3">
                            <label>Mobile <span className="text-danger">*</span></label>
                            <input pattern='^\d{11}$' title='11 Digit Mobile Number' onChange={this.handleChange} id="mobile-num" type="text" className="form-control" name="mobile" placeholder="Enter Your Mobile Number" required></input>
                        </div>

                        <button type="submit" className="btn btn-md btn-info mt-3">Register</button>
                    </form> 
                </div>
        );
    }
}

export default UserRegister;