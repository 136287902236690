import React, {Component} from 'react';
import Header from './Header';
import { AddToCalendarButton } from 'add-to-calendar-button-react';

class UpcomingBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countDownTime: '',
            bannerText: ''
        }
    }

    componentDidMount = () => {
        let countDownDate = new Date(this.props.countDownTime);
        let bannerText = `This site will be enabled from
         ${countDownDate.toLocaleString('en-us', {hour:"2-digit", minute:"2-digit"})} on 
         ${countDownDate.toLocaleString('en-us', {year: "numeric", month: 'long', day: 'numeric'})}`
        this.setState({bannerText: bannerText})
        if (this.props.programStatus === 2) {
            let countDownDateMilSecond = countDownDate.getTime();
            let x = setInterval(() => {
                let now = new Date().getTime();
                let diff = countDownDateMilSecond - now;
                let days = Math.floor(diff / (1000 * 60 * 60 * 24));
                let hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                let minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
                let seconds = Math.floor((diff % (1000 * 60)) / 1000);
                this.setState({countDownTime: `${days}d ${hours}h ${minutes}m ${seconds}s`});
                if (diff < 0) {
                    clearInterval(x);
                    this.setState({countDownTime: `Program Will Start Soon. Please reload browser.`});
                }
            }, 1000);
        }
    }

    render() {
        if(localStorage.programIcon !== "null"){

            let iconEl = document.getElementById('icon');
            iconEl.href = `data:image/jpeg;base64,${localStorage.programIcon}`;

        }

        let ssdt = this.props.countDownTime;
        let stDate = ssdt.replaceAll("/", "-");
        let stDateA = stDate.split(" ");

        let calDate = stDateA[0];
        
        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 text-center mt-2 mb-3">
                    <Header></Header>
                    <div className='col-lg-12 col-md-12 no-sm-h-padding'>
                        <div className='bannerContnt'  style={{backgroundImage: "url(data:image/jpeg;base64,"+localStorage.program_watermark+")"}}>
                            <div className='bannerContntOverlay'>

                                <div className="mb-5">
                                    <h3 className='banner-h3'>
                                        <span dangerouslySetInnerHTML={{__html: localStorage.programName}}/><br/>
                                        <span dangerouslySetInnerHTML={{__html: localStorage.companyName}}/>
                                    </h3>
                                    <h3 className='banner-h3' dangerouslySetInnerHTML={{__html: localStorage.programDate}} />
                                </div>
                                {
                                    this.props.programStatus === 2
                                        ?
                                        <>
                                            <h3 className='banner-h3'>
                                                {/*This site will be enabled from 02.00 PM on August 13 2020*/}
                                                {this.state.bannerText}
                                            </h3>
                                            
                                            <h3>{this.state.countDownTime}</h3>

                                            <br></br>

                                            <p style={{width:'205px', margin:'10px auto'}}>
                                            <AddToCalendarButton
                                                name={localStorage.programName+' - '+localStorage.companyName}
                                                startDate={calDate}
                                                timeZone="Asia/Dhaka"
                                                options="'Apple','Google','iCal','Outlook.com','Yahoo','MicrosoftTeams','Microsoft365'"
                                                trigger="click"
                                            ></AddToCalendarButton>

                                            </p>

                                            {localStorage.registration_module === '1' &&
                                            <div className="col-sm-12">                                        
                                                <div className="col-sm-12 text-center">                                        
                                                    <button type="button" className="mt-1 mr-2 btn btn-xs btn-info login-btn" data-toggle="modal" data-target="#register">
                                                        Pre-Registration
                                                    </button>
                                                </div>                                        
                                            </div>
                                            }
                                        </>
                                        :
                                        <>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <h3 className='text-danger'>Program Ended</h3>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UpcomingBanner;