import React, {Component} from 'react';
import Jitsi from 'react-jitsi';
import {toast} from "react-toastify";

class JitsiConference extends Component {
    constructor(props) {
        super(props);
        this.roomName = localStorage.jitsiLink;
        this.userFullName = localStorage.userName;
        this.domain = localStorage.jitsi_domain;
        this.config = {
            fileRecordingsEnabled: false,
            liveStreamingEnabled: false
        }

        this.interfaceConfig = {}

        this.state = {
            isBlocked: false
        }
    }

    async componentDidMount() {

        console.log(localStorage.jitsi_domain);

        try {

            const stream = await navigator.mediaDevices.getUserMedia({audio: true, video: true});

            if(stream){

                //toast.success("Microphone Allowed.");
                this.setState({isBlocked: false});

            }else{

                toast.error("Camera or Microphone permission denied. Please reload your browser allow audio permission.");
                this.setState({isBlocked: true});

            }
            
        } catch(err) {

            toast.error("Camera or Microphone permission denied. Please reload your browser & allow audio permission.");
            this.setState({isBlocked: true});
            
        }
    }

    render() {
        return (
            <div className="player">
                <div className="panel">
                    <div id='react-jitsi-container' style={{width: '100%', height: 'auto'}}>
                        <div id='react-jitsi-frame'>
                            <Jitsi 
                            containerStyle={{width: '100%', height: 'auto'}}
                            roomName={this.roomName}
                            displayName={this.userFullName}
                            domain={this.domain}
                            config={this.config}
                            interfaceConfig={this.interfaceConfig}
                            />

                            {/* <iframe 
                                title="Zoom Conference"
                                style={{height: '550px'}}
                                allow="microphone;camera;fullscreen"
                                className="embed-responsive-item"
                                src={`${localStorage.mainHost}/gocon-zoom-sdk?mid=${localStorage.zoomMeetingId}&mp=${localStorage.zoomPassword}&u=${localStorage.userName}`}
                            /> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default JitsiConference;