import React, {Component} from 'react';
import axios from "axios";

class NavBarJs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            localizations: [],
        }
        this.key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj';
    }


    componentDidMount = () => {
        axios({
            method: 'post',
            url: `${localStorage.apiHost}/all-localization`,
            headers: {
                'key': this.key,
                // 'access-token': localStorage.token
            },
            data: {
                'program-id': localStorage.programId
            }
        }).then(response => {
            if (response.data.status === 200) {    
                // response.data[0].forEach(localization => {
                //     this.state.localizations.push(localization);
                // })
                this.setState({localizations: response.data[0]});           
            }
        }).catch((error) => {
            if(error.response){
                console.log(error.response);
            }
        });
    }
    
    changeLoginState = (event) => {
        localStorage.removeItem("moderator_login_id");
        const key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj';
        let url = "";
        if (localStorage.getItem('userType') != null) {
            if (localStorage.userType === "Shareholder") {
                url = `${localStorage.apiHost}/logout-shareholder`;
            } else {
                url = `${localStorage.apiHost}/logout-board-member`;
            }
        }
        axios({
            method: 'post',
            url: url,
            headers: {
                'key': key,
                'access-token': localStorage.token
            },
            data: {
                'program-id': localStorage.programId
            }
        }).then(response => {

            // console.log(response);

            localStorage.isLogin = "false";
            localStorage.loginID = "0";
            localStorage.removeItem("token");
            localStorage.removeItem("userName");
            localStorage.removeItem("userType");
            localStorage.removeItem("otpPhone");
            localStorage.removeItem("moderator_login_id");
            this.props.onClick("false");

            
            // let style = document.querySelector('style');
            // style.innerHTML += "#zmmtg-root {background-color: transparent !important; display: none;} .ReactModal__Overlay--after-open {display: none !important;}";
            
            // let mainRoot = document.getElementById('zmmtg-root');
            // mainRoot.style.display = 'none';
            
            // let zoomModal = document.getElementById('ReactModal__Overlay--after-open');
            // zoomModal.style.display = 'block';
        }).catch((error) => {
            if(error.response){
                console.log(error.response);
            }
        });

        event.preventDefault();
    }

    locChange = (event) => {
        
        this.props.changeLocalization(event.target.value);
        
    }

    render() {
        return (

            <div className="col-md-12 no-sm-h-padding mt-2 mb-2">
                <div className="col-md-12 viewer-section" style={{background: localStorage.navigationBarBgColor}}>
                    <div className="row mb-2 pt-2 pb-2">         
                        <div className="col-md-8 col-sm-6 col-xs-12 mt-auto mb-auto xs_padding_5">
                            <p style={{color: localStorage.navigationBarTextColor, fontSize:localStorage.navbarFontSize}}>

                                
                                {localStorage.total_attendance_status === '1' && 
                                    <span className='attens'>
                                        <b>{this.props.totalAttendedShareholdersText}: </b> 
                                        {this.props.totalShareHolders}; 

                                    </span>
                                }

                                {localStorage.total_share_status === '1' &&
                                    <span className='attens'><b>{this.props.totalSharesText}:</b> {this.props.totalShare}; </span>
                                }
                                
                                {localStorage.total_attendance_status === '1' && localStorage.show_online_physical_attendance_navbar === '1' &&
                                    
                                    <> 
                                        <b>Online:</b> {this.props.total_shareholder_evoting - (this.props.total_shareholder_physical+this.props.total_shareholder_evoting - this.props.totalShareHolders)}; &nbsp;
                                        <b>Physical:</b> {this.props.total_shareholder_physical - (this.props.total_shareholder_physical+this.props.total_shareholder_evoting - this.props.totalShareHolders)}; &nbsp;
                                        <b>Online+Physical:</b> {this.props.total_shareholder_physical+this.props.total_shareholder_evoting - this.props.totalShareHolders}; &nbsp;
                                    </> 
                                }

                                {localStorage.online_shareholder_attendance_status === '1' &&
                                    <span className='attens'><b>Total Shareholder's Attendance: </b>
                                    
                                        {this.props.onlineShareHolders}; &nbsp;
                                        {localStorage.show_online_physical_attendance_navbar === '1' &&
                                            <><b>Online:</b> {this.props.online_shareholders_evoting}; <b>Physical:</b> {this.props.online_shareholders_physical}; </>
                                        }
                                    </span>
                                }

                                {localStorage.online_shareholder_attendance_status === '1' && localStorage.show_online_physical_attendance_navbar !== '1' &&
                                    <span className='attens'><b>Total Shares:</b> {this.props.online_shares}</span>
                                }
                            </p>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12 mt-auto mb-auto xs_padding_5">
                            <p style={{color: localStorage.navigationBarTextColor, fontSize:localStorage.navbarFontSize}}>

                                {localStorage.localizationStatus == 1 &&  

                                    <select 
                                    onChange= {this.locChange}
                                    className="custom-select d-inline"
                                    style={{color: localStorage.localizationTextColor, backgroundColor:localStorage.localizationBGColor}}
                                    >

                                        {this.state.localizations.map((localization, index) => {
                                            
                                            return (
                                            
                                                <option key={index} value={localization.id}>{localization.name}</option>
                                            )
                                        })}
                                    </select>   
                                }                             
                                 
                                <a className="logout-icon" data-toggle="tooltip" title={this.props.logoutButton} href="" onClick={(e) => this.changeLoginState(e)}>
                                    {localStorage.logoutBtnAlignment == 0 &&
                                        <>
                                            <span style={{color: localStorage.navigationBarTextColor}} >{this.props.logoutButton} </span> 

                                            <span><img src='logout.png' alt="logout icon" className="my_icon"/></span>

                                            {/* <i  style={{color: localStorage.navigationBarTextColor}} className="icon fas fa-sign-out-alt"></i> */}

                                            &nbsp;
                                            &nbsp;
                                        </>
                                    }
                                    {localStorage.loggedInUsernameStatus == 1 &&
                                        <span style={{color: localStorage.navigationBarTextColor}} >{localStorage.userName}</span>
                                    }

                                    {localStorage.logoutBtnAlignment == 1 &&
                                        <>
                                            &nbsp;
                                            &nbsp;
                                            <span style={{color: localStorage.navigationBarTextColor}} >{this.props.logoutButton} </span> 

                                            <span><img src='logout.png' alt="logout icon" className="my_icon"/></span>

                                            {/* <i  style={{color: localStorage.navigationBarTextColor}} className="icon fas fa-sign-out-alt"></i> */}

                                           
                                        </>
                                    }
                                </a>
                            </p>
                        </div>
                                   
                    </div> 
                        
                </div>
            </div>

            // <nav className="navbar navbar-light navbar-expand-md justify-content-center"
            //      style={{background: localStorage.navigationBarBgColor}}>
            //     <button className="navbar-toggler ml-1 fr" type="button" data-toggle="collapse"
            //             data-target="#collapsingNavbar2">
            //         <span className="navbar-toggler-icon"></span>
            //     </button>
            //     <div className="navbar-collapse collapse justify-content-between  w-100" id="collapsingNavbar2">
            //         <ul className="nav navbar-nav flex-row justify-content-center flex-nowrap">
            //             <li className="nav-item"><a className="nav-link p5" href=""> <span className="green">
            //                 <span className="user_nav"
            //                       style={{color: localStorage.navigationBarTextColor}}>  Total attendance : {this.props.totalShareHolders}</span> </span>
            //             </a>
            //             </li>
            //         </ul>
            //         <ul className="navbar-nav mx-auto text-center">
            //             {(localStorage.userType === "Shareholder" || localStorage.userType === "boardMember") && this.props.agendaButtonShow === 1 &&
            //             <li className="nav-item active">
            //                 <a className="nav-link" href="#"
            //                    data-target="#myModal" data-toggle="modal"
            //                    style={{border: 'none', cursor: 'pointer', color: localStorage.navigationBarTextColor}}>Agenda
            //                     Vote
            //                 </a>
            //             </li>
            //             }

            //         </ul>
            //         <ul className="nav navbar-nav flex-row justify-content-center flex-nowrap">
            //             <li className="nav-item"><a className="nav-link p5" href=""> <span
            //                 className="my_icon"
            //                 style={{color: localStorage.navigationBarTextColor}}>{localStorage.userName}</span>
            //             </a></li>
            //             <li className="nav-item"><a className="nav-link p5" href=""
            //                                         onClick={(e) => this.changeLoginState(e)}><span>
            //                 <img src={require('../img/icon/sign-out-alt-solid.svg')} alt="logout icon"
            //                      className="my_icon"/></span>
            //             </a>
            //             </li>
            //         </ul>
            //     </div>


            //     {/* modal */}
            //     <div aria-modal="true" className="modal show" id="myModal">
            //         <div className="modal-dialog modal-full">
            //             <div className="modal-content">
            //                 <div className="modal-header border-0">
            //                     <h4>Agenda Vote</h4>
            //                     <button aria-label="Close" className="close" data-dismiss="modal" type="button">
            //                         <span aria-hidden="true">×</span>
            //                     </button>
            //                 </div>

            //                 <Agenda isModal={true} eventAgenda={this.props.eventAgenda}/>
            //             </div>
            //         </div>
            //     </div>
            //     {/* modal */}

            // </nav>
        );
    }
}

export default NavBarJs;