import React, {Component} from 'react';
import Agenda from "./Agenda";

class AgendaVote extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
        }
    }

    openModal = (e) => {
        let voteModal = window.$('#voteModal');
        voteModal.modal('show');
        this.setState({modalOpen: true});
        e.preventDefault();
    }
    closeModal = (e) => {
        let voteModal = window.$('#voteModal');
        voteModal.modal('hide');
        this.setState({modalOpen: false});
        e.preventDefault();
    }

    render() {
        // console.log(this.props.agendaModalFavorButton);
        return (
            <>
                {/* <div className="vote-here-blog mt-2">
                    {localStorage.voteHereBtnAlignment == 0 &&
                        <div className="row">
                            <div className="col-12">
                                <p className="mt-2"
                                style={{
                                    fontSize: localStorage.voteBlockTextFontSize
                                }}>
                                    {this.props.agendaVoteHereNote}
                                </p>
                            </div>

                            <div className="col-12">
                                <button className="btn"
                                        onClick={(e) => this.openModal(e)}
                                        style={{
                                            background: localStorage.voteHereButtonColor,
                                            color: localStorage.voteHereButtonTextColor,
                                            fontSize: localStorage.voteButtonFontSize
                                        }}>
                                    {this.props.agendaVoteHereButton}
                                </button>
                            </div>
                            
                        </div>
                    }
                    {localStorage.voteHereBtnAlignment == 1 &&
                        <div className="row">
                            <div className="col-5">
                                <button className="btn"
                                        onClick={(e) => this.openModal(e)}
                                        style={{
                                            background: localStorage.voteHereButtonColor,
                                            color: localStorage.voteHereButtonTextColor,
                                            fontSize: localStorage.voteButtonFontSize
                                        }}>
                                    {this.props.agendaVoteHereButton}
                                </button>
                            </div>
                            <div className="col-7">
                                <p className="mt-2"
                                style={{
                                    fontSize: localStorage.voteBlockTextFontSize
                                }}>
                                    {this.props.agendaVoteHereNote}
                                </p>
                            </div>                            
                            
                        </div>
                    }
                    {localStorage.voteHereBtnAlignment == 2 &&
                        <div className="row">                            
                            <div className="col-7">
                                <p className="mt-2"
                                style={{
                                    fontSize: localStorage.voteBlockTextFontSize
                                }}>
                                    {this.props.agendaVoteHereNote}
                                </p>
                            </div> 
                            <div className="col-5">
                                <button className="btn"
                                        onClick={(e) => this.openModal(e)}
                                        style={{
                                            background: localStorage.voteHereButtonColor,
                                            color: localStorage.voteHereButtonTextColor,
                                            fontSize: localStorage.voteButtonFontSize
                                        }}>
                                    {this.props.agendaVoteHereButton}
                                </button>
                            </div>                           
                            
                        </div>
                    }
                </div> */}

                <span className="btn" onClick={(e) => this.openModal(e)} style={{background: localStorage.voteHereButtonColor, color: localStorage.voteHereButtonTextColor, fontSize: localStorage.voteButtonFontSize, borderRadius:'5px'}}>
                    <img src='voteIcon.png' alt="logout icon" className="my_icon"/> {this.props.agendaVoteHereButton}
                    
                    {/* <button className="btn"
                    onClick={(e) => this.openModal(e)}
                    style={{
                        background: localStorage.voteHereButtonColor,
                        color: localStorage.voteHereButtonTextColor,
                        fontSize: localStorage.voteButtonFontSize
                    }}>
                        {this.props.agendaVoteHereButton}
                    </button> */}
                </span> 

                <div className="modal fade" id="voteModal" tabIndex="-1" role="dialog"
                     aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-md modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">{this.props.agendaVoteModalHeading}</h5>
                                <button  type="button" className="close" id="modal-close-button"
                                        onClick={(e) => this.closeModal(e)}
                                        aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body ">
                                <div className="model-blog p-0 pl-2 pr-2 mb-2">
                                    <Agenda 
                                        modalOpen={this.state.modalOpen} 
                                        isModal={true}
                                        eventAgenda={this.props.eventAgenda}
                                        agendaVoteHereButton={this.props.agendaVoteHereButton}
                                        agendaFavorButton={this.props.agendaFavorButton}
                                        agendaAgainstButton={this.props.agendaAgainstButton}
                                        subAgendaVoteRequiredText={this.props.subAgendaVoteRequiredText}
                                        subAgendaMaxAllowedVoteText={this.props.subAgendaMaxAllowedVoteText}
                                        subAgendaVoteSubmitButton={this.props.subAgendaVoteSubmitButton}
                                        currentLocalization={this.props.currentLocalization}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default AgendaVote;