import React from 'react'
import axios from 'axios';
import {toast} from "react-toastify";
import {isIOS, isMacOs} from 'react-device-detect';

class Comment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputComment: '',
            comments: []
        }
        this.deviceType = !isIOS || !isMacOs ? 2 : 1;
        this.key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj';
    }


    componentDidMount = () => {
            
        axios({
            method: 'post',
            url: `${localStorage.apiHost}/all-comment`,
            headers: {
                'key': this.key,
                'access-token': localStorage.token
            },
            data: {
                'program-id': localStorage.programId
            }
        }).then(response => {
            if (response.data.status === 200) {
                // response.data[0].forEach(comment => {
                //     this.state.comments.push(comment);
                // })
                this.setState({comments: response.data[0]});
                let elem = document.getElementById('chatbox-body');
                if(elem !== null){
                    elem.scrollTop = elem.scrollHeight;
                }
            }
        }).catch((error) => {
            if(error.response){
                console.log(error.response);
            }
        });
    }

    componentDidUpdate(prevProps) {

        if (this.props.eventComment !== prevProps.eventComment) {
            
            this.state.comments.push(this.props.eventComment);
            
            this.setState({comments: this.state.comments});

            setTimeout(function() {
                let elem = document.getElementById('chatbox-body');
                if(elem !== null){
                    elem.scrollTop = elem.scrollHeight;
                }
            }.bind(this), 2000);

            
        }

    }

    setComment = (event) => {
        this.setState({inputComment: event.target.value});
    }

    submitComment = (event) => {

        
        let inputComment = this.state.inputComment;
        if (inputComment.length > 0) {
            axios({
                method: 'post',
                url: `${localStorage.apiHost}/save-comment`,
                headers: {
                    'key': this.key,
                    'access-token': localStorage.token
                },
                data: {
                    'program-id': localStorage.programId,
                    'comment': inputComment
                }
            }).then(response => {
                if (response.data.status === 200) {
                    toast.success("Message sent successfully");
                    this.setState({inputComment: ''});                    
                 
                } else {
                    toast.error(response.data.message);
                }
            }).catch((error) => {
                if(error.response){
                    console.log(error.response);
                }
            });
        }

        event.preventDefault();
    }
    render() {
        
        
        return (<div className={`${localStorage.userType === 'boardMember'?"bm-chatbox col-lg-12 col-md-12":"col-lg-12 col-md-12" }`}>
                <div className={`${this.props.voiceBlockShow === 1 ? "btrc-chatbox":"chatbox" } ${localStorage.userType === "Shareholder" || localStorage.userType === "otherMember" ? "chatbox":"" }`}>
                    <div id="chatbox" className="chatbox-header p0" style={{background:localStorage.commentHeaderBgColor}}>
                        <h3 style={{background:localStorage.commentHeaderBgColor, color:localStorage.commentHeaderTextColor, fontSize:localStorage.commentLabelFontSize}}>{this.props.commentsHeading}<span>{this.state.comments.length}</span></h3>
                    </div>
                    <div id="chatbox-body" className="chatbox-body p-2" >
                    {/* <!-- /.direct-chat-messages start --> */}
                        {this.state.comments.map((comment, index) => {
                            

                            const months = ["Jan", "Feb", "Mar","Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

                            let current_datetime = new Date(comment.created);
                            let reply_com_current_datetime = new Date(comment.reply_at);

                            let mins = current_datetime.getMinutes();
                            let reply_com_mins = reply_com_current_datetime.getMinutes();

                            if(mins < 10){

                                mins = '0'+mins;

                            }
                            if(reply_com_mins < 10){

                                reply_com_mins = '0'+mins;

                            }
                            
                            let formatted_date = months[current_datetime.getMonth()] + " " + current_datetime.getDate() + ", " + current_datetime.getHours() + ":" + mins;

                            let reply_com_formatted_date = months[reply_com_current_datetime.getMonth()] + " " + reply_com_current_datetime.getDate() + ", " + reply_com_current_datetime.getHours() + ":" + reply_com_mins;

                            // if(!formatted_date || formatted_date == ''){
                            if(this.deviceType === 1){
                                formatted_date = comment.created;
                            }
                            
                            // if(!reply_com_current_datetime || reply_com_current_datetime == ''){
                            if(this.deviceType === 1){
                                reply_com_current_datetime = comment.reply_at;
                            }

                            var str = comment.comment;
                            var rep_comment = str.substring(0, 100);
                            // console.log(comment.reply_by);
                            if(comment.reply_by === 0){
                                    
                            
                                return (
                                    
                                
                                    <div className="direct-chat-messages pb-2"  key={index}>
                                        <div className="direct-chat-msg">
                                            <div className="direct-chat-infos">
                                                {/* <span className="direct-chat-name" style={{background:localStorage.commentBoIdBgColor, color:localStorage.commentBoIdTextColor, fontSize:localStorage.commentBoidFontSize}}>{this.props.commentsBoid}: {comment.boid}</span> */}

                                                <span className="direct-chat-name" style={{background:localStorage.commentBoIdBgColor, color:localStorage.commentBoIdTextColor, fontSize:localStorage.commentBoidFontSize}}>{comment.name}</span>


                                                <span className="direct-chat-timestamp right" style={{color:localStorage.commentTextColor, fontSize:  localStorage.commentDateFontSize}}>{formatted_date}</span>

                                            </div>
                                            <div className="pt-2 pb-2 mt-2 direct-chat-text">
                                                <p style={{background:localStorage.commentTextBgColor, color:localStorage.commentTextColor, fontSize:localStorage.commentTextFontSize}}>
                                                    <i style={{color:localStorage.commentTextBgColor}} className='fa fa-caret-up '></i>
                                                    {comment.comment}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                )
                            }else{
                                return (
                                    <div className="direct-chat-messages pb-2"  key={index}>
                                        <div className="direct-chat-msg">
                                            <div className="direct-chat-infos">
                                                <span className="direct-chat-name" style={{background:localStorage.commentBoIdBgColor, color:localStorage.commentBoIdTextColor, fontSize:localStorage.commentBoidFontSize}}>{comment.moderator_name}</span>
    
    
                                                <span className="direct-chat-timestamp right" style={{color:localStorage.commentTextColor, fontSize:  localStorage.commentDateFontSize}}>{reply_com_formatted_date}</span>
    
                                            </div>
                                            <div className="pt-2 pb-2 mt-2 direct-chat-text">
                                               
                                                <p style={{background:localStorage.commentTextBgColor, color:localStorage.commentTextColor, fontSize:localStorage.commentTextFontSize}} className="pt-2 pb-2">
                                                    <i style={{color:localStorage.commentTextBgColor}} className='fa fa-caret-up '></i>
                                                    <span className="d-block" style={{background:localStorage.commentTextBgColor, color:localStorage.commentTextColor, fontSize:localStorage.commentTextFontSize,opacity:0.6}}><em>{this.props.commentsBoid}: {comment.boid}<br></br>{rep_comment}...</em></span>

                                                    <span style={{borderTop:'1px solid gray', marginTop:'5px', paddingBottom:'5px',display:'block'}}></span>

                                                    <span style={{background:localStorage.commentTextBgColor, color:localStorage.commentTextColor, fontSize:localStorage.commentTextFontSize}} className="d-block "><i className='fa fa-reply-all'></i> {comment.reply}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>                                    
                                )
                            }
                        })}
                    {/* <!-- /.direct-chat-messages start --> */}

                </div>
                {/* <!-- /.card-body --> */}
                
                {/* <!-- /.card-footer--> */}
                {localStorage.userType === "Shareholder" && this.props.chatED === 1 &&
                    <div className="chatbox-footer">
                        <form id="comment" onSubmit={(e) =>this.submitComment(e) }>
                            <input onChange={this.setComment}
                                            value={this.state.inputComment} id="send_comments" type="text" placeholder={this.props.commentsTypePlaceholder} />
                            
                            {/* <a><i  onClick={(e) => this.submitComment(e)} style={{cursor: 'pointer'}} className="fab fa-atlassian"></i></a> */}
                            <button onClick={(e) => this.submitComment(e)} style={{background:localStorage.commentSendBtnBgColor, color:localStorage.commentSendBtnColor, cursor:'pointer'}} className="btn btn-xs">{this.props.commentsSendButton}</button>
                            {/* <a  onClick={(e) => this.submitComment(e)} style={{cursor: 'pointer'}} className="btn btn-primary bt"> Send</a> */}
                        </form>
                    </div>
                }
        </div>
    </div> 

            // <div className="panel">
            //     <div className="chat-panel">
            //         <div className="chatbox-header" style={{background:localStorage.commentHeaderBgColor}}>
            //             <div>
            //                 <h4 style={{color:localStorage.commentHeaderTextColor}}>Chat </h4>
            //             </div>
            //         </div>

            //         <div className="chatbox_body chatbox_scrollbar " style={{height: '380px'}}>
            //             {this.state.comments.map((comment, index) => {
            //                 return (<div className="chatbox_1 darker" style={{background:localStorage.commentTextBgColor}} key={index}>
            //                     <p className="chat_user_p w-25" style={{background:localStorage.commentBoIdBgColor, color:localStorage.commentBoIdTextColor}}>
            //                         BO : <span className="chat_user_id">{comment.boid}</span>
            //                     </p>
            //                     <p style={{color:localStorage.commentTextColor}}>{comment.comment}</p>
            //                     <span className="time-right">{comment.created}</span>
            //                 </div>)
            //             })}
            //         </div>

            //         {localStorage.userType === "Shareholder" &&
            //         <div className="messages-box">
            //             <form className="bg-light" id="comment">
            //                 <input onChange={this.setComment}
            //                        value={this.state.inputComment}
            //                        type="text"
            //                        id="send_comments" name="comments"
            //                        className="write_messages"
            //                        placeholder="Type a message"/>
            //                 <img
            //                     onClick={(e) => this.submitComment(e)}
            //                     src={require('../../img/icon/angle-right-solid.svg')}
            //                     alt="send"
            //                     style={{cursor: 'pointer'}}
            //                     className="send-icon"/>
            //             </form>
            //         </div>
            //         }
            //     </div>
            // </div>
        );
    }

}

export default Comment;
