import React, { Component } from "react";
import './PrintComponent.css';

class PrintComponent extends Component {
    render() {
        const { shareholder } = this.props;
        const htmlCode = localStorage.getItem('printer_setting'); // Get from localStorage

        // Check if required data is present
        if (!htmlCode || !shareholder) {
            return <div>Missing data or template!</div>;
        }

        // Ensure all properties exist on shareholder
        const {
            name = '',
            email = '',
            bo_id = '',
            mobile = '',
            total_share = '',
            father = '',
            mother = '',
            nid = '',
            dob = '',
            address = '',
            bank = ''
        } = shareholder;

        // Get the current date and time
        const currentDate = new Date();
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const currentDateString = currentDate.toLocaleDateString('en-US', options);
        const currentTimeString = currentDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });

        // Replace placeholders with actual data
        let updatedHTML = htmlCode
            .replace(/\[\[name\]\]/g, name)
            .replace(/\[\[email\]\]/g, email)
            .replace(/\[\[bo_id\]\]/g, bo_id)
            .replace(/\[\[mobile\]\]/g, mobile)
            .replace(/\[\[total_share\]\]/g, total_share)
            .replace(/\[\[father_name\]\]/g, father)
            .replace(/\[\[mother_name\]\]/g, mother)
            .replace(/\[\[nid\]\]/g, nid)
            .replace(/\[\[dob\]\]/g, dob)
            .replace(/\[\[address\]\]/g, address)
            .replace(/\[\[company_name\]\]/g, localStorage.companyName)
            .replace(/\[\[current_date\]\]/g, currentDateString)
            .replace(/\[\[current_time\]\]/g, currentTimeString)
            .replace(/\[\[bank_name\]\]/g, bank);

        // Render the updated HTML
        return (
            <div className="d-none d-print-block" dangerouslySetInnerHTML={{ __html: updatedHTML }} />
        );
    }
}


export default PrintComponent;
