import React from 'react';

class Footer extends React.Component {
    render() {
        // console.log(localStorage.moderator_login_id);
        return (
            <div className="col-md-12 no-sm-h-padding">
                <div className="col-md-12 footer-section" style={{ background: localStorage.footerBgColor, color: localStorage.footerTextColor }}>
                    <div className="row pt-1">
                        <div className="col-md-4 col-sm-12 m-auto pt-0 pb-0  xs-no-padding">
                            <p style={{ color: localStorage.footerTextColor }}>

                                {localStorage.inqueryNumber !== 'null' && localStorage.helpline_icon !== 'null' ? <img src={`data:image/jpeg;base64,${localStorage.helpline_icon}`} alt="helpline_icon" className='mr-2' style={{ width: '25px', height: 'auto' }} /> : ''}

                                {localStorage.inqueryNumber !== 'null' ?

                                    <span dangerouslySetInnerHTML={{ __html: localStorage.inqueryNumber }} />

                                    :

                                    ''

                                }

                            </p>
                        </div>

                        <div className="text-center col-md-4 col-sm-12 m-auto pt-0 pb-0  xs-no-padding">

                            {localStorage.showUserGuideOption === '1' || localStorage.showAnnualReportOption === '1' ?

                                <p className='user_guide' style={{ color: localStorage.footerTextColor }} >

                                    {localStorage.showUserGuideOption === '1' && localStorage.userguide_logo !== 'null' ? <img src={`data:image/jpeg;base64,${localStorage.userguide_logo}`} alt="userguide_logo" className='mr-2' style={{ width: '25px', height: 'auto' }} /> : ''}
                                    {localStorage.showUserGuideOption === '1' &&
                                        <a style={{ color: localStorage.footerTextColor }} href={localStorage.user_guide_url} target="_blank"> {localStorage.userGuide} </a>
                                    }

                                </p>

                                :
                                ''
                            }

                        </div>

                        <div className="col-md-4 col-sm-12 m-auto xs-no-padding">
                            <div className="right" style={{ fontSize: '12px' }}>
                                {/* {localStorage.moderator_login_id &&
                                    <>
                                        {localStorage.moderator_login_id.length > 6 &&
                                            <a target='_blank' id='report-download' style={{ color: localStorage.footerTextColor }} href={localStorage.mainHost + '/moderator/moderator-auto-login/' + localStorage.moderator_login_id}><b>Report Download</b> <i className='fa fa-download'></i>&nbsp;&nbsp;&nbsp;&nbsp;</a>
                                        }
                                    </>
                                } */}
                                {/* {localStorage.upcoming_program_status === '1' &&
                                    <span className="dropup">
                                        <a className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ color: localStorage.footerTextColor }}>
                                            Upcoming Programs
                                        </a>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <div className="dropdown-menu">

                                            {localStorage.upcoming_program_1 != 'null' &&
                                                <p className='pl-2 pb-1'><a target='_blank' href={localStorage.upcoming_program_1}>{localStorage.upcoming_program_1_name}</a></p>
                                            }
                                            {localStorage.upcoming_program_2 != 'null' &&
                                                <p className='pl-2 pb-1'><a target='_blank' href={localStorage.upcoming_program_2}>{localStorage.upcoming_program_2_name}</a></p>
                                            }
                                            {localStorage.upcoming_program_3 != 'null' &&
                                                <p className='pl-2 pb-1'><a target='_blank' href={localStorage.upcoming_program_3}>{localStorage.upcoming_program_3_name}</a></p>
                                            }
                                        </div>
                                    </span>
                                } */}
                                <p className='user_guide'>
                                    {localStorage.showAnnualReportOption === '1' && localStorage.annualreport_logo !== 'null' ? <img src={`data:image/jpeg;base64,${localStorage.annualreport_logo}`} alt="annualreport_logo" className='mr-2' style={{ width: '25px', height: 'auto' }} /> : ''}
                                    {localStorage.showAnnualReportOption === '1' &&
                                        <a style={{ color: localStorage.footerTextColor }} href={localStorage.annual_report_url} target="_blank">
                                            {localStorage.annualReport}
                                        </a>
                                    }
                                </p>
                            </div>
                            <span className="clear"></span>

                        </div>
                    </div>

                    <div className="row  mb-3">
                        <div className="col-md-4 col-sm-12 m-auto pt-0 pb-2  xs-no-padding">
                            <p style={{ color: localStorage.footerTextColor }}>

                                {localStorage.companyName !== 'null' ?

                                    <b dangerouslySetInnerHTML={{ __html: localStorage.companyName }} />

                                    :

                                    ''

                                }

                            </p>
                        </div>

                        <div className="text-center col-md-4 col-sm-12 m-auto pt-0 pb-2  xs-no-padding">
                            <p>
                                <b style={{ color: localStorage.footerTextColor }}>Powered By </b>
                                <a href="https://comjagat.org" target="_blank" className="Copyright_link" style={{ color: localStorage.footerTextColor }}>ComJagat Technologies</a>
                            </p>
                        </div>

                        <div className="col-md-4 col-sm-12 m-auto xs-no-padding text-center">
                            <div className="right pb-2" style={{ fontSize: '12px' }}>
                                {localStorage.moderator_login_id &&
                                    <>
                                        {localStorage.moderator_login_id.length > 6 &&
                                            <a target='_blank' id='report-download' style={{ color: localStorage.footerTextColor }} href={localStorage.mainHost + '/moderator/moderator-auto-login/' + localStorage.moderator_login_id}><b>Report Download</b> <i className='fa fa-download'></i>&nbsp;&nbsp;&nbsp;&nbsp;</a>
                                        }
                                    </>
                                }
                                {localStorage.upcoming_program_status === '1' &&
                                    <span className="dropup">
                                        <a className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ color: localStorage.footerTextColor }}>
                                            Upcoming Programs
                                        </a>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <div className="dropdown-menu">

                                            {localStorage.upcoming_program_1 != 'null' &&
                                                <p className='pl-2 pb-1'><a target='_blank' href={localStorage.upcoming_program_1}>{localStorage.upcoming_program_1_name}</a></p>
                                            }
                                            {localStorage.upcoming_program_2 != 'null' &&
                                                <p className='pl-2 pb-1'><a target='_blank' href={localStorage.upcoming_program_2}>{localStorage.upcoming_program_2_name}</a></p>
                                            }
                                            {localStorage.upcoming_program_3 != 'null' &&
                                                <p className='pl-2 pb-1'><a target='_blank' href={localStorage.upcoming_program_3}>{localStorage.upcoming_program_3_name}</a></p>
                                            }
                                        </div>
                                    </span>
                                }
                                <b style={{ color: localStorage.footerTextColor }}>GOCON v 2.19.1</b>
                            </div>
                            <span className="clear"></span>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;
