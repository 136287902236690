import React, { Component } from 'react';
import axios from 'axios';
import {toast} from "react-toastify";
import {browserName, fullBrowserVersion, engineName, engineVersion, deviceType, osName, osVersion} from 'react-device-detect';

class Otp extends Component {

    constructor(props) {
        super(props)
        this.state = {
            otp: null,
            errorMessage: "",
            resendMessage: ""
        }
    }

    componentDidMount() {

    }

    handleChange = (event) => {
        this.setState({
            otp: event.target.value
        });
    }

    handleSubmit = (event) => {

        let userAgent = browserName+' '+fullBrowserVersion+' '+deviceType+' '+engineName+' '+engineVersion+' '+osName+' '+osVersion+' ';

        const key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj';
        const url = `${localStorage.apiHost}/verify-otp`;
        // console.log("otp token", localStorage.otp_token);
        // console.log("otp", this.state.otp);
        axios({
            method: 'post',
            url: url,
            data:{
                'program-id': localStorage.programId,
                'otp': this.state.otp,
                'user-agent': userAgent
            },
            headers: {
                'key': key,
                'token': localStorage.otp_token,
            }

        }).then(response => {
            // console.log(response.data);
            if (response.data.status === 200) {

                localStorage.loginID = response.data.id;
                localStorage.isLogin = "true";
                localStorage.otpVerified = "true";
                localStorage.token = response.headers.token;
                localStorage.userName = response.data.name;
                localStorage.userType = "Shareholder"
                this.props.onClick("true");
            } else {
                toast.error(response.data.message);
            }
        }).catch((error) => {
            if(error.response){
                console.log(error.response);
            }
        });
        event.preventDefault();
    }

    resendOtp = (event) => {
        const key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj';
        const url = `${localStorage.apiHost}/resend-otp`;

        axios({
            method: 'post',
            url: url,
            data:{
                'program-id': localStorage.programId
            },
            headers: {
                'key': key,
                'token': localStorage.otp_token
            }
        }).then(response => {
            if (response.data.status === 200) {
                toast.success(response.data.message);
            }else{
                // console.log(response);
            }
        }).catch((error) => {
            if(error.response){
                console.log(error.response);
            }
        });
        event.preventDefault();
    }


    render() {
        return (
            <div className="my-5">
                <div className="row">
                    <div className="col-md-4 offset-md-4">
                        <div className="login_form board_member_login">
                            <div className="form_title">
                                <h4>{localStorage.otpPageTitle === "null" ? 'Login With OTP Code' : localStorage.otpPageTitle}</h4>
                            </div>

                            <p className="text-danger"></p>

                            <div className="form">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        {/* <label>{localStorage.otpPageSubtitle === "null" ? 'OTP code that we are sent to your mobile no ' : localStorage.otpPageSubtitle} ({localStorage.otpPhone}):</label> */}
                                        <label>{ `OTP code that we are sent to your ${localStorage.otp_for == 'email' ? 'email address':'mobile no'}`} ({localStorage.otpPhone}):</label>
                                        <input type="text" onChange={this.handleChange} name="otp" id="otp" className="form-control" placeholder={localStorage.otpCodeInputPlaceholder === "null" ? 'Your OTP code ' : localStorage.otpCodeInputPlaceholder} required="" />
                                        <input type="hidden" name="type" value="individual" />
                                        <input type="hidden" name="mobile" value="01723230609" />
                                    </div>

                                    <button type="submit" className="btn btn-success">{localStorage.otpSubmitButton === "null" ? 'Login' : localStorage.otpSubmitButton}</button>

                                    &nbsp;&nbsp;&nbsp;

                                    <button type="button" onClick={this.resendOtp} className=" btn btn-default">{localStorage.resendOtpButton === "null" ? 'Resend' : localStorage.resendOtpButton}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Otp;
