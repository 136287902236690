import React from 'react';
import axios from 'axios';
import {toast} from "react-toastify";
import {browserName, fullBrowserVersion, engineName, engineVersion, deviceType, osName, osVersion} from 'react-device-detect';

class OtherLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            member_other_id: null,
        }
    }

    handleChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        this.setState({
            [name]: value
        });
    }

    handleSubmit = (event) => {
			
        localStorage.isProxy = "";
        localStorage.proxy_tp = "";

		localStorage.isMultipleCompany = "";
        localStorage.company_tp = '';
        
        let userAgent = browserName+' '+fullBrowserVersion+' '+deviceType+' '+engineName+' '+engineVersion+' '+osName+' '+osVersion+' ';

        const key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj';
        const url = `${localStorage.apiHost}/board-member-login`;
        axios({
            method: 'post',
            url: url,
            data: {
                'board-member-id': this.state.member_other_id,
                'type': '2',
                'program-id': localStorage.programId,
                'ip': localStorage.ip,
                'user-agent': userAgent,
                'agm-type': this.props.agmType
            },
            headers: {
                'key': key
            }

        }).then(response => {
            if (response.data.status === 200) {
                localStorage.token = response.headers.token;
                localStorage.isLogin = "true";
                localStorage.userName = response.data.name;
                localStorage.userType = "otherMember";
                localStorage.moderator_login_id = response.data.moderator_login_id;
                this.props.onClick("true");
            } else {
                toast.error(response.data.message);
            }
        }).catch((error) => {
            if(error.response){
                console.log(error.response);
            }
        });
        event.preventDefault();
    }


    render() {
        return (
            <div className="card login-card mb-2">
                <div className="card-header login-card-header" style={{background:localStorage.loginTabHeaderBgColor, color:localStorage.loginTabHeaderTextColor}}>
                    <h3 className="card-title text-center" style={{fontSize:localStorage.loginAsHeadingFontSize}}>
                        {localStorage.loginAsOtherBmHeading === "null" ? 'Login as Others' : localStorage.loginAsOtherBmHeading}
                    </h3>
                </div>
                <div className="card-body pt-2 pb-3">
                    <div className="text-center">
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label className="font-weight-bold" style={{fontSize:localStorage.loginLabelFontSize, color:localStorage.loginLabelColor}}>
                                    {localStorage.otherBmIdInputLabel === "null" ? 'Other Member ID' : localStorage.otherBmIdInputLabel}:
                                </label>
                                <input onChange={this.handleChange} type="text" name="member_other_id"
                                       className="form-control" id="name"
                                       placeholder={`Enter Your ${localStorage.otherBmIdInputLabel === "null" ? "Board Member ID" : localStorage.otherBmIdInputLabel}`}
                                       required/>
                            </div>

                            <button type="submit" className="mt-1 btn btn-login"
                                    style={{background:localStorage.loginButtonBgColor,color:localStorage.loginButtonTextColor}}
                            >
                                {localStorage.loginButton === "null" ? 'Login' : localStorage.loginButton}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default OtherLogin;