import React, {Component} from 'react';

class Welcome extends Component
{
    render()
    {
        return (
            <div className="page-wrap d-flex flex-row align-items-center" style={{minHeight: '100vh'}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center">
                            <span className="display-1 d-block">Welcome to GoCon</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Welcome;