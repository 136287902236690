import React, { Component } from 'react';
import axios from 'axios';
import {toast} from "react-toastify";

class AllPresentation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bmSlides: [],
            classes: false
        }
        this.key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj';

    }

    componentDidMount = () => {
       
        //get boardmember slide data from api
        axios({
            method: 'post',
            url: `${localStorage.apiHost}/board-member-sliders`,
            headers: {
                'key': this.key,
                'access-token': localStorage.token
            },
            data: {
                'program-id': localStorage.programId
            }
        }).then(response => {
            if (response.data.status === 200) {  
                // response.data[0].forEach(bmSlide => {
                //     this.state.bmSlides.push(bmSlide);
                // })
                this.setState({bmSlides: response.data[0]});
                
            }
        }).catch((error) => {
            if(error.response){
                console.log(error.response);
            }
        });
    }
    sliderChange = (event, id) => {
        
        let clickedImg = event.target;
        // let allImg = document.getElementsByClassName('presentation-img');
        
        axios({
            method: 'post',
            url: `${localStorage.apiHost}/board-member-slider-status`,
            headers: {
                'key': this.key,
                'access-token': localStorage.token
            },
            data: {
                'program-id': localStorage.programId,
                'slider-id': id,
                'status': 1
            }
        }).then(response => {
            if (response.data.status === 200) {
                
                // allImg.classList.remove('presentation-img-selected');

                var elems = document.querySelectorAll(".presentation-img");

                [].forEach.call(elems, function(el) {
                    el.classList.remove("presentation-img-selected");
                });

                clickedImg.classList.add('presentation-img-selected');
                toast.success("Presentation slide changed successfully");
                
            } else {
                toast.error(response.data.message);
            }            
        }).catch((error) => {
            if(error.response){
                console.log(error.response);
            }
        });
    }
    render() {
        // console.log(this.state.classes);
        // const showbmSlides = this.state.bmSlides;
        // let className = 'presentation-img';
        
        return (  
            <>        
            {this.state.bmSlides.length > 0
                ?
                <div className="col-lg-12 col-md-12 col-sm-12 p0">
                    
                        <p className="all-presentation  bg-white ">                   
                            {this.state.bmSlides.map((bmSlide, index) => {
                                return (
                                    <img onClick={(e) => this.sliderChange(e, bmSlide.id)}  key={index} className={`${bmSlide.status === 1 ? "presentation-img-selected":"" } presentation-img`} src={`data:image/jpeg;base64,${bmSlide.image_url}`} alt="BM Slides"></img>
                                )
                            })
                            } 
                        </p>
                </div>
                :
                ''
            }
            </>
               
        );
        
    }
}

export default AllPresentation;