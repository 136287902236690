import React from "react";
import axios from 'axios';
import {toast} from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Notiflix from 'notiflix';
import "notiflix/dist/notiflix-3.2.6.min.css";
import {browserName, fullBrowserVersion, engineName, engineVersion, deviceType, osName, osVersion} from 'react-device-detect';


class IndividualLogin extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            otp: null
        }
        this.key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj'
    }
    

	handleChange = (event) => {
		const value = event.target.value;
		const name = event.target.name;

        this.setState({

            [name]: value

        });
	}

    handleSubmit = (event) => {

        const key = this.key;
        const url = `${localStorage.apiHost}/otp-login`;
        const data = {
            'otp': this.state.otp,
            'program-id': localStorage.programId,  
        };
        // console.log(data);
    
        axios({
            method: 'post',
            url: url,
            data: data,
            headers: {
                'key': key
            }

        }).then(response => {

            if (response.data.status === 200) {

                localStorage.loginID = response.data.id;
                localStorage.token = response.headers.token;
                localStorage.isLogin = "true";
                localStorage.userName = response.data.name;
                localStorage.userType = "Shareholder"
                this.props.onClick("true");
                toast.success(response.data.message);
                
            } else {
                toast.error(response.data.message);
            }
        }).catch((error) => {
            if(error.response){
                console.log(error.response);
            }
        });
        event.preventDefault();
    }

    
    render() {

        return (
            <div className="tab-pane fade show active" id="nav-otp" role="tabpanel" aria-labelledby="nav-individual-tab">
                <div className="individual-form px-4 py-1">
                    <form onSubmit={this.handleSubmit}>

                        <div className="form-group">
                            <label className="font-weight-bold" style={{fontSize:localStorage.loginLabelFontSize, color:localStorage.loginLabelColor}}>
                                OTP:
                            </label>
                            <input onChange={e => { this.handleChange(e) }}
                                   type="text" name="otp" className="form-control" id="otp"
                                   required minLength="1" placeholder="Enter your OTP" />
                        </div>
                        <br></br>


                        <button type="submit" className="mt-1 btn btn-login" value="submit"
                                style={{background:localStorage.loginButtonBgColor, color:localStorage.loginButtonTextColor}}>
                            {localStorage.loginButton === "null" ? 'Login' : localStorage.loginButton}
                        </button>
                    </form>
                </div>
            </div>
        );
    }
}

export default IndividualLogin;