import React, {Component} from 'react';
import axios from "axios";

class AttendanceUI extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        return (

            <div className="col-md-12 no-sm-h-padding mt-2 mb-2">
                <div className="col-md-12 viewer-section" style={{background: localStorage.navigationBarBgColor}}>
                    <div className="row mb-2 pt-2 pb-2">         
                        <div className="col-md-12 col-sm-12 col-xs-12 mt-auto mb-auto xs_padding_5">
                            <h2 style={{color: localStorage.navigationBarTextColor, fontSize:localStorage.navbarFontSize}} className='text-center'>


                                <br></br>
                                <br></br>
                                <br></br>

                                <b>Shareholder's Information</b>

                                <br></br>
                                <br></br>
                                
                                {localStorage.total_attendance_status === '1' && 
                                    <span className='attens'>
                                        <b>{this.props.totalAttendedShareholdersText}: </b> 
                                        {this.props.totalShareHolders}; 

                                    </span>
                                }

                                {localStorage.total_share_status === '1' &&
                                    <span className='attens'><b>{this.props.totalSharesText}:</b> {this.props.totalShare}; </span>
                                }
                                
                                {localStorage.total_attendance_status === '1' && localStorage.show_online_physical_attendance_navbar === '1' &&
                                    
                                    <> 
                                    
                                    <br></br>
                                    <b>Online:</b> {this.props.total_shareholder_evoting - (this.props.total_shareholder_physical+this.props.total_shareholder_evoting - this.props.totalShareHolders)}; &nbsp;
                                    <b>Physical:</b> {this.props.total_shareholder_physical - (this.props.total_shareholder_physical+this.props.total_shareholder_evoting - this.props.totalShareHolders)}; &nbsp;
                                    <b>Online+Physical:</b> {this.props.total_shareholder_physical+this.props.total_shareholder_evoting - this.props.totalShareHolders}; &nbsp;
                                    </> 
                                }

                                <br></br>
                                <br></br>
                                {localStorage.online_shareholder_attendance_status === '1' &&
                                    <span className='attens'><b>Total Shareholder's Attendance: </b>
                                    
                                        {this.props.onlineShareHolders}; &nbsp;
                                        {localStorage.show_online_physical_attendance_navbar === '1' &&
                                            <> 
                                                <br></br>
                                                <b>Online:</b> {this.props.online_shareholders_evoting}; &nbsp;
                                                <b>Physical:</b> {this.props.online_shareholders_physical}; &nbsp;
                                                <b>Online+Physical:</b> {this.props.online_shareholders_physical+this.props.online_shareholders_evoting - this.props.onlineShareHolders}; &nbsp;
                                            </> 
                                        }
                                    </span>
                                }

                                {localStorage.online_shareholder_attendance_status === '1' && localStorage.show_online_physical_attendance_navbar !== '1' &&
                                    <span className='attens'><b>Total Shares:</b> {this.props.online_shares}</span>
                                }

                                
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                            </h2>
                        </div>     
                    </div> 
                        
                </div>
            </div>
        );
    }
}

export default AttendanceUI;