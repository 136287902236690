import React, { Component } from 'react';
import axios from 'axios';
import {toast} from "react-toastify";


class FloatingChatBox extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            showChatBox: false,
            inputComment: '',
            comment_type: 'select_user',
            comments: [],
            bms: [],
            mods: [],
        }
        this.key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj';
    }

    componentDidMount = () => {
        this.setState({
            comments: this.props.eventbMComment,
        });


        axios({
            method: 'post',
            url: `${localStorage.apiHost}/all-comment-bm`,
            headers: {
                'key': this.key,
                'access-token': localStorage.token
            },
            data: {
                'program-id': localStorage.programId
            }
        }).then(response => {
            if (response.data.status === 200) {
                // response.data[0].map(comment => {
                //     this.state.comments.push(comment);
                // })
                this.setState({ comments: response.data[0] });
                setTimeout(function() {
                    let elem = document.getElementById('bm-chatbox-body');
                    if(elem !== null){
                        elem.scrollTop = elem.scrollHeight;
                    }
                }.bind(this), 2000);
            }
        }).catch((error) => {
            if(error.response){
                console.log(error.response);
            }
        });


        axios({
            method: 'post',
            url: `${localStorage.apiHost}/all-board-member`,
            headers: {
                'key': this.key
            },
            data: {
                'program-id': localStorage.programId
            }
        }).then(response => {
            if (response.data.status === 200) {
                // response.data[0].map(bm => {
                //     this.state.bms.push(bm);
                // })
                this.setState({ bms: response.data[0]});

                // console.log(this.state.bms);
            }
        }).catch((error) => {
            if(error.response){
                console.log(error.response);
            }
        });

        axios({
            method: 'post',
            url: `${localStorage.apiHost}/all-moderator`,
            headers: {
                'key': this.key
            },
            data: {
                'program-id': localStorage.programId
            }
        }).then(response => {
            if (response.data.status === 200) {
                // response.data[0].map(mod => {
                //     this.state.mods.push(mod);
                // })
                this.setState({ mods: response.data[0]});

                // console.log(this.state.mods[0].name);
            }
        }).catch((error) => {
            if(error.response){
                console.log(error.response);
            }
        });


    }

    
    componentDidUpdate(prevProps) {

        if (this.props.eventbMComment !== prevProps.eventbMComment) {

            // console.log(this.props.eventbMComment);
            
            this.state.comments.push(this.props.eventbMComment);
            
            this.setState({comments: this.state.comments});

            setTimeout(function() {
                let elem = document.getElementById('bm-chatbox-body');
                if(elem !== null){
                    elem.scrollTop = elem.scrollHeight;
                }
            }.bind(this), 2000);

            
        }

    }

    openChatBox = (event) => {
        localStorage.bmChatNotification = "false";
        this.setState({ showChatBox: true });
        event.preventDefault();
    }

    closeChatBox = (event) => {
        localStorage.bmChatNotification = "false";
        this.setState({ showChatBox: false });
        event.preventDefault();
    }

    setComment = (event) => {
        this.setState({ 
            inputComment: event.target.value
        });
    }
    setCommentType = (event) => {
        this.setState({ 
            comment_type: event.target.value 
        });
    }

    submitComment = (event) => {
        let inputComment = this.state.inputComment;
        let comment_type = this.state.comment_type;

        if(comment_type === 'select_user'){
            
            toast.error("Please select a user for private chat");

        }else{
        
            if (inputComment.length > 0) {
                axios({
                    method: 'post',
                    url: `${localStorage.apiHost}/save-comment-bm`,
                    headers: {
                        'key': this.key,
                        'access-token': localStorage.token
                    },
                    data: {
                        'program-id': localStorage.programId,
                        'comment': inputComment,
                        'comment_type': comment_type
                    }
                }).then(response => {
                    if (response.data.status === 200) {
                        toast.success(response.data.message);
                        document.getElementById("bm-comment-form").reset();
                        this.setState({ comment_type: 'select_user' });
                    } else {
                        toast.error(response.data.message);
                    }
                }).catch((error) => {
                    if(error.response){
                        console.log(error.response);
                    }
                });
            }
        }

        event.preventDefault();
    }

    broadCastAudio = (audioId, event) => {
        //broadcast audio to all shareholders
        axios({
            method: 'post',
            url: `${localStorage.apiHost}/send-audio-broadcast`,
            headers: {
                'key': this.key,
                'access-token': localStorage.token
            },
            data: {
                'program-id': localStorage.programId,
                'voice-id': audioId
            }
        }).then(response => {
            if (response.data.status === 200) {
                alert("audio successfully broadcast");
            }
        }).catch((error) => {
            if(error.response){
                console.log(error.response);
            }
        });
        event.preventDefault();
    }

    render() {
        let elem = document.getElementById('bm-chatbox-body');
        if(elem !== null){
            elem.scrollTop = elem.scrollHeight;
        }
        
        return (
            <div>
                {
                    this.state.showChatBox === true
                        ?
                        <div className="chat-popup-box">
                            <div className="chat-panel round-top" style={{backgroundColor:'white'}}>
                                <div className="chatbox-header">
                                    <div className="col-md-11 col-sm-11 col-xs-11">
                                        <h5>{this.props.bmModeratorChatHeading}</h5>
                                    </div>
                                    <div>
                                        <img onClick={(e) => this.closeChatBox(e)}
                                            src="/styles/img/icon/times-solid.svg" alt="exit Icon"
                                            className="chatbox_icon"
                                            style={{ cursor: 'pointer' }} /> 
                                    </div>
                                </div>
                                <div id="bm-chatbox-body" className="chatbox_body chatbox_scrollbar" style={{height: '380px'}}>


                                    {this.state.comments.map((comment, index) => {
                                        return (
                                            <>
                                            {comment.public_private_status === 0

                                            ?

                                            <div className="chatbox_1 lighter" key={index}>
                                                <strong className={comment.bm_name === null ? 'fa-pull-left' : 'fa-pull-right'}>{comment.bm_name ?? comment.moderator_name}</strong><br />
                                                {
                                                    comment.comment !== null &&
                                                    <p>{comment.comment}</p>
                                                }

                                                {
                                                    comment.voice !== null &&
                                                    <>
                                                        <audio controls style={{ width: '100%', marginTop:'5px' }}>
                                                            <source src={comment.voice} type="audio/mp3" />
                                                        </audio>
                                                        <button onClick={(e) => this.broadCastAudio(comment.voice_id, e)} >{this.props.bmModeratorChatBroadcastButton}</button>
                                                    </>
                                                }
                                                <br />
                                                <span className="time-right">{comment.created}</span>
                                            </div>

                                            :

                                            <>

                                                {comment.bm_id == localStorage.bmid 

                                                ?

                                                <div className="chatbox_1 lighter" key={index}>

                                                    <strong className={`text-danger ${comment.bm_name === null ? 'fa-pull-left' : 'fa-pull-right'}`}>

                                                        {comment.bm_name ?? comment.moderator_name} to {comment.which_bm_name ?? comment.which_moderator_name} (Privately)
                                                        
                                                    </strong>
                                                    
                                                    <br/>
                                                    {
                                                        comment.comment !== null &&
                                                        <p>{comment.comment}</p>
                                                    }

                                                    {
                                                        comment.voice !== null &&
                                                        <>
                                                            <audio controls style={{ width: '100%', marginTop:'5px' }}>
                                                                <source src={comment.voice} type="audio/mp3" />
                                                            </audio>
                                                            <button onClick={(e) => this.broadCastAudio(comment.voice_id, e)} >{this.props.bmModeratorChatBroadcastButton}</button>
                                                        </>
                                                    }
                                                    <br />
                                                    <span className="time-right">{comment.created}</span>
                                                </div>

                                                :

                                                <>
                                                    {comment.which_bm == localStorage.bmid && 
                                                        <div className="chatbox_1 lighter" key={index}>

                                                            <strong className={`text-danger ${comment.bm_name === null ? 'fa-pull-left' : 'fa-pull-right'}`}>

                                                                {comment.bm_name ?? comment.moderator_name} to {comment.which_bm_name ?? comment.which_moderator_name} (Privately)
                                                                
                                                            </strong>
                                                            
                                                            <br/>
                                                            {
                                                                comment.comment !== null &&
                                                                <p>{comment.comment}</p>
                                                            }

                                                            {
                                                                comment.voice !== null &&
                                                                <>
                                                                    <audio controls style={{ width: '100%', marginTop:'5px' }}>
                                                                        <source src={comment.voice} type="audio/mp3" />
                                                                    </audio>
                                                                    <button onClick={(e) => this.broadCastAudio(comment.voice_id, e)} >{this.props.bmModeratorChatBroadcastButton}</button>
                                                                </>
                                                            }
                                                            <br />
                                                            <span className="time-right">{comment.created}</span>
                                                        </div>
                                                    }
                                                </>

                                                }

                                            </>

                                            }
                                            </>
                                        );
                                    })
                                    }

                                </div>


                                <div className="messages-box">
                                    <form id="bm-comment-form" onSubmit={(e) => this.submitComment(e)}>
                                        {(localStorage.bm_moderator_private_chat === '1' || localStorage.bm_bm_private_chat === '1') &&
                                            <select 
                                                className="bm_mod_private_chat_dropdown"
                                                onChange={(e) => this.setCommentType(e)}
                                                name="comment_type" 
                                                required
                                            >

                                                <option value="select_user">Select User for Private Chat</option>

                                                <option value="0">All User</option>

                                                {localStorage.bm_moderator_private_chat === '1' &&

                                                    <optgroup label="Moderator">
                                                        {
                                                        this.state.mods.map((mods, index) => {
                                                            return (
                                                                <option key={index} value={'1_'+mods.id}>{mods.name} - {mods.department}</option>
                                                            );
                                                        })
                                                        }
                                                    </optgroup>

                                                }

                                                {localStorage.bm_bm_private_chat === '1' &&

                                                    <optgroup label="Board Member">
                                                        {
                                                        this.state.bms.map((bms, index) => {
                                                            return (
                                                                <>
                                                                {localStorage.bmid != bms.id &&
                                                                    <option key={index} value={'2_'+bms.id}>{bms.name}</option>
                                                                }
                                                                </>
                                                            );
                                                        })
                                                        }
                                                    </optgroup>

                                                }

                                            </select>
                                        }
                                        
                                        <input 
                                            onChange={(e) => this.setComment(e)}
                                            type="text" 
                                            name="comment" 
                                            className="write_messages "
                                            
                                            // className={`${(localStorage.bm_bm_private_chat === '1' || localStorage.bm_moderator_private_chat === '1') ? "write_messages write_messages_private" : "write_messages" }`}

                                            placeholder={this.props.bmModeratorChatTypePlaceholder}
                                        />

                                        <img onClick={(e) => this.submitComment(e)}
                                            src="/styles/img/icon/angle-right-solid.svg"
                                            alt="send"
                                            className="send-icon"
                                            style={{ cursor: 'pointer' }} />
                                    </form>
                                </div>


                            </div>
                        </div>
                        :
                        <button onClick={(e) => this.openChatBox(e)} className="chat-popup-button bm_mod_chat">
                            {
                                localStorage.bmChatNotification === "true" &&
                                <span className="notify_bm_chat"><i className="fa fa-bell" /></span>
                            }
                            <img
                                src="/styles/img/icon/comment-dots-solid.svg" alt="" 
                                className={`chat-pop-up-icon`} /> {this.props.bmModeratorChatHeading}
                        </button>
                }
            </div>
        );
    }
}

export default FloatingChatBox;