import React, {Component} from 'react';
import {isIOS, isMacOs} from 'react-device-detect';
class ZoomConference extends Component {    

    constructor(props) {
        super(props);
        this.deviceType = isIOS || isMacOs ? 2 : 1;
    }
    
    render() {
        return (
            <div className="player">
                <div className="panel">
                    {
                    localStorage.zoomMeetingId !== 'null'
                    ?
                    <>   

                        <iframe 
                            title="Zoom Conference"
                            style={{height: '550px'}}
                            allow="microphone;camera;fullscreen"
                            className="embed-responsive-item"
                            // src="https://gocon.comjagat.org/zoom.html"
                            // src="http://localhost:3000/zoom.html"
                            src="https://gocon.comjagat.org/zoom.html"
                        />

                        {/* {this.deviceType === 1
                        ?
                            // <iframe 
                            //     title="Zoom Conference"
                            //     style={{height: '550px'}}
                            //     allow="microphone;camera;fullscreen"
                            //     className="embed-responsive-item"
                            //     src={`${localStorage.mainHost}/gocon-zoom-sdk?mid=${localStorage.zoomMeetingId}&mp=${localStorage.zoomPassword}&u=${localStorage.userName}`}
                            // />
                            <iframe 
                                title="Zoom Conference"
                                style={{height: '550px'}}
                                allow="microphone;camera;fullscreen"
                                className="embed-responsive-item"
                                src="https://test-gocon.comjagat.org/zoom.html"
                                // src="http://localhost:3000/zoom.html"
                            />
                        :

                        <div className="p-2 pt-5 pb-5 bg-white"
                            style={{height: '330px', borderRadius: '10px'}}>
                            <div>
                                <i className="fa fa-info-circle text-center" style={{fontSize:'48px', color:'tomato', display:'block'}}></i>
                                <h3 className="text-danger text-center">Device  not supported</h3>
                            </div>
                            
                            <p className="pt-2 text-center">
                                <b>Zoom restrict your browser to support Zoom Web Meetings.</b>
                                <br></br>
                                <b>Supported Devices:</b> Windows 10, Windows 11 & Android.
                                <br></br>
                                <b>Supported Browsers:</b> Google Chrome (Updated Version), Mozilla Firefox (Updated Version).
                            </p>
                            

                        </div>

                        } */}
                    </>
                    : 

                    <div className="p-2 pt-5 pb-5 bg-white" style={{maxHeight: '400px', borderRadius: '10px'}}>
                        <div>
                            <br></br>
                            <br></br>
                            <br></br>
                            <h3 className="text-danger text-center">Welcome to the Board Member Panel</h3>
                            <br></br>
                            <br></br>
                            <br></br>
                        </div>                        

                    </div>
                    // <div className="p-5 text-danger">Conference Link Not Found</div>
                    }
                </div>
            </div>
        );
    }
}

export default ZoomConference;