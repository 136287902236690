import React from 'react';
import VoteButton from './VoteButton';
// import ProposedSeconded from "./ProposedSeconded";
import SubAgenda from "./SubAgenda";
import axios from 'axios';

class AgendaProjection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            agendas: [],
            disabledAgenda: [],
            // enableVoteButton: false
        }

        this.key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj';

        // sultana
        this.showproposed=true;
        // sultana
    }

    componentDidMount = () => {
        this.setState({agendas: this.props.eventAgenda});

    }

    changeAgendaStatus = (value) => {
        this.state.disabledAgenda.push(value);
    }

    render() {
        // console.log(this.state.agendas.length);

        var cols = 'col-md-4';

        if(this.state.agendas.length === 1){cols = 'col-md-12'}
        if(this.state.agendas.length === 2){cols = 'col-md-6'}
        if(this.state.agendas.length === 3){cols = 'col-md-4'}
        if(this.state.agendas.length === 4){cols = 'col-md-4'}
        if(this.state.agendas.length === 5){cols = 'col-md-4'}
        if(this.state.agendas.length === 6){cols = 'col-md-4'}
console.log('this.props.agendaFavorButton');
console.log(this.props.agendaFavorButton);
        return (
            <div className="row justify-content-center" id='agenda_projection'>
                {this.state.agendas.map((agenda, index) => {

                    let total_share = localStorage.total_share*1;
                    let favor = agenda.favour ? agenda.favour*1 : 0;
                    let against = agenda.against ? agenda.against*1 : 0;

                    let vote_percentage_favor = 0;
                    let vote_percentage_against = 0;

                    if(localStorage.votingPercentageLogic === '1'){

                        if(total_share > 0 && favor > 0){
                            vote_percentage_favor = Math.round((favor*100/total_share) * 100) / 100;
                        }
    
                        if(total_share > 0 && against > 0){
                            vote_percentage_against = Math.round((against*100/total_share) * 100) / 100;
                        }

                    }

                    if(localStorage.votingPercentageLogic === '2'){

                        let voted_shares = favor + against;

                        if(voted_shares > 0 && favor > 0){
                            vote_percentage_favor = Math.round((favor*100/voted_shares) * 100) / 100;
                        }
    
                        if(voted_shares > 0 && against > 0){
                            vote_percentage_against = Math.round((against*100/voted_shares) * 100) / 100;
                        }
                        
                    }
                    
                    if(agenda.agenda_screen_status === 1){

                        return (

                        
                            <div className={`mb-3 ${cols}`} key={index}>
    
                                <div className="vote-agenda-blog row m-0 pb-2">
                                    <div className="col-lg-12 col-md-12 col-sm-12 p-0 mb-2">
    
    
                                        <h4 className='m-0' style={{background:localStorage.agendaHeaderBgColor, color:localStorage.agendaHeaderTextColor, fontSize:localStorage.agendaNameFontSize}}>
                                            <div dangerouslySetInnerHTML={{__html: agenda.agenda_name}}/>
                                            <div dangerouslySetInnerHTML={{__html: agenda.agenda_name_bn}}/>
                                        </h4>
                                            
    
                                    </div>
    
                                    {agenda.agenda_screen_text_status === 1 &&
                                    <div className={`col-lg-12 col-md-12 col-sm-12 details mb-2`} >
                                        
                                            <p style={{fontSize:localStorage.agendaDescriptionFontSizeEn}} dangerouslySetInnerHTML={{__html: agenda.description}} />
                                            
                                            <p className="pt-1" style={{fontSize:localStorage.agendaDescriptionFontSizeBn}} dangerouslySetInnerHTML={{__html: agenda.description_bn}} />
                                            
                                    </div>
                                    }
    
                                    {
                                        agenda.sub_agenda === 0 &&
                                        <VoteButton 
                                            onClick={(e) => this.changeAgendaStatus(e)}
                                            agenda={agenda}                            
                                            agendaFavorButton={this.props.agendaFavorButton}
                                            agendaAgainstButton={this.props.agendaAgainstButton}
                                            subAgendaVoteRequiredText={this.props.subAgendaVoteRequiredText}
                                            subAgendaMaxAllowedVoteText={this.props.subAgendaMaxAllowedVoteText}
                                            subAgendaVoteSubmitButton={this.props.subAgendaVoteSubmitButton}    
                                            buttonType={'physical'}
                                            agendaVoteHereButton={this.props.agendaVoteHereButton}
                                            agendaVoteCastedButton={this.props.agendaVoteCastedButton}
                                            vote_percentage_favor={vote_percentage_favor}
                                            vote_percentage_against={vote_percentage_against}
                                        />
                                    }
                                    {
                                        this.props.isModal && agenda.sub_agenda === 1 &&
                                        <SubAgenda subAgendaText={agenda.sub_agenda_text} agendaId={agenda.id} requiredVote={agenda.required_sub_agenda_vote}
                                        // agendaVoteModalButton={this.props.agendaVoteModalButton}
                                        subAgendaVoteRequiredText={this.props.subAgendaVoteRequiredText}
                                        subAgendaMaxAllowedVoteText={this.props.subAgendaMaxAllowedVoteText}
                                        subAgendaVoteSubmitButton={this.props.subAgendaVoteSubmitButton}   
                                        vote_given_sub_agenda={this.props.vote_given_sub_agenda}   
                                        buttonType={1}
                                        />
                                    }
    
                                </div>
    
                            </div>
                        
                        );
                    }
                })}

            </div>
            
        );
    }

}

export default AgendaProjection;
