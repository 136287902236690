import React from 'react';
import axios from 'axios';
import Echo from 'laravel-echo';
import BroadCastVideo from './shareholder/BroadCastVideo';
import Agenda from './shareholder/Agenda';
import Comment from './shareholder/Comment';
// import ViewerInfo from './shareholder/ViewerInfo';
import Audio from './shareholder/Audio';
import PresentationStream from './shareholder/PresentationStream';
import AgendaVote from "./shareholder/AgendaVote";
import FloatingChatBox from './shareholder/FloatingChatBox';
import JitsiConference from './shareholder/JitsiConference';
import NavBarJs from "./NavBar";
// import ShareHolderVideo from "./shareholder/ShareHolderVideo";
import Slider from "./shareholder/Slider";
import ProposedSeconded from "./shareholder/ProposedSeconded";
import AllPresentation from "./shareholder/AllPresentation";
// import ZoomTest from "./shareholder/ZoomTest";
import FloatingChatBoxForBMGroup from './shareholder/FloatingChatBoxForBMGroup';
import ZoomConference from './shareholder/ZoomConference';
import PhysicalAgendaVote from './shareholder/PhysicalAgendaVote';
import AgendaProjection from './shareholder/AgendaProjection';
import AttendanceUI from './AttendanceUI';
import $ from 'jquery';

window.io = require('socket.io-client');

class ShareHolderDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: [],
            bmComment: [],
            agenda: [],
            totalShare: 0,
            totalShareHolders: 0,
            onlineShareHolders: 0,
            online_shares: 0,

            total_shareholder_evoting: 0,
            total_shareholder_physical: 0,

            online_shareholders_evoting: 0,
            online_shareholders_physical: 0,

            broadCastedAudio: "",
            shareHolderSlide: null,
            voiceBlockShow: 1,
            sliderBlockShow: 0,
            agendaBlockShow: 0,
            chatBlockShow: 1,
            voteHereBlockShow: 0,
            proposedSecondedBlockShow: 0,
            presentationStream: localStorage.presentation_status,
            chat_ed: localStorage.chat_ed,
            bmGroupComment: [],
            remaining_agenda: 0,
            total_agenda: 0,

            attendanceBlockShow: 0,
            total_shareholder:0,
            total_shares:0,
            number_of_attendance:0,
            attendance_total_shares:0,
            percentage_of_attendees:0,
            percentage_of_attendees_shares:0,

            //localization

            currentLocalization: '1',

            totalAttendedShareholdersText: localStorage.totalAttendedShareholdersText,
            totalSharesText: localStorage.totalSharesText,

            logoutButton: localStorage.logoutButton,
            voiceNote: localStorage.voiceNote,
            voiceButtonRecord: localStorage.voiceButtonRecord,
            voiceButtonStop: localStorage.voiceButtonStop,
            commentsHeading: localStorage.commentsHeading,
            commentsTypePlaceholder: localStorage.commentsTypePlaceholder,
            commentsSendButton: localStorage.commentsSendButton,
            commentsBoid: localStorage.commentsBoid,

            // agendaVoteModalButton: localStorage.agendaVoteModalButton,
            agendaVoteModalHeading: localStorage.agendaVoteModalHeading,
            agendaVoteHereButton: localStorage.agendaVoteHereButton,
            agendaVoteCastedButton: localStorage.agendaVoteCastedButton,
            agendaVoteHereNote: localStorage.agendaVoteHereNote,
            agendaFavorButton: localStorage.agendaFavorButton,
            agendaAgainstButton: localStorage.agendaAgainstButton,

            subAgendaVoteRequiredText: localStorage.subAgendaVoteRequiredText,
            subAgendaMaxAllowedVoteText: localStorage.subAgendaMaxAllowedVoteText,
            subAgendaVoteSubmitButton: localStorage.subAgendaVoteSubmitButton,

            agendaProposedButton: localStorage.agendaProposedButton,
            agendaProposedName: localStorage.agendaProposedName,
            agendaProposedBoId: localStorage.agendaProposedBoId,

            agendaSecondedButton: localStorage.agendaSecondedButton,
            agendaSecondedName: localStorage.agendaSecondedName,
            agendaSecondedBoId: localStorage.agendaSecondedBoId,

            agendaDemamdedButton: localStorage.agendaDemamdedButton,
            agendaDemamdedTotal: localStorage.agendaDemamdedTotal,

            agendaVoteResultFavor: localStorage.agendaVoteResultFavor,
            agendaVoteResultAgainst: localStorage.agendaVoteResultAgainst,

            presentationTabHeading: localStorage.presentationTabHeading,
            agendaTabHeading: localStorage.agendaTabHeading,

            bmModeratorChatHeading: localStorage.bmModeratorChatHeading,
            bmModeratorChatBroadcastButton: localStorage.bmModeratorChatBroadcastButton,
            bmModeratorChatTypePlaceholder: localStorage.bmModeratorChatTypePlaceholder,
            bmGroupChatHeading: localStorage.bmGroupChatHeading,
            vote_given_sub_agenda: false,

        }
        this.key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj';
        localStorage.bmChatNotification = false;
        localStorage.bmGroupChatNotification = false;
        this.urlParams = new URLSearchParams(window.location.search);
        // Replace useRef with createRef
        this.tabContentRef = React.createRef();
        // this.handleScroll = this.handleScroll.bind(this);
    }

    // handleScroll() {
    //     const tabContent = this.tabContentRef.current;
    //     const scrollPos = tabContent.scrollTop;
    
    //     // Loop through all the vote-agenda-blog elements
    //     const voteAgendaBlogs = tabContent.querySelectorAll('.vote-agenda-blog');
    //     voteAgendaBlogs.forEach((blog) => {
    //         const voteGivenContainer = blog.querySelector('.vote-given-blog-container');
    //         if (!voteGivenContainer) return;
    
    //         // Calculate the position of the .vote-agenda-blog relative to the .tab-content
    //         const blogRect = blog.getBoundingClientRect();
    //         const tabContentRect = tabContent.getBoundingClientRect();
    
    //         const topOffset = blogRect.top - tabContentRect.top;
    //         const bottomOffset = topOffset + blog.offsetHeight;
    
    //         // Check if the scroll position is within the range of the current .vote-agenda-blog
    //         if (scrollPos >= (topOffset) && scrollPos < (bottomOffset)) {
    //             // Remove the "fixed" class from all other .vote-given-blog-container elements
    //             tabContent.querySelectorAll('.vote-given-blog-container').forEach((container) => {
    //                 container.classList.remove('fixed');
    //             });
    
    //             // Add the "fixed" class to the current one
    //             voteGivenContainer.classList.add('fixed');
    //         }
    //     });
    // }

    updateVoteStyles(tabContent) {
        if(localStorage.fixed_voting_bar === '1'){
            // Add "fixed" class to the first vote-agenda-blog
            const firstVoteAgenda = tabContent.querySelector('.vote-agenda-blog');
            if (firstVoteAgenda) {
                const voteGivenContainer = firstVoteAgenda.querySelector('.vote-given-blog-container');
                if (voteGivenContainer) {
                    voteGivenContainer.classList.add('fixed');
                }
            }
        
            // Add bottom margin to the last vote-agenda-blog
            const voteAgendaBlogs = tabContent.querySelectorAll('.vote-agenda-blog');
            voteAgendaBlogs.forEach((blog) => {
                blog.style.marginBottom = '0px';
            })
            
            const lastVoteAgenda = voteAgendaBlogs[voteAgendaBlogs.length - 1];
            if (lastVoteAgenda) {
                lastVoteAgenda.style.marginBottom = localStorage.physical_voting === '1' ? '230px' : '155px';
            }
        
            // Scroll to the top
            tabContent.scrollTop = 0;

            const voteAgendaBlogs2 = tabContent.querySelectorAll('.vote-given-blog-container');
            voteAgendaBlogs2.forEach((blog2) => {
                blog2.classList.add('hide');
            })
        }else{
            // Add "fixed" class to the first vote-agenda-blog
            const firstVoteAgenda = tabContent.querySelector('.vote-agenda-blog');
            if (firstVoteAgenda) {
                const voteGivenContainer = firstVoteAgenda.querySelector('.vote-given-blog-container');
                if (voteGivenContainer) {
                    voteGivenContainer.classList.remove('fixed');
                }
            }
        
            // Add bottom margin to the last vote-agenda-blog
            const voteAgendaBlogs = tabContent.querySelectorAll('.vote-agenda-blog');
            voteAgendaBlogs.forEach((blog) => {
                blog.style.marginBottom = '0px';
            })
        
            // Scroll to the top
            tabContent.scrollTop = 0;

            const voteAgendaBlogs2 = tabContent.querySelectorAll('.vote-given-blog-container');
            voteAgendaBlogs2.forEach((blog2) => {
                blog2.classList.remove('hide');
            })

        }
    }

    componentDidMount() {

        if(localStorage.userType === 'boardMember'){
            let style = document.querySelector('style');
            style.innerHTML += "#zmmtg-root {background-color: transparent !important; display: block;} .ReactModal__Overlay--after-open {display: none !important;}";
        }

        if (Number(localStorage.voteHereBlock) !== this.state.voteHereBlockShow) {
            this.setState({voteHereBlockShow: Number(localStorage.voteHereBlock)});
        }
        if (Number(localStorage.proposedSecondedFeature) !== this.state.proposedSecondedBlockShow) {
            this.setState({proposedSecondedBlockShow: Number(localStorage.proposedSecondedFeature)});
        }


        const tabContent = this.tabContentRef.current;
        // if(localStorage.fixed_voting_bar === '1'){
        //     $('.tab-content').on('scroll', function() {
        //         var scrollPos = $(this).scrollTop();
                
        //         $('.vote-agenda-blog').each(function() {
        //             var $this = $(this);
        //             var topOffset = $this.position().top; // relative to .tab-content
        //             var bottomOffset = topOffset + $this.outerHeight();
        //             var $voteGiven = $this.find('.vote-given-blog-container');
        //             console.log(scrollPos);
        //             console.log(topOffset);
        //             console.log(bottomOffset);
        //             // Check if the current blog is in the viewport
        //             if (scrollPos >= (topOffset + 155) && scrollPos < (bottomOffset)) {
        //                 // Remove fixed class from all other vote-given-blog-containers
        //                 $('.vote-given-blog-container').removeClass('fixed');
            
        //                 // Add fixed class to the current vote-given-blog-container
        //                 $voteGiven.addClass('fixed');
        //             }
        //         });
        //     });

        // }


        this.echo = new Echo({
            broadcaster: 'socket.io',
            host: 'https://echoserveraws.comjagat.org',
            // host: 'http://localhost:6001',
            auth: {
                headers: {
                    'key': this.key,
                    'access-token': localStorage.token
                }
            }
        });

        const echo = this.echo;

        // console.log(echo);

        // logout shareholder
        if(localStorage.userType === 'Shareholder'){
            echo.private('shareholder-remote-logout-' + localStorage.programId +'-'+ localStorage.loginID)
                .listen('.shareholder-remote-logout-event', (data) => {

                    // window.location.reload();

                    let url = `${localStorage.apiHost}/check-shareholder`;

                    axios({
                        method: 'post',
                        url: url,
                        headers: {
                            'key': this.key,
                            'access-token': localStorage.token
                        },
                        data: {
                            'program-id': localStorage.programId,
                            'login': 'login'
                        }
                    }).then(response => {

                        if (response.data.login !== "login") {
                            localStorage.loginID = "0";
                            this.changeLoginStatus('false');
                            localStorage.isLogin = "false";
                            localStorage.removeItem("token");
                            localStorage.removeItem("userName");
                            localStorage.removeItem("userType");
                            localStorage.removeItem("otpPhone");
                        }
                    }).catch((error) => {
                        if(error.response){
                            console.log(error.response);
                        }
                    });

                });

            echo.private('all-shareholder-logout-' + localStorage.programId)
                .listen('.all-shareholder-logout-event', (data) => {

                    // console.log('listening');

                    let url = `${localStorage.apiHost}/logout-shareholder`;

                    axios({
                        method: 'post',
                        url: url,
                        headers: {
                            'key': this.key,
                            'access-token': localStorage.token
                        },
                        data: {
                            'program-id': localStorage.programId
                        }
                    }).then(response => {

                        localStorage.loginID = "0";
                        this.changeLoginStatus('false');
                        localStorage.isLogin = "false";
                        localStorage.removeItem("token");
                        localStorage.removeItem("userName");
                        localStorage.removeItem("userType");
                        localStorage.removeItem("otpPhone");

                        // console.log('listening response');
                        // console.log(response);
                    }).catch((error) => {
                        if(error.response){
                            console.log(error.response);
                        }
                    });

                });
        }

        // subscirbe to presentation event
        echo.private('presentation-status-' + localStorage.programId)
            .listen('.presentation-status-event', (data) => {
                this.setState({presentationStream: data.presentation_status});
            });


        // subscirbe to comment channel so that shareholder can see realtime comment
        echo.private('approved-comment-' + localStorage.programId)
            .listen('.approved-comment-event', (commentData) => {
                this.setState({comment: commentData});
            });
        if(localStorage.userType === 'Shareholder' || localStorage.userType === 'otherMember'){
            echo.private(`reload-program-${localStorage.programId}`)
            .listen('.reload-program-event', (program_id) => {
                window.location.reload();
            });
        }
        if(localStorage.userType === 'boardMember'){
            echo.private(`reload-boardmember-program-${localStorage.programId}`)
            .listen('.reload-boardmember-program-event', (program_id) => {
                window.location.reload();
            });
        }


        //get all active agenda list when application load
        axios({
            method: 'post',
            url: `${localStorage.apiHost}/agenda`,
            headers: {
                'key': this.key,
                'access-token': localStorage.token
            },
            data: {
                'program-id': localStorage.programId
            }
        }).then(response => {
            // console.log(response);
            if (response.data.status === 200) {
                this.state.agenda.splice(0);
                response.data[0].forEach((agenda, index) => {
                    if (localStorage.showPSBox === "true" && agenda.proposed_sh) {
                        this.proposedSecondedAgenda = agenda;
                        this.agdIndex = index;
                    }
                    this.state.agenda.push(agenda);
                })
                this.setState({
                    agenda: this.state.agenda,
                    remaining_agenda: response.data.remaining_agenda,
                    total_agenda: response.data.total_agenda,
                });
                // Initial setup (first and last elements)
                this.updateVoteStyles(tabContent);
            }
        }).catch((error) => {
            if(error.response){
                console.log(error.response);
            }
        });


        axios({
            method: 'post',
            url: `${localStorage.apiHost}/check-sub-agenda-vote-status`,
            headers: {
                'key': this.key,
                'access-token': localStorage.token
            },
            data: {
                'program-id': localStorage.programId
            }
        }).then(response => {

            this.setState({vote_given_sub_agenda: response.data.vote_given_sub_agenda === 0 ? false : true });

        }).catch((error) => {
            if(error.response){
                console.log(error.response);
            }
        });

        //subscribe to Agenda channel so that shareholder can see agenda list which are open
        echo.private(`active-agenda-${localStorage.programId}`)
            .listen('.active-agenda-event', (agendaData) => {

                this.state.agenda.splice(0); //reset array

                agendaData[0].forEach((agenda, index) => {
                    this.state.agenda.push(agenda);
                })

                this.setState({agenda: this.state.agenda});

                axios({
                    method: 'post',
                    url: `${localStorage.apiHost}/agenda`,
                    headers: {
                        'key': this.key,
                        'access-token': localStorage.token
                    },
                    data: {
                        'program-id': localStorage.programId
                    }
                }).then(response => {
                    if (response.data.status === 200) {
                        this.setState({
                            remaining_agenda: response.data.remaining_agenda,
                            total_agenda: response.data.total_agenda,
                        });
                        // Initial setup (first and last elements)
                        this.updateVoteStyles(tabContent);
                    }
                }).catch((error) => {
                    if(error.response){
                        console.log(error.response);
                    }
                });



                axios({
                    method: 'post',
                    url: `${localStorage.apiHost}/check-sub-agenda-vote-status`,
                    headers: {
                        'key': this.key,
                        'access-token': localStorage.token
                    },
                    data: {
                        'program-id': localStorage.programId
                    }
                }).then(response => {

                    this.setState({vote_given_sub_agenda: response.data.vote_given_sub_agenda === 0 ? false : true });

                }).catch((error) => {
                    if(error.response){
                        console.log(error.response);
                    }
                });

            });

        //subscribe to shareholder-information channel
        //to get total logged in shareholders and total number of share
        echo.private(`shareholder-information-${localStorage.programId}`)
            .listen('.shareholder-information-event', (shareholders) => {
                // this.setState({
                //     totalShareHolders: shareholders.total_shareholder,
                //     totalShare: shareholders.total_share,
                //     onlineShareHolders: shareholders.online_shareholders,
                //     online_shares: shareholders.online_shares,

                //     total_shareholder_evoting: shareholders.total_shareholder_evoting,
                //     total_shareholder_physical: shareholders.total_shareholder_physical,

                //     online_shareholders_evoting: shareholders.online_shareholders_evoting,
                //     online_shareholders_physical: shareholders.online_shareholders_physical,
                // });

                axios({
                    method: 'post',
                    url: `${localStorage.apiHost}/shareholder-information`,
                    headers: {
                        'key': this.key,
                        'access-token': localStorage.token
                    },
                    data: {
                        'program-id': localStorage.programId
                    }
                }).then(response => {
                    if (response.data.status === 200) {
                        this.setState({
                            totalShareHolders: response.data.total_shareholder,
                            totalShare: response.data.total_share,
                            onlineShareHolders: response.data.online_shareholders,
                            online_shares: response.data.online_shares,

                            total_shareholder_evoting: response.data.total_shareholder_evoting,
                            total_shareholder_physical: response.data.total_shareholder_physical,

                            online_shareholders_evoting: response.data.online_shareholders_evoting,
                            online_shareholders_physical: response.data.online_shareholders_physical,
                        });
                    }
                }).catch((error) => {
                    if(error.response){
                        console.log(error.response);
                    }
                });
            });

        //get shareholder attendance information from api
        axios({
            method: 'post',
            url: `${localStorage.apiHost}/shareholder-information`,
            headers: {
                'key': this.key,
                'access-token': localStorage.token
            },
            data: {
                'program-id': localStorage.programId
            }
        }).then(response => {
            if (response.data.status === 200) {
                this.setState({
                    totalShareHolders: response.data.total_shareholder,
                    totalShare: response.data.total_share,
                    onlineShareHolders: response.data.online_shareholders,
                    online_shares: response.data.online_shares,

                    total_shareholder_evoting: response.data.total_shareholder_evoting,
                    total_shareholder_physical: response.data.total_shareholder_physical,

                    online_shareholders_evoting: response.data.online_shareholders_evoting,
                    online_shareholders_physical: response.data.online_shareholders_physical,
                });
            }
        }).catch((error) => {
            if(error.response){
                console.log(error.response);
            }
        });



        //Subscribe to Shareholder Audio broadcast event
        //this event fire when board member broadcast audio from Board member chat box
       // if (localStorage.userType === 'Shareholder') {
            echo.private(`audio-broadcast-${localStorage.programId}`)
                .listen('.audio-broadcast-event', (broadCastedAudio) => {
                    this.setState({
                        broadCastedAudio: broadCastedAudio.voice
                    });
                });


            //get shareholder slide data from api
            axios({
                method: 'post',
                url: `${localStorage.apiHost}/shareholder-sliders`,
                headers: {
                    'key': this.key,
                    'access-token': localStorage.token
                },
                data: {
                    'program-id': localStorage.programId
                }
            }).then(response => {
                if (response.data.status === 200) {
                    this.setState({
                        shareHolderSlide: response.data.image
                    });
                }
            }).catch((error) => {
                if(error.response){
                    console.log(error.response);
                }
            });

            //this event fire when presentation slide change by admin or board member
            echo.private(`active-slider-${localStorage.programId}`)
                .listen('.active-slider-event', (slide) => {
                    this.setState({
                        shareHolderSlide: slide.image
                    });
                });
            //get all component block status from api
            axios({
                method: 'post',
                url: `${localStorage.apiHost}/program-details`,
                headers: {
                    'key': this.key,
                    'access-token': localStorage.token
                },
                data: {
                    'program-id': localStorage.programId
                }
            }).then(response => {
                if (response.data.status === 200) {
                    console.log( response.data[0]);
                    this.setState({
                        chatBlockShow: response.data[0].chat_status,
                        agendaBlockShow: response.data[0].agenda_status,
                        sliderBlockShow: response.data[0].slider_status,
                        attendanceBlockShow: response.data[0].attendance_info_status,
                        voiceBlockShow: response.data[0].voice_status,
                        total_shareholder: response.data[0].total_shareholder,
                        total_shares: response.data[0].total_shares,
                        number_of_attendance: response.data[0].number_of_attendance,
                        attendance_total_shares: response.data[0].attendance_total_shares,
                        percentage_of_attendees: response.data[0].percentage_of_attendees,
                        percentage_of_attendees_shares: response.data[0].percentage_of_attendees_shares,
                    });
                }
            }).catch((error) => {
                if(error.response){
                    console.log(error.response);
                }
            });

            //Subscribe to component block status event
            echo.private(`chat-status-${localStorage.programId}`)
                .listen('.chat-status-event', (data) => {
                    this.setState({
                        chatBlockShow: data.chat_status,
                        chat_ed: data.chat_ed,
                    });
                });

            echo.private(`agenda-block-${localStorage.programId}`)
                .listen('.agenda-block-event', (data) => {
                    this.setState({
                        agendaBlockShow: data.agenda_status,
                    });
                    // fixed_voting_bar
                    localStorage.fixed_voting_bar = data.fixed_voting_bar;
                    this.updateVoteStyles(tabContent);
                });

            echo.private(`slider-block-${localStorage.programId}`)
                .listen('.slider-block-event', (data) => {
                    this.setState({
                        sliderBlockShow: data.slider_status,
                    });

                    if(data.slider_status === 1){
                        if(data.agenda_slider_visibility === 1){
                            document.getElementById('home-tab').click();
                        }else{
                            document.getElementById('profile-tab').click();
                        }
                    }


                });

            echo.private(`voice-status-${localStorage.programId}`)
                .listen('.voice-status-event', (data) => {
                    this.setState({
                        voiceBlockShow: data.voice_status,
                    });
                });

            echo.private(`proposed-seconded-vote-here-block-status-${localStorage.programId}`)
                .listen('.proposed-seconded-vote-here-block-event', (data) => {
                    // console.log("proposed seconded listen ", data);
                    if (this.state.voteHereBlockShow !== data.vote_here_block) {
                        this.setState({voteHereBlockShow: data.vote_here_block});
                    }
                    if (this.state.proposedSecondedBlockShow !== data.proposed_seconded_feature) {
                        this.setState({proposedSecondedBlockShow: data.proposed_seconded_feature});
                    }
                });

       // }

        //Subscribe to moderator and Board member chat
        //this event fire when moderator send message or audio file to board member
        //only board member can subscribe to this event
        if (localStorage.userType === 'boardMember' && localStorage.BmBoderatorChat === "1") {
            echo.channel(`bm-moderator-comment-${localStorage.programId}`)
                .listen('.bm-moderator-comment-event', (commentData) => {
                    localStorage.bmChatNotification = true;
                    this.setState({
                        bmComment: commentData,
                    });

                });
        }

        //Subscribe to Board member group chat
        //only board member can subscribe to this event

        if (localStorage.userType === 'boardMember' && localStorage.BmGroupChat === "1") {
            echo.channel(`bm-group-comment-${localStorage.programId}`)
                .listen('.bm-group-comment-event', (commentData) => {
                    localStorage.bmGroupChatNotification = true;
                    this.setState({
                        bmGroupComment: commentData,
                    });

                });
        }

    }
    componentWillUnmount = () => {
        this.echo.disconnect();

        // const tabContent = this.tabContentRef.current;
        // tabContent.removeEventListener('scroll', this.handleScroll);
    }

    changeLoginStatus = (value) => {
        this.props.onClick(value);
    }

    //localization chagne

    changeLocalization = (value) => {
        // console.log('Language: '+value);

        axios({
            method: 'post',
            url: `${localStorage.apiHost}/change-localization`,
            headers: {
                'key': this.key,
                'access-token': localStorage.token
            },
            data: {
                'program-id': localStorage.programId,
                'localization-id': value,
            }
        }).then(response => {
            if (response.data.status === 200) {
                this.setState({

                    currentLocalization: value,

                    totalAttendedShareholdersText: response.data[0].total_attended_shareholders_text,
                    totalSharesText: response.data[0].total_shares_text,

                    logoutButton: response.data[0].logout_button,
                    voiceNote: response.data[0].voice_note,
                    voiceButtonRecord: response.data[0].voice_button_record,
                    voiceButtonStop: response.data[0].voice_button_stop,
                    commentsHeading: response.data[0].comments_heading,
                    commentsTypePlaceholder: response.data[0].comments_type_placeholder,
                    commentsBoid: response.data[0].comments_boid,
                    commentsSendButton: response.data[0].comment_send_button,

                    // agendaVoteModalButton: response.data[0].agenda_vote_modal_button,
                    agendaVoteModalHeading: response.data[0].agenda_vote_modal_heading,
                    agendaVoteHereButton: response.data[0].agenda_vote_here_button,
                    agendaVoteCastedButton: response.data[0].agenda_vote_casted_button,
                    agendaVoteHereNote: response.data[0].agenda_vote_here_note,
                    agendaFavorButton: response.data[0].agenda_favor_button,
                    agendaAgainstButton: response.data[0].agenda_against_button,


                    subAgendaVoteRequiredText: response.data[0].sub_agenda_vote_required_text,
                    subAgendaMaxAllowedVoteText: response.data[0].sub_agenda_max_allowed_vote_text,
                    subAgendaVoteSubmitButton: response.data[0].sub_agenda_vote_submit_button,

                    agendaProposedButton: response.data[0].agenda_proposed_button,
                    agendaProposedName: response.data[0].agenda_proposed_name,
                    agendaProposedBoId: response.data[0].agenda_proposed_boid,

                    agendaSecondedButton: response.data[0].agenda_seconded_button,
                    agendaSecondedName: response.data[0].agenda_seconded_name,
                    agendaSecondedBoId: response.data[0].agenda_seconded_boid,

                    agendaDemamdedButton: response.data[0].agenda_demamded_button,
                    agendaDemamdedTotal: response.data[0].agenda_demamded_total,

                    agendaVoteResultFavor: response.data[0].agenda_vote_result_favor,
                    agendaVoteResultAgainst: response.data[0].agenda_vote_result_against,

                    presentationTabHeading: response.data[0].presentation_tab_heading,
                    agendaTabHeading: response.data[0].agenda_tab_heading,

                    bmModeratorChatHeading: response.data[0].bm_moderator_chat_heading,
                    bmModeratorChatBroadcastButton: response.data[0].bm_moderator_chat_broadcast_button,
                    bmModeratorChatTypePlaceholder: response.data[0].bm_moderator_chat_type_placeholder,

                    bmGroupChatHeading: response.data[0].bm_group_chat_heading

                })
            }
        }).catch((error) => {
            if(error.response){
                console.log(error.response);
            }
        });
    }

    closeConfirm = (e) => {
        document.getElementById('NotiflixConfirmWrap').remove();
        document.getElementById('closeConfirm').style.display = "none";
    }

    render() {
        // console.log(this.state.agendaFavorButton);
        // console.log("this.state.remaining_agenda");
        // console.log(this.state.remaining_agenda);
        let activeTabAgenda = true;
        let activeTabSlider = false;
        let activeTabAttendance = false;

        if (this.state.agendaBlockShow === 0 && this.state.sliderBlockShow === 1) {
            activeTabAgenda = false;
            activeTabSlider = true;
            activeTabAttendance = false;

        }else if(this.state.agendaBlockShow === 0 && this.state.sliderBlockShow === 0 && this.state.attendanceBlockShow === 1){
            activeTabAgenda = false;
            activeTabSlider = false;
            activeTabAttendance = true;
        }
        let style = document.querySelector('style');
        style.innerHTML += `
        .vote-blog .nav-tabs .nav-link{
            background-color:${localStorage.agendaPresentationTabBackgroudColor};
            color:${localStorage.agendaPresentationTabFontColor}
        }
        .vote-blog .nav-tabs .nav-link.active{
            background-color:${localStorage.agendaPresentationTabActiveBackgroudColor};
            color:${localStorage.agendaPresentationTabActiveFontColor}
        }`;

        // console.log("chat "+this.state.chat_ed);

        let presentationStatus = this.state.presentationStream * 1;
        let chatED = this.state.chat_ed * 1;


        // console.log(localStorage.translation);

        let shareholder_percentage = 0;
        let share_percentage = 0;

        if(this.state.totalShareHolders > 0 && this.state.totalShare > 0){

            shareholder_percentage = Math.round(((this.state.totalShareHolders / localStorage.total_shareholders_company) * 100) * 100) / 100;
            share_percentage = Math.round(((this.state.totalShare / localStorage.total_shares_company) * 100) * 100) / 100;

        }

        return (
            <div>


                <div id='closeConfirm' onClick={(e) => this.closeConfirm(e)}>
                    <i className="fa fa-times"></i>
                </div>

                {this.urlParams.has('projector') ?
                    ''
                :
                    <NavBarJs
                        eventAgenda={this.state.agenda}
                        onClick={(e) => this.changeLoginStatus(e)}
                        login={this.state.isLogin}
                        agendaButtonShow={this.state.agendaBlockShow}
                        totalShare={this.state.totalShare}
                        totalShareHolders={this.state.totalShareHolders}
                        onlineShareHolders={this.state.onlineShareHolders}
                        online_shares={this.state.online_shares}
                        changeLocalization = {this.changeLocalization}
                        totalAttendedShareholdersText={this.state.totalAttendedShareholdersText}
                        totalSharesText={this.state.totalSharesText}
                        logoutButton={this.state.logoutButton}

                        total_shareholder_evoting={this.state.total_shareholder_evoting}
                        total_shareholder_physical={this.state.total_shareholder_physical}
                        online_shareholders_evoting={this.state.online_shareholders_evoting}
                        online_shareholders_physical={this.state.online_shareholders_physical}


                    />
                }

                {this.urlParams.has('projector') ?

                    <div className="col-md-12 main section no-sm-h-padding">
                        <div className="row mb-1 justify-content-md-center justify-content-lg-center">
                            {/*  video & poll-propose-secanded section start  */}
                            <div className={`col-lg-12 col-md-12 col-sm-12 video-blog-long projector-long p-0 pt-2`}>

                                {this.urlParams.get('video') === '1' &&
                                <>
                                {
                                    localStorage.userType === 'Shareholder' || localStorage.userType === 'otherMember'
                                        ?
                                        <BroadCastVideo/>
                                        //<ShareHolderVideo />
                                        :
                                        ''

                                }

                                {localStorage.userType === 'boardMember' && localStorage.conferenceType === '1'
                                ?
                                    <JitsiConference/>
                                    //uncomment this component to test zoom sdk
                                    // <ZoomTest/>
                                :

                                ''
                                }

                                {localStorage.userType === 'boardMember' && localStorage.conferenceType === '0'
                                ?
                                    <ZoomConference/>
                                    //uncomment this component to test zoom sdk
                                    // <ZoomTest/>
                                :

                                ''

                                }

                                </>
                                }



                                {this.urlParams.get('agenda') === '1' &&
                                <>
                                    <AgendaProjection
                                        eventAgenda={this.state.agenda}
                                        onClick={(e) => this.changeLoginStatus(e)}
                                        login={this.state.isLogin}
                                        agendaButtonShow={this.state.agendaBlockShow}
                                        totalShare={this.state.totalShare}
                                        totalShareHolders={this.state.totalShareHolders}
                                        onlineShareHolders={this.state.onlineShareHolders}
                                        online_shares={this.state.online_shares}
                                        changeLocalization = {this.changeLocalization}
                                        totalAttendedShareholdersText={this.state.totalAttendedShareholdersText}
                                        totalSharesText={this.state.totalSharesText}
                                        logoutButton={this.state.logoutButton}

                                        total_shareholder_evoting={this.state.total_shareholder_evoting}
                                        total_shareholder_physical={this.state.total_shareholder_physical}
                                        online_shareholders_evoting={this.state.online_shareholders_evoting}
                                        online_shareholders_physical={this.state.online_shareholders_physical}

                                        isModal={false}
                                        showproposed={this.state.proposedSecondedBlockShow}
                                        agendaFavorButton={this.state.agendaFavorButton}
                                        agendaAgainstButton={this.state.agendaAgainstButton}
                                        subAgendaVoteRequiredText={this.state.subAgendaVoteRequiredText}
                                        subAgendaMaxAllowedVoteText={this.state.subAgendaMaxAllowedVoteText}
                                        subAgendaVoteSubmitButton={this.state.subAgendaVoteSubmitButton}
                                        agendaVoteResultFavor={this.state.agendaVoteResultFavor}
                                        agendaVoteResultAgainst={this.state.agendaVoteResultAgainst}
                                        currentLocalization={this.state.currentLocalization}
                                        agendaVoteHereButton={this.state.agendaVoteHereButton}
                                        agendaVoteCastedButton={this.state.agendaVoteCastedButton}
                                    />
                                </>
                                }


                                {this.urlParams.get('attendanceui') === '1' &&
                                <>
                                    <AttendanceUI
                                        eventAgenda={this.state.agenda}
                                        onClick={(e) => this.changeLoginStatus(e)}
                                        login={this.state.isLogin}
                                        agendaButtonShow={this.state.agendaBlockShow}
                                        totalShare={this.state.totalShare}
                                        totalShareHolders={this.state.totalShareHolders}
                                        onlineShareHolders={this.state.onlineShareHolders}
                                        online_shares={this.state.online_shares}
                                        changeLocalization = {this.changeLocalization}
                                        totalAttendedShareholdersText={this.state.totalAttendedShareholdersText}
                                        totalSharesText={this.state.totalSharesText}
                                        logoutButton={this.state.logoutButton}

                                        total_shareholder_evoting={this.state.total_shareholder_evoting}
                                        total_shareholder_physical={this.state.total_shareholder_physical}
                                        online_shareholders_evoting={this.state.online_shareholders_evoting}
                                        online_shareholders_physical={this.state.online_shareholders_physical}

                                    />
                                </>
                                }


                                {this.urlParams.get('comment') === '1' &&
                                <>
                                    <Comment
                                        eventComment={this.state.comment}
                                        voiceBlockShow={this.state.voiceBlockShow}
                                        commentsHeading={this.state.commentsHeading}
                                        commentsTypePlaceholder={this.state.commentsTypePlaceholder}
                                        commentsSendButton={this.state.commentsSendButton}
                                        commentsBoid={this.state.commentsBoid}
                                        chatED={chatED}
                                    />
                                </>
                                }

                            </div>
                        </div>

                    </div>

                :

                    <div className="col-md-12 main section no-sm-h-padding">
                        <div className="row mb-1">
                            {/*  video & poll-propose-secanded section start  */}
                            <div className={`${this.state.proposedSecondedBlockShow === 1 ? "video-blog" : "video-blog-long"} col-lg-5 col-md-5 col-sm-12 mb-2`}>
                                {/* <!-- video  start   --> */}
                                {/*Video Broadcast Component*/}
                                {
                                    localStorage.userType === 'Shareholder' || localStorage.userType === 'otherMember'
                                        ?
                                        <BroadCastVideo/>
                                        //<ShareHolderVideo />
                                        :
                                        ''

                                }

                                {localStorage.userType === 'boardMember' && localStorage.conferenceType === '1'
                                ?
                                    <JitsiConference/>
                                    //uncomment this component to test zoom sdk
                                    // <ZoomTest/>
                                :

                                ''
                                }

                                {localStorage.userType === 'boardMember' && localStorage.conferenceType === '0'
                                ?
                                    <ZoomConference/>
                                    //uncomment this component to test zoom sdk
                                    // <ZoomTest/>
                                :

                                ''

                                }


                                {/* <!-- video   End   -->     */}
                                {/* <!-- poll-propose-secanded  start  --> */}

                                {
                                    this.state.proposedSecondedBlockShow === 1 &&
                                    this.state.agenda.map((agenda, index) => {
                                        if (agenda.proposed_sh === 1 || agenda.seconded_sh === 1 || agenda.demanded_sh === 1) {
                                            return <ProposedSeconded key={index} psdAgenda={agenda}
                                                agendaProposedButton={this.state.agendaProposedButton}
                                                agendaProposedName={this.state.agendaProposedName}
                                                agendaProposedBoId={this.state.agendaProposedBoId}

                                                agendaSecondedButton={this.state.agendaSecondedButton}
                                                agendaSecondedName={this.state.agendaSecondedName}
                                                agendaSecondedBoId={this.state.agendaSecondedBoId}

                                                agendaDemamdedButton={this.state.agendaDemamdedButton}
                                                agendaDemamdedTotal={this.state.agendaDemamdedTotal}

                                            />
                                        }
                                    })
                                }

                                {/* <!-- poll-propose-secanded  end  --> */}

                                {localStorage.translation === '1' && localStorage.userType === 'boardMember' &&
                                    <iframe
                                    className='translation'
                                    width="100"
                                    height="40"
                                    title="Translation"
                                    src={localStorage.translation_link}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen/>
                                }

                            </div>
                            {/*  video  & poll-propose-secanded section end */}

                            {/* New Wowza Streaming */}

                            {presentationStatus === 1 ?

                                <div className={`${localStorage.userType != 'Shareholder' && localStorage.userType != 'boardMember'?"other-bm-vote-blog col-lg-4 col-md-4 col-sm-12 mb-2 md-no-padding":"col-md-4 col-sm-12 mb-2  md-no-padding" }`}>

                                    {/*  vote agenda start */}
                                    <div className={`${this.state.voteHereBlockShow === 1 && localStorage.userType === "Shareholder" || localStorage.userType === "boardMember" ? "vote-blog" : "vote-blog vote-blog-long"} ${localStorage.bmVote == 0 && localStorage.userType === "boardMember" ? " vote-blog-long" : " x"} ${localStorage.userType === "boardMember" && this.state.voteHereBlockShow === 0 ? " vote-blog-long" : " "}`}>

                                        <div className="presentationStreamBlock">
                                            <PresentationStream/>
                                        </div>

                                    </div>
                                </div>

                            :

                            // <div className={`${localStorage.userType != 'Shareholder' && localStorage.userType != 'boardMember'?"other-bm-vote-blog col-lg-4 col-md-4 col-sm-12 mb-2 md-no-padding":"col-md-4 col-sm-12 mb-2  md-no-padding" }`}>
                            <div className="col-md-4 col-sm-12 mb-2  md-no-padding">

                                {/*  vote agenda start */}
                                {/* <div className={`${this.state.voteHereBlockShow === 1 && localStorage.userType === "Shareholder" || localStorage.userType === "boardMember" ? "vote-blog" : "vote-blog vote-blog-long"} ${localStorage.bmVote == 0 && localStorage.userType === "boardMember" ? " vote-blog-long" : " x"} ${localStorage.userType === "boardMember" && this.state.voteHereBlockShow === 0 ? " vote-blog-long" : " "}`}> */}
                                {/* <div className={`${this.state.voteHereBlockShow === 1  ? "vote-blog" : "vote-blog vote-blog-long"} ${localStorage.bmVote == 0 && localStorage.userType === "boardMember" ? " vote-blog-long" : " x"} ${localStorage.userType === "boardMember" && this.state.voteHereBlockShow === 0 ? " vote-blog-long" : " "}`}> */}
                                <div className={`${localStorage.bmVote == 0 && localStorage.userType === "boardMember" ? " vote-blog-long" : " x"} ${localStorage.userType === "boardMember"? " vote-blog-long" : " "} vote-blog vote-blog-long`}>
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        {
                                            this.state.agendaBlockShow === 1 &&
                                            <li className="nav-item p0">
                                                <a className={`nav-link ${activeTabAgenda ? 'active' : ''}`} id="home-tab"
                                                data-toggle="tab" href="#home"
                                                role="tab" aria-controls="home" aria-selected="true"> {this.state.agendaTabHeading}<span className='agenda-count'>{this.state.total_agenda}</span></a>
                                            </li>
                                        }
                                        {
                                            this.state.sliderBlockShow === 1 &&
                                            <li className="nav-item p0">
                                                <a className={`nav-link ${activeTabSlider ? 'show active' : ''}`}
                                                id="profile-tab" data-toggle="tab" href="#profile"
                                                role="tab" aria-controls="profile" aria-selected="false">{this.state.presentationTabHeading}</a>
                                            </li>
                                        }
                                        {
                                            this.state.attendanceBlockShow === 1 &&
                                            <li className="nav-item p0">
                                            <a className={`nav-link  ${activeTabAttendance ? 'show active' : ''}`}
                                            id="attendance-info-tab" data-toggle="tab" href="#attendance-info"
                                            role="tab" aria-controls="attendance-info" aria-selected="false">Attendance</a>
                                        </li>
                                        }
                                    </ul>
                                    <div ref={this.tabContentRef} className="tab-content" id="myTabContent">
                                        <div className={`tab-pane fade ${activeTabAgenda ? 'show active' : ''}`} id="home"
                                            role="tabpanel"
                                            aria-labelledby="home-tab">

                                            {localStorage.userType === "Shareholder" && localStorage.vote_remaining_feature === '1' &&

                                            <div className='remaining_agenda_container'>
                                                {this.state.remaining_agenda > 0 ?
                                                <div className='remaining_agenda'>
                                                    Votes Remaining: <b>{this.state.remaining_agenda}</b>
                                                </div>
                                                :
                                                <div className='voting_complete'>
                                                    <i className='fa fa-check-circle'></i> Voting Complete
                                                </div>
                                                }
                                            </div>

                                            }

                                            {
                                                this.state.agendaBlockShow === 1
                                                ?
                                                <Agenda
                                                    voteHereBlockShow={this.state.voteHereBlockShow}
                                                    isModal={true} eventAgenda={this.state.agenda}
                                                    showproposed={this.state.proposedSecondedBlockShow}
                                                    agendaFavorButton={this.state.agendaFavorButton}
                                                    agendaAgainstButton={this.state.agendaAgainstButton}
                                                    subAgendaVoteRequiredText={this.state.subAgendaVoteRequiredText}
                                                    subAgendaMaxAllowedVoteText={this.state.subAgendaMaxAllowedVoteText}
                                                    subAgendaVoteSubmitButton={this.state.subAgendaVoteSubmitButton}
                                                    agendaVoteResultFavor={this.state.agendaVoteResultFavor}
                                                    agendaVoteResultAgainst={this.state.agendaVoteResultAgainst}
                                                    currentLocalization={this.state.currentLocalization}
                                                    agendaVoteHereButton={this.state.agendaVoteHereButton}
                                                    agendaVoteCastedButton={this.state.agendaVoteCastedButton}
                                                    vote_given_sub_agenda={this.state.vote_given_sub_agenda}
                                                />

                                                : ''
                                            }
                                        </div>
                                        <div className={`tab-pane fade ${activeTabSlider ? 'show active' : ''}`}id="profile" role="tabpanel"
                                            aria-labelledby="profile-tab">
                                            {
                                                (this.state.shareHolderSlide && this.state.sliderBlockShow === 1)
                                                ?
                                                <Slider slide={this.state.shareHolderSlide}/>
                                                : ''
                                            }
                                        </div>
                                        <div className={`tab-pane fade ${activeTabAttendance ? 'show active' : ''}`} id="attendance-info" role="tabpanel" aria-labelledby="attendance-info-tab">
                                            <table className='text-center table table-borderless mt-2'>
                                                <thead>
                                                    <tr>
                                                        {this.state.total_shareholder === 1 &&
                                                        <td cellspacing='2' style={{width:'50%', background: '#e3dbf2', color: '#000', border: '2px solid #FFF', padding: '15px 10px'}}>
                                                            <h6 >
                                                                Total Shareholder's
                                                            </h6>
                                                            <h5>
                                                                <b>{localStorage.total_shareholders_company}</b>
                                                            </h5>
                                                        </td>
                                                        }
                                                         {this.state.total_shares === 1 &&
                                                        <td cellspacing='2' style={{width:'50%', background: '#d2f8d1', color: '#000', border: '2px solid #FFF', padding: '15px 10px'}}>
                                                            <h6 >
                                                                Total Shares
                                                            </h6>
                                                            <h5>
                                                                <b>{localStorage.total_shares_company}</b>
                                                            </h5>
                                                        </td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                    {this.state.number_of_attendance === 1 &&
                                                        <td style={{width:'50%', background: '#ffd9cc', color: '#000', border: '2px solid #FFF', padding: '15px 10px'}}>
                                                            <h6 >
                                                                Number of Attendance
                                                            </h6>
                                                            <h5>
                                                                <b>{this.state.totalShareHolders}</b>
                                                            </h5>
                                                        </td>
                                                        }
                                                        {this.state.attendance_total_shares === 1 &&
                                                        <td style={{width:'50%', background: '#e0f2fe', color: '#000', border: '2px solid #FFF', padding: '15px 10px'}}>
                                                            <h6 >
                                                                Attendance Total Shares
                                                            </h6>
                                                            <h5>
                                                                <b>{this.state.totalShare}</b>
                                                            </h5>
                                                        </td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                    {this.state.percentage_of_attendees === 1 &&
                                                        <td style={{width:'50%', background: '#b5e51d', color: '#000', border: '2px solid #FFF', padding: '15px 10px'}}>
                                                            <h6 >
                                                                Percentage of Attendees
                                                            </h6>
                                                            <h5>
                                                                <b>{shareholder_percentage}%</b>
                                                            </h5>
                                                        </td>
                                                        }
                                                        {this.state.percentage_of_attendees_shares === 1 &&
                                                        <td style={{width:'50%', background: '#b5e51d', color: '#000', border: '2px solid #FFF', padding: '15px 10px'}}>
                                                            <h6 >
                                                                Percentage of Attendees Shares
                                                            </h6>
                                                            <h5>
                                                                <b>{share_percentage}%</b>
                                                            </h5>
                                                        </td>
                                                        }
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                                {/* vote agenda end  */}


                                {/* <!-- vote  start  --> */}
                                {/* {this.state.voteHereBlockShow === 1 && (localStorage.userType === "Shareholder" || (localStorage.userType === "boardMember" && localStorage.bmVote === "1") || localStorage.userType === "otherMember") ?
                                <AgendaVote
                                    eventAgenda={this.state.agenda}
                                    changeLocalization = {this.changeLocalization}
                                    // agendaVoteModalButton={this.state.agendaVoteModalButton}
                                    agendaVoteModalHeading={this.state.agendaVoteModalHeading}
                                    agendaVoteHereButton={this.state.agendaVoteHereButton}
                                    agendaVoteHereNote={this.state.agendaVoteHereNote}
                                    agendaFavorButton={this.state.agendaFavorButton}
                                    agendaAgainstButton={this.state.agendaAgainstButton}
                                    subAgendaVoteRequiredText={this.state.subAgendaVoteRequiredText}
                                    subAgendaMaxAllowedVoteText={this.state.subAgendaMaxAllowedVoteText}
                                    subAgendaVoteSubmitButton={this.state.subAgendaVoteSubmitButton}
                                    currentLocalization={this.state.currentLocalization}

                                /> : ''
                                } */}
                                {/* <!-- vote  end  --> */}
                            </div>

                            }

                            {/* < !-- vote agenda & record  end--> */}


                            <div className="col-lg-3 col-md-3 col-sm-12 mb-2 p-0">
                                {
                                    this.state.chatBlockShow === 1 && (this.state.comment || !this.state.comment)
                                        ?
                                        <Comment
                                            eventComment={this.state.comment}
                                            voiceBlockShow={this.state.voiceBlockShow}
                                            commentsHeading={this.state.commentsHeading}
                                            commentsTypePlaceholder={this.state.commentsTypePlaceholder}
                                            commentsSendButton={this.state.commentsSendButton}
                                            commentsBoid={this.state.commentsBoid}
                                            chatED={chatED}
                                        />
                                        : ''
                                }

                                {/* <!-- record  button start   --> */}
                                {

                                    this.state.voiceBlockShow === 1 && localStorage.userType === "Shareholder" &&
                                    <Audio
                                        voiceBlockShow={this.state.voiceBlockShow}
                                        broadCastedAudio={this.state.broadCastedAudio}
                                        voiceNote={this.state.voiceNote}
                                        voiceButtonRecord={this.state.voiceButtonRecord}
                                        voiceButtonStop={this.state.voiceButtonStop}
                                    />
                                }
                                {
                                    this.state.voiceBlockShow === 1 && localStorage.userType === "otherMember" &&
                                    <Audio
                                        voiceBlockShow={this.state.voiceBlockShow}
                                        broadCastedAudio={this.state.broadCastedAudio}
                                        voiceNote={this.state.voiceNote}
                                        voiceButtonRecord={this.state.voiceButtonRecord}
                                        voiceButtonStop={this.state.voiceButtonStop}
                                    />

                                }
                                {/* <!-- record  button end   --> */}
                            </div>
                        </div>

                        {
                            (localStorage.userType === "boardMember" && this.state.sliderBlockShow === 1)
                            ?
                            <AllPresentation/>
                            : ''
                        }

                    </div>

                }



                {/*floating chat box for board member*/}
                {
                    localStorage.userType === 'boardMember' && localStorage.BmBoderatorChat === "1" &&
                    <FloatingChatBox
                        eventbMComment={this.state.bmComment}
                        bmModeratorChatHeading={this.state.bmModeratorChatHeading}
                        bmModeratorChatBroadcastButton={this.state.bmModeratorChatBroadcastButton}
                        bmModeratorChatTypePlaceholder={this.state.bmModeratorChatTypePlaceholder}
                    />
                }


                {/*floating chat box for board member Group*/}
                {
                    localStorage.userType === 'boardMember' && localStorage.BmGroupChat === "1" &&
                    <FloatingChatBoxForBMGroup
                        eventbMGroupComment={this.state.bmGroupComment}
                        bmGroupChatHeading={this.state.bmGroupChatHeading}
                        bmModeratorChatTypePlaceholder={this.state.bmModeratorChatTypePlaceholder}
                    />
                }
            </div>


        );
    }
}

export default ShareHolderDashboard;
