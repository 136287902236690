import React, {Component} from 'react';

class Slider extends Component {
    componentDidMount() {

    }

    render() {
        return (
            <div>
                { this.props.slide === ""
                    ?
                    <p className="text-center p-5">No Slider Available</p>
                    :
                    <img src={`data:image/png;base64,${this.props.slide}`} alt="presentation slide" className="w100 mt-2" />
                }
            </div>
        );
    }
}

export default Slider;