import React, {Component} from 'react';
import axios from 'axios';
import Header from './components/Header';
import Footer from './components/Footer';
import Login from './components/Login';
import ShareHolderDashboard from './components/ShareHolderDashboard';
import UpcomingBanner from "./components/UpcomingBanner";
import PageNotFound from "./components/PageNotFound";
import Welcome from "./components/Welcome";
import PhysicalLogin from './components/PhysicalLogin';
import PhysicalAGMDashboard from './components/PhysicalAGMDashboard';
import AttendanceDashboard from './components/AttendanceDashboard';
import UserRegister from './components/login/UserRegister';
import Notiflix from 'notiflix';
import NavBarLoginJs from './components/NavBarLogin';
import {publicIp, publicIpv4, publicIpv6} from 'public-ip';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: "false",
            otpStatus: "false",
            invalidProgram: false,
            programLoad: false,
            demoProgram: true,
        }
        this.programLoaded = false;
        this.programStatus = null;
        this.urlParams = new URLSearchParams(window.location.search);
        this.bannerStatus = 0; //false
        this.isDemo = false;
        this.isPhysical = false;
        this.isAttendance = false;
        this.programCountDownTime = null;
        this.proposedSecondedFeature = 0;
        this.voteHereBlock = 0;
        this.key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj';
        // localStorage.setItem('apiHost', 'http://localhost:8000/api');
        localStorage.setItem('apiHost','https://goconadmin.comjagat.org/api');
        // localStorage.setItem('mainHost', 'http://localhost:8000');
        localStorage.setItem('mainHost', 'https://goconadmin.comjagat.org');
        // localStorage.setItem('moderatorLink', 'http://localhost:8000/moderator/login');
        localStorage.setItem('moderatorLink', 'https://goconadmin.comjagat.org/moderator/login');
        localStorage.programId = this.urlParams.get('program');
    }

    changeLoginStatus = (value) => {
        this.setState({isLogin: value});
    }
    loadLocalization = (programLocalization) => {
        localStorage.userGuide = programLocalization.user_guide
        localStorage.annualReport = programLocalization.annual_report
        localStorage.moderatorLoginHeading = programLocalization.moderator_login_heading
        localStorage.moderatorLoginButton = programLocalization.moderator_login_button
        localStorage.loginAsShareholderHeading = programLocalization.login_as_shareholder_heading
        localStorage.individualTab = programLocalization.individual_tab
        localStorage.companyTab = programLocalization.company_tab
        localStorage.proxyTab = programLocalization.proxy_tab//d
        localStorage.shareholderNameInputLabel = programLocalization.shareholder_name_input_label
        localStorage.boidInputLabel = programLocalization.boid_input_label //sd
        localStorage.totalShareInputLabel = programLocalization.total_share_input_label
        localStorage.mobileInputLabel = programLocalization.mobile_input_label
        localStorage.mobileInputPlaceholder = programLocalization.mobile_input_placeholder//d
        localStorage.dateOfBirthInputLabel = programLocalization.date_of_birth_input_label
        localStorage.nidInputLabel = programLocalization.nid_input_label
        localStorage.emailInputLabel = programLocalization.email_input_label
        localStorage.folioIdInputLabel = programLocalization.folio_id_input_label//d
        localStorage.companyNameInputLabel = programLocalization.company_name_input_label//d
        localStorage.authorizedPersonNameInputLabel = programLocalization.authorized_person_name_input_label//d
        localStorage.proxyNameInputLabel = programLocalization.proxy_name_input_label//d
        localStorage.loginButton = programLocalization.login_button//d
        localStorage.loginAsBmHeading = programLocalization.login_as_bm_heading//d
        localStorage.bmIdInputLabel = programLocalization.bm_id_input_label//d
        localStorage.loginAsOtherBmHeading = programLocalization.login_as_other_bm_heading//d
        localStorage.otherBmIdInputLabel = programLocalization.other_bm_id_input_label//d
        //new
        localStorage.totalAttendedShareholdersText = programLocalization.total_attended_shareholders_text
        localStorage.totalSharesText = programLocalization.total_shares_text
        // localStorage.onlineShareholdersText = programLocalization.online_shareholders_text
        localStorage.logoutButton = programLocalization.logout_button
        localStorage.voiceNote = programLocalization.voice_note
        localStorage.voiceButtonRecord = programLocalization.voice_button_record
        localStorage.voiceButtonStop = programLocalization.voice_button_stop
        // localStorage.voiceConfirmationText = programLocalization.voice_confirmation_text
        // localStorage.footerCopyright = programLocalization.footer_copyright
        // localStorage.footerSlogan = programLocalization.footer_slogan
        localStorage.commentsHeading = programLocalization.comments_heading
        localStorage.commentsTypePlaceholder = programLocalization.comments_type_placeholder
        localStorage.commentsSendButton = programLocalization.comment_send_button
        // localStorage.commentsSendConfirmationSuccess = programLocalization.comments_send_confirmation_success
        // localStorage.commentsSendConfirmationError = programLocalization.comments_send_confirmation_error
        localStorage.commentsBoid = programLocalization.comments_boid
        // localStorage.agendaVoteModalButton = programLocalization.agenda_vote_modal_button
        localStorage.agendaVoteModalHeading = programLocalization.agenda_vote_modal_heading
        localStorage.agendaVoteHereButton = programLocalization.agenda_vote_here_button
        localStorage.agendaVoteHereNote = programLocalization.agenda_vote_here_note
        localStorage.agendaFavorButton = programLocalization.agenda_favor_button
        localStorage.agendaAgainstButton = programLocalization.agenda_against_button
        // localStorage.agendaVoteConfirmationSuccessText = programLocalization.agenda_vote_confirmation_success_text
        // localStorage.agendaVoteConfirmationErrorText = programLocalization.agenda_vote_confirmation_error_text
        // localStorage.agendaVoteSendErrorText = programLocalization.agenda_vote_send_error_text
        localStorage.subAgendaVoteRequiredText = programLocalization.sub_agenda_vote_required_text
        localStorage.subAgendaMaxAllowedVoteText = programLocalization.sub_agenda_max_allowed_vote_text
        // localStorage.subAgendaVoteConfirmationSuccessText = programLocalization.sub_agenda_vote_confirmation_success_text
        // localStorage.subAgendaVoteConfirmationErrorText = programLocalization.sub_agenda_vote_confirmation_error_text
        // localStorage.subAgendaConfirmationTextBeforeSubmit = programLocalization.sub_agenda_confirmation_text_before_submit
        // localStorage.subAgendaVoteSendErrorText = programLocalization.sub_agenda_vote_send_error_text
        localStorage.subAgendaVoteSubmitButton = programLocalization.sub_agenda_vote_submit_button

        localStorage.agendaProposedButton = programLocalization.agenda_proposed_button
        localStorage.agendaProposedName = programLocalization.agenda_proposed_name
        localStorage.agendaProposedBoId = programLocalization.agenda_proposed_boid

        localStorage.agendaSecondedButton = programLocalization.agenda_seconded_button
        localStorage.agendaSecondedName = programLocalization.agenda_seconded_name
        localStorage.agendaSecondedBoId = programLocalization.agenda_seconded_boid

        localStorage.agendaDemamdedButton = programLocalization.agenda_demamded_button
        localStorage.agendaDemamdedTotal = programLocalization.agenda_demamded_total

        // localStorage.proposedConfirmationSuccessText = programLocalization.proposed_confirmation_success_text
        // localStorage.proposedConfirmationErrorText = programLocalization.proposed_confirmation_error_text

        // localStorage.secondedConfirmationSuccessText = programLocalization.seconded_confirmation_success_text
        // localStorage.secondedConfirmationErrorText = programLocalization.seconded_confirmation_error_text

        // localStorage.secondedProposedCanNotSecondedText = programLocalization.seconded_proposed_can_not_seconded_text

        // localStorage.demandedConfirmationSuccessText = programLocalization.demanded_confirmation_success_text
        // localStorage.demandedConfirmationErrorText = programLocalization.demanded_confirmation_error_text

        localStorage.agendaVoteResultFavor = programLocalization.agenda_vote_result_favor
        localStorage.agendaVoteResultAgainst = programLocalization.agenda_vote_result_against

        localStorage.presentationTabHeading = programLocalization.presentation_tab_heading
        localStorage.agendaTabHeading = programLocalization.agenda_tab_heading

        localStorage.bmModeratorChatHeading = programLocalization.bm_moderator_chat_heading
        localStorage.bmModeratorChatBroadcastButton = programLocalization.bm_moderator_chat_broadcast_button
        localStorage.bmModeratorChatTypePlaceholder = programLocalization.bm_moderator_chat_type_placeholder
        // localStorage.bmModeratorChatSendConfirmation = programLocalization.bm_moderator_chat_send_confirmation

        localStorage.bmGroupChatHeading = programLocalization.bm_group_chat_heading

        localStorage.otpSubmitButton = programLocalization.otp_submit_button
        localStorage.resendOtpButton = programLocalization.resend_otp_button
        // localStorage.otpSubmitErrorText = programLocalization.otp_submit_error_text
        localStorage.otpPageTitle = programLocalization.otp_page_title
        localStorage.otpPageSubtitle = programLocalization.otp_page_subtitle
        localStorage.otpCodeInputPlaceholder = programLocalization.otp_code_input_placeholder

        localStorage.logoutConfirmationHeading = programLocalization.logout_confirmation_heading
        localStorage.logoutConfirmationText = programLocalization.logout_confirmation_text
        localStorage.loginAnywayButton = programLocalization.login_anyway_button
        localStorage.goBackButton = programLocalization.go_back_button

        localStorage.agendaVoteCastedButton = programLocalization.agenda_vote_casted_button
        localStorage.only_one_device_login_text = programLocalization.only_one_device_login_text
        localStorage.opt_sent_note = programLocalization.opt_sent_note
    }

    loadData = (program) => {
        localStorage.companyName = program.company_name;
        localStorage.showPSBox = true;
        localStorage.companyNameBn = program.company_name_bn;
        localStorage.programName = program.program_name;
        localStorage.programNameBn = program.program_name_bn;
        localStorage.programDate = program.program_date;
        localStorage.programDateBn = program.program_date_bn;
        localStorage.programLogo = program.program_logo;
        localStorage.program_watermark = program.program_watermark;
        localStorage.programMobileBanner = program.mobile_banner;
        localStorage.streaming_link = program.archive_link;
        localStorage.inqueryNumber = program.inquery_number;
        localStorage.conference_link = program.steaming_link;
        localStorage.presentation_status = program.presentation_status;
        localStorage.live_presentation_link = program.live_presentation_link;
        localStorage.chat_ed = program.chat_ed;
        localStorage.BmBoderatorChat = program.bm_moderator_chat;
        localStorage.proposedSecondedFeature = program.proposed_seconded_feature;
        localStorage.voteHereBlock = program.vote_here_block;
        localStorage.againstVoteReason = program.against_vote_reason;
        localStorage.bmVote = program.bm_vote;
        localStorage.BmGroupChat = program.bm_group_chat;
        // console.log(localStorage.BmGroupChat);
        localStorage.programIcon = program.program_icon;
        localStorage.helpline_icon = program.helpline_icon;
        localStorage.userguide_logo = program.userguide_logo;
        localStorage.annualreport_logo = program.annualreport_logo;

        localStorage.enableEmail = program.enable_email;
        localStorage.enableNid = program.enable_nid;
        localStorage.enableDob = program.enable_dob;

        localStorage.upcoming_program_status = program.upcoming_program_status;
        localStorage.upcoming_program_1 = program.upcoming_program_1;
        localStorage.upcoming_program_2 = program.upcoming_program_2;
        localStorage.upcoming_program_3 = program.upcoming_program_3;
        localStorage.upcoming_program_1_name = program.upcoming_program_1_name;
        localStorage.upcoming_program_2_name = program.upcoming_program_2_name;
        localStorage.upcoming_program_3_name = program.upcoming_program_3_name;

        localStorage.physical_voting = program.physical_voting;

        localStorage.show_agenda_result_physical_portal = program.show_agenda_result_physical_portal;
        localStorage.auto_login_save_attendance = program.auto_login_save_attendance;
        localStorage.show_online_physical_attendance_navbar = program.show_online_physical_attendance_navbar;
        localStorage.physical_otp_login = program.physical_otp_login;
        localStorage.show_total_voters_count = program.show_total_voters_count;
        localStorage.enable_both_online_physical_login = program.enable_both_online_physical_login;
        localStorage.show_image = program.show_image;
        localStorage.show_signature = program.show_signature;

        localStorage.login_individual_boid = program.login_individual_boid;
        localStorage.login_individual_name = program.login_individual_name;
        localStorage.login_individual_share = program.login_individual_share;

        localStorage.login_company_boid = program.login_company_boid;
        localStorage.login_company_share = program.login_company_share;
        localStorage.login_company_name = program.login_company_name;
        localStorage.login_company_authorized_name = program.login_company_authorized_name;

        localStorage.login_proxy_boid = program.login_proxy_boid;
        localStorage.login_proxy_sh_name = program.login_proxy_sh_name;
        localStorage.login_proxy_share = program.login_proxy_share;
        localStorage.login_proxy_name = program.login_proxy_name;

        localStorage.conferenceType = program.conference_type;
        localStorage.jitsiLink = program.jitsi_link;
        localStorage.jitsi_domain = program.jitsi_domain;

        localStorage.registration_module = program.registration_module;
        localStorage.vote_percentage_module = program.vote_percentage_module;
        localStorage.total_share = program.total_share;
        localStorage.localizationStatus = program.localization_status;
        localStorage.loggedInUsernameStatus = program.logged_in_username_status;
        localStorage.votingPercentageLogic = program.voting_percentage_logic;

        localStorage.total_attendance_status = program.total_attendance_status;
        localStorage.total_share_status = program.total_share_status;
        localStorage.online_shareholder_attendance_status = program.online_shareholder_attendance_status;
        localStorage.chief_moderation = program.chief_moderation;

        localStorage.bm_bm_private_chat = program.bm_bm_private_chat;
        localStorage.bm_moderator_private_chat = program.bm_moderator_private_chat;


        this.bannerStatus = program.banner_status;
        this.programCountDownTime = program.program_countdown_endtime;
        this.programStatus = program.status;


        //*********** UI Color ***********//
        // localStorage.logoWidth = program.logo_width;
        // localStorage.logoHeight = program.logo_height;

        localStorage.agendaHeaderBgColor = program.agenda_header_bg_color;
        localStorage.agendaHeaderTextColor = program.agenda_header_text_color;

        localStorage.commentBoIdBgColor = program.comment_bo_id_bg_color;
        localStorage.commentBoIdTextColor = program.comment_bo_id_text_color;
        localStorage.commentHeaderBgColor = program.comment_header_bg_color;
        localStorage.commentHeaderTextColor = program.comment_header_text_color;
        localStorage.commentTextBgColor = program.comment_text_bg_color;
        localStorage.commentTextColor = program.comment_text_color;
        localStorage.commentSendBtnBgColor = program.comment_send_btn_bg_color;
        localStorage.commentSendBtnColor = program.comment_send_btn_color;

        localStorage.programNameTextColor = program.program_name_text_color;
        localStorage.companyNameTextColor = program.company_name_text_color;
        localStorage.dateTextColor = program.date_text_color;
        localStorage.footerBgColor = program.footer_bg_color;
        localStorage.footerTextColor = program.footer_text_color;

        localStorage.localizationTextColor = program.localization_text_color;
        localStorage.localizationBGColor = program.localization_bg_color;

        localStorage.loginActiveTabColor = program.login_active_tab_color;
        localStorage.loginActiveTabTextColor = program.login_active_tab_text_color;
        localStorage.loginButtonTabColor = program.login_button_tab_color;
        localStorage.loginButtonTabTextColor = program.login_button_tab_text_color;
        localStorage.loginTabHeaderBgColor = program.login_tab_header_bg_color;
        localStorage.loginTabHeaderTextColor = program.login_tab_header_text_color;
        localStorage.loginButtonBgColor = program.login_button_bg_color;
        localStorage.loginButtonTextColor = program.login_button_text_color;

        localStorage.navigationBarBgColor = program.navigation_bar_bg_color;
        localStorage.navigationBarTextColor = program.navigation_bar_text_color;

        localStorage.proposedButtonColor = program.proposed_button_color;
        localStorage.proposedButtonTextColor = program.proposed_button_text_color;
        localStorage.secondedButtonColor = program.seconded_button_color;
        localStorage.secondedButtonTextColor = program.seconded_button_text_color;
        localStorage.demandButtonColor = program.demand_button_color;
        localStorage.demandButtonTextColor = program.demand_button_text_color;

        localStorage.recordButtonColor = program.record_button_color;
        localStorage.recordButtonTextColor = program.record_button_text_color;

        localStorage.voteHereButtonColor = program.vote_here_button_bg_color;
        localStorage.voteHereButtonTextColor = program.vote_here_button_text_color;

        localStorage.SubAgendaSubmitButtonColor = program.submit_button_color;
        localStorage.SubAgendaSubmitButtonTextColor = program.submit_button_text_color;
        localStorage.topHeaderBgColor = program.top_header_bg_color;

        localStorage.voteButtonAgainestColor = program.vote_button_againest_color;
        localStorage.voteButtonAgainestTextColor = program.vote_button_againest_text_color;
        localStorage.voteButtonFavourColor = program.vote_button_favour_color;
        localStorage.voteButtonFavourTextColor = program.vote_button_favour_text_color;
        localStorage.voteButtonTextColor = program.vote_button_text_color;

        localStorage.voteResultAgainstHeaderBgColor = program.vote_result_against_header_bg_color;
        localStorage.voteResultAgainstHeaderTextColor = program.vote_result_against_header_text_color;
        localStorage.voteResultAgainstNumberBgColor = program.vote_result_against_number_bg_color;
        localStorage.voteResultAgainstNumberTextColor = program.vote_result_against_number_text_color;
        localStorage.voteResultFavourHeaderBgColor = program.vote_result_favour_header_bg_color;
        localStorage.voteResultFavourHeaderTextColor = program.vote_result_favour_header_text_color;
        localStorage.voteResultFavourNumberBgColor = program.vote_result_favour_number_bg_color;
        localStorage.voteResultFavourNumberTextColor = program.vote_result_favour_number_text_color;

        localStorage.agendaDescriptionFontSizeBn = program.agenda_description_font_size_bn;//d
        localStorage.agendaDescriptionFontSizeEn = program.agenda_description_font_size_en;//d
        localStorage.agendaPresentationTabActiveBackgroudColor = program.agenda_presentation_tab_active_backgroud_color;
        localStorage.agendaPresentationTabActiveFontColor = program.agenda_presentation_tab_active_font_color;
        localStorage.agendaPresentationTabBackgroudColor = program.agenda_presentation_tab_backgroud_color;//d
        localStorage.agendaPresentationTabFontColor = program.agenda_presentation_tab_font_color;//d
        localStorage.banglaHeaderAlignment = program.bangla_header_alignment;//d
        localStorage.englishHeaderAlignment = program.english_header_alignment;//d

        localStorage.loginAsHeadingFontSize = program.login_as_heading_font_size;//d
        localStorage.navbarFontSize = program.navbar_font_size;//d

        // localStorage.programFontBn = program.program_font_bn;
        // localStorage.programFontEn = program.program_font_en

        // localStorage.headerCompanyNameFontSizeBn = program.header_company_name_font_size_bn;//d
        // localStorage.headerCompanyNameFontSizeEn = program.header_company_name_font_size_en;//d
        // localStorage.headerDateFontSizeBn = program.header_date_font_size_bn;//d
        // localStorage.headerDateFontSizeEn = program.header_date_font_size_en;//d
        // localStorage.headerProgramNameFontSizeBn = program.header_program_name_font_size_bn;//d
        // localStorage.headerProgramNameFontSizeEn = program.header_program_name_font_size_en;//d

        localStorage.voteBlockTextFontSize = program.vote_block_text_font_size;//d
        localStorage.voteButtonFontSize = program.vote_button_font_size;//d

        localStorage.agendaNameFontSize = program.agenda_name_font_size;//d
        localStorage.commentDateFontSize = program.comment_date_font_size;//d
        localStorage.commentBoidFontSize = program.comment_boid_font_size;//d
        localStorage.commentTextFontSize = program.comment_text_font_size;//d
        localStorage.loginLabelFontSize = program.login_label_font_size;//d
        localStorage.loginLabelColor = program.login_label_color;//d

        localStorage.commentLabelFontSize = program.comment_label_font_size;
        localStorage.recordButtonFontSize = program.record_button_font_size;
        localStorage.recordBlockTextFontSize = program.record_block_text_font_size;

        localStorage.zoomMeetingId = program.zoom_meeting_id;
        localStorage.zoomPassword = program.zoom_password;

        localStorage.logoutBtnAlignment = program.logout_btn_alignment;
        localStorage.voteHereBtnAlignment = program.vote_here_btn_alignment;
        localStorage.showModeratorLinkInLogin = program.show_moderator_link_in_login;
        localStorage.showAnnualReportOption = program.show_annual_report_option;

        localStorage.showUserGuideOption = program.show_user_guide_option;
        localStorage.showNavbarInLogin = program.show_navbar_in_login;

        localStorage.annual_report_url = program.annual_report_url;
        localStorage.user_guide_url = program.user_guide_url;

        localStorage.voiceBlockShow = program.voice_status;

        localStorage.voteCastedButtonColor = program.vote_casted_button_bg_color;
        localStorage.voteCastedButtonTextColor = program.vote_casted_button_text_color;
        localStorage.voteCastedButtonFontSize = program.vote_casted_button_font_size;
        localStorage.only_one_device_login = program.only_one_device_login;
        localStorage.translation_link = program.translation_link;
        localStorage.multiple_proxy = program.multiple_proxy;
        localStorage.gp_multiple_company = program.gp_multiple_company;
        localStorage.vote_remaining_feature = program.vote_remaining_feature;
        localStorage.bank_asia = program.bank_asia;
        localStorage.proxy_boid_login = program.proxy_boid_login;
        localStorage.fixed_voting_bar = program.fixed_voting_bar;

        localStorage.total_shareholders_company = program.total_shareholders_company;
        localStorage.total_shares_company = program.total_shares_company;
        localStorage.printer_setting = program.printer_setting;

        //*********** End UI Color ***********//
        this.programLoaded = true;
        // this.updateFont();
    }

    componentDidMount = async () => {

        // document.getElementById('zmmtg-root').remove();

        Notiflix.Confirm.init({ className:'notiflix-confirm', messageMaxLength:200})

        // const publicIp = require('public-ip');

		try{

			localStorage.ip = await publicIpv4();

		}catch (error) {

			try{

				localStorage.ip = await publicIpv6();

			}catch (error) {

				localStorage.ip = '127.0.0.1';
			}
		}

        let isLogin = false;
        let url = "";
        if (localStorage.getItem('userType') != null) {
            if (localStorage.userType === "Shareholder") {
                url = `${localStorage.apiHost}/check-shareholder`;
            } else {
                url = `${localStorage.apiHost}/check-board-member`;
            }
        }
        //user logged in previously and reload or re open browser
        if (localStorage.getItem('token') != null && localStorage.isLogin === "true") {
            if (this.urlParams.has('program') && this.urlParams.get('program') === localStorage.programId) {
                await axios({
                    method: 'post',
                    url: url,
                    headers: {
                        'key': this.key,
                        'access-token': localStorage.token
                    },
                    data: {
                        'program-id': localStorage.programId,
                        'login': 'login'
                    }
                }).then(response => {
                    if (response.data.login === "login") {
                        axios({
                            method: 'post',
                            url: `${localStorage.apiHost}/program-details`,
                            headers: {
                                'key': this.key
                            },
                            data: {
                                'program-id': localStorage.programId
                            }
                        }).then(response => {
                            if (response.data.status === 200) {
                                let program = response.data[0];
                                let programLocalization = response.data['localization'];
                                this.loadLocalization(programLocalization);
                                let programLocalizationBN = response.data['localization_bn'];
                                localStorage.agendaFavorButtonBN = programLocalizationBN.agenda_favor_button;
                                localStorage.agendaAgainstButtonBN = programLocalizationBN.agenda_against_button;
                                // console.log("program data", program);
                                this.loadData(program);
                                this.checkDemo(program.demo_login_pass);
                                this.checkPhysical(program.physical_password);
                                this.checkPhysicalAttendance(program.attendance_password);
                                this.setState({otpStatus: program.otp_status == "1" ? "true" : "false"});
                            }
                        }).catch((error) => {
                            if(error.response){
                                console.log(error.response);
                            }
                        });
                        isLogin = true;
                    }
                }).catch((error) => {
                    if(error.response){
                        console.log(error.response);
                    }
                });
            } else {
                this.setState({invalidProgram: true});
            }
        } else {
            isLogin = false
        }

        if (isLogin) {
            this.setState({isLogin: "true"});
        } else {
            //get program details when user not login
            if (this.urlParams.has('program')) {
                axios({
                    method: 'post',
                    url: `${localStorage.apiHost}/program-details`,
                    headers: {
                        'key': this.key
                    },
                    data: {
                        'program-id': this.urlParams.get('program')
                    }
                }).then(response => {
                    if (response.data.status === 200) {
                        this.programDetails = response.data[0];
                        let program = response.data[0];
                        // console.log("program data reload", program);

                        let programLocalization = response.data['localization'];
                        this.loadLocalization(programLocalization);
                        let programLocalizationBN = response.data['localization_bn'];
                        localStorage.agendaFavorButtonBN = programLocalizationBN.agenda_favor_button;
                        localStorage.agendaAgainstButtonBN = programLocalizationBN.agenda_against_button;
                        this.loadData(program);
                        this.checkDemo(program.demo_login_pass);
                        this.checkPhysical(program.physical_password);
                        this.checkPhysicalAttendance(program.attendance_password);
                        this.setState({otpStatus: program.otp_status == "1" ? "true" : "false"});

                    } else {
                        this.setState({invalidProgram: true});
                        // console.log("invalid program url");
                    }
                }).catch((error) => {
                    if(error.response){
                        console.log(error.response);
                    }
                });
            } else {
                this.setState({invalidProgram: true});
                // console.log("Invalid Program Url");
            }
        }


    }

    checkDemo = (demoPass) => {

        if (this.urlParams.has('demo')) {

            if (this.urlParams.get('demo') === demoPass) {

                this.isDemo = true;

            } else {

                this.setState({invalidProgram: true});
                window.location.href = this.removeParam('demo', window.location.href);

                // console.log(window.location.href);

            }

        }

    }

    removeParam = (key, sourceURL) => {
        var rtn = sourceURL.split("?")[0],
            param,
            params_arr = [],
            queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
        if (queryString !== "") {
            params_arr = queryString.split("&");
            for (var i = params_arr.length - 1; i >= 0; i -= 1) {
                param = params_arr[i].split("=")[0];
                if (param === key) {
                    params_arr.splice(i, 1);
                }
            }
            if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
        }
        return rtn;
    }

    checkPhysical = (physicalPass) => {

        if (this.urlParams.has('physical')) {

            if (this.urlParams.get('physical') === physicalPass) {

                this.isPhysical = true;

            } else {

                this.setState({invalidProgram: true});

            }

        }

    }

    checkPhysicalAttendance = (attendancePass) => {

        if (this.urlParams.has('attendance')) {

            if (this.urlParams.get('attendance') === attendancePass) {

                this.isAttendance = true;

            } else {

                this.setState({invalidProgram: true});

            }

        }

    }

    // updateFont = () => {
    //     if(localStorage.programFontEn !== "null"){
    //         let style = document.querySelector('style');
    //         style.innerHTML += `
    //             @font-face{
    //                 font-family: 'custom-font-en';
    //                 src: url(data:application/x-font-tff;charset=utf-8;base64,${localStorage.programFontEn});
    //             }
    //             @font-face{
    //                 font-family: 'custom-font-bn';
    //                 src: url(data:application/x-font-tff;charset=utf-8;base64,${localStorage.programFontBn});
    //             }`
    //     }
    // }

    stripHtml = (html) => {
        // Create a new div element
        var temporalDivElement = document.createElement("div");
        // Set the HTML content with the providen
        temporalDivElement.innerHTML = html;
        // Retrieve the text property of the element (cross-browser support)
        return temporalDivElement.textContent || temporalDivElement.innerText || "";
    }

    render() {

        if(localStorage.programIcon !== "null"){

            let iconEl = document.getElementById('icon');
            iconEl.href = `data:image/jpeg;base64,${localStorage.programIcon}`;

        }

        if(localStorage.companyName !== "null"){

            let titleEl = document.getElementById('site-title');

            let titleText = this.isPhysical ? "Physical Voting Panel - " : (this.isAttendance ? "Shareholder Verification - " : "");

            titleEl.innerHTML = titleText+this.stripHtml(localStorage.companyName);

        }

        let element;

        if (this.state.invalidProgram) {

            element = <PageNotFound/>

        } else {

            if (this.state.isLogin === "true" && this.programLoaded === true) {

                if (this.isPhysical) {

                    element = <><Header onClick={this.changeLoginStatus} /><PhysicalAGMDashboard programLoad={this.programLoaded} onClick={this.changeLoginStatus} /><Footer/></>

                }else if (this.isAttendance) {

                    element = <><Header onClick={this.changeLoginStatus} /><AttendanceDashboard onClick={this.changeLoginStatus} programLoad={this.programLoaded} /><Footer/></>

                }else{

                    if(this.urlParams.has('projector')){

                        element = <><ShareHolderDashboard programLoad={this.programLoaded} onClick={this.changeLoginStatus} /></>

                    }else{

                        element = <><Header onClick={this.changeLoginStatus} /><ShareHolderDashboard programLoad={this.programLoaded} onClick={this.changeLoginStatus} /><Footer/></>

                    }


                }


            }

            if(this.programLoaded && this.state.isLogin === "false"){

                if (this.isPhysical) {

                    element = <><Header onClick={this.changeLoginStatus} /><PhysicalLogin onClick={this.changeLoginStatus} otp={this.state.otpStatus} agmType={2} /> <Footer/></>

                }else if (this.isAttendance) {

                    element = <><Header onClick={this.changeLoginStatus} /><AttendanceDashboard onClick={this.changeLoginStatus} programLoad={this.programLoaded} /><Footer/></>

                }else{

                    if(localStorage.showNavbarInLogin === '1'){

                        element = <><Header onClick={this.changeLoginStatus} /><NavBarLoginJs/><Login onClick={this.changeLoginStatus} otp={this.state.otpStatus} agmType={1} /> <Footer/></>

                    }else{

                        element = <><Header onClick={this.changeLoginStatus} /><Login onClick={this.changeLoginStatus} otp={this.state.otpStatus} agmType={1} /> <Footer/></>

                    }

                }

            }

            if ((!this.isDemo && this.bannerStatus === 1) || this.programStatus === 3) {

                element = <><UpcomingBanner programStatus={this.programStatus} countDownTime={this.programCountDownTime}/><Footer/></>
            }


        }

        const reload = () => window.location.reload();

        return (
            <div>
                {
                    window.location.pathname.length > 1
                        ?
                        <div>
                            {element}
                        </div>
                        :
                        <Welcome/>
                }
                <div className="modal fade" id="register">
                    <div className="modal-dialog">
                        <div className="modal-content">

                            {/* <!-- Modal Header --> */}
                            <div className="modal-header">
                                <h5>Registration</h5>
                                <button type="button" id="registerClose" className="close" data-dismiss="modal">&times;</button>
                            </div>

                            {/* <!-- Modal body --> */}
                            <div className="modal-body">
                                <UserRegister/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default App;
