import React from 'react';
import axios from 'axios';
import Echo from 'laravel-echo';
// import BroadCastVideo from './shareholder/BroadCastVideo';
// import Agenda from './shareholder/Agenda';
// import Comment from './shareholder/Comment';
// import ViewerInfo from './shareholder/ViewerInfo';
// import Audio from './shareholder/Audio';
// import PresentationStream from './shareholder/PresentationStream';
// import AgendaVote from "./shareholder/AgendaVote";
// import FloatingChatBox from './shareholder/FloatingChatBox';
// import JitsiConference from './shareholder/JitsiConference';
import NavBarJs from "./NavBar";
// import ShareHolderVideo from "./shareholder/ShareHolderVideo";
// import Slider from "./shareholder/Slider";
// import ZoomConference from "./shareholder/ZoomConference";
// import ProposedSeconded from "./shareholder/ProposedSeconded";
// import AllPresentation from "./shareholder/AllPresentation";
// import ZoomTest from "./shareholder/ZoomTest";
// import FloatingChatBoxForBMGroup from './shareholder/FloatingChatBoxForBMGroup';
import PhysicalAgendaVote from './shareholder/PhysicalAgendaVote';

window.io = require('socket.io-client');

class PhysicalAGMDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: [],
            bmComment: [],
            agenda: [],
            totalShare: 0,
            totalShareHolders: 0,
            broadCastedAudio: "",
            shareHolderSlide: null,
            voiceBlockShow: 1,
            sliderBlockShow: 0,
            agendaBlockShow: 0,
            chatBlockShow: 1,
            voteHereBlockShow: 0,
            proposedSecondedBlockShow: 0,
            presentationStream: localStorage.presentation_status,
            chat_ed: localStorage.chat_ed,
            bmGroupComment: [],
            remaining_agenda: 0,

            total_shareholder_evoting: 0,
            total_shareholder_physical: 0,

            online_shareholders_evoting: 0,
            online_shareholders_physical: 0,

            //localization

            currentLocalization: '1',

            totalAttendedShareholdersText: localStorage.totalAttendedShareholdersText,
            totalSharesText: localStorage.totalSharesText,

            logoutButton: localStorage.logoutButton,
            voiceNote: localStorage.voiceNote,
            voiceButtonRecord: localStorage.voiceButtonRecord,
            voiceButtonStop: localStorage.voiceButtonStop,
            commentsHeading: localStorage.commentsHeading,
            commentsTypePlaceholder: localStorage.commentsTypePlaceholder,
            commentsBoid: localStorage.commentsBoid,

            // agendaVoteModalButton: localStorage.agendaVoteModalButton,
            agendaVoteModalHeading: localStorage.agendaVoteModalHeading,
            agendaVoteHereButton: localStorage.agendaVoteHereButton,
            agendaVoteHereNote: localStorage.agendaVoteHereNote,
            agendaFavorButton: localStorage.agendaFavorButton,
            agendaAgainstButton: localStorage.agendaAgainstButton,
            agendaVoteCastedButton: localStorage.agendaVoteCastedButton,

            subAgendaVoteRequiredText: localStorage.subAgendaVoteRequiredText,
            subAgendaMaxAllowedVoteText: localStorage.subAgendaMaxAllowedVoteText,
            subAgendaVoteSubmitButton: localStorage.subAgendaVoteSubmitButton,

            agendaProposedButton: localStorage.agendaProposedButton,
            agendaProposedName: localStorage.agendaProposedName,
            agendaProposedBoId: localStorage.agendaProposedBoId,

            agendaSecondedButton: localStorage.agendaSecondedButton,
            agendaSecondedName: localStorage.agendaSecondedName,
            agendaSecondedBoId: localStorage.agendaSecondedBoId,

            agendaDemamdedButton: localStorage.agendaDemamdedButton,
            agendaDemamdedTotal: localStorage.agendaDemamdedTotal,

            agendaVoteResultFavor: localStorage.agendaVoteResultFavor,
            agendaVoteResultAgainst: localStorage.agendaVoteResultAgainst,

            presentationTabHeading: localStorage.presentationTabHeading,
            agendaTabHeading: localStorage.agendaTabHeading,

            bmModeratorChatHeading: localStorage.bmModeratorChatHeading,
            bmModeratorChatBroadcastButton: localStorage.bmModeratorChatBroadcastButton,
            bmModeratorChatTypePlaceholder: localStorage.bmModeratorChatTypePlaceholder,

            bmGroupChatHeading: localStorage.bmGroupChatHeading,

        }
        this.key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj';
        localStorage.bmChatNotification = false;
        localStorage.bmGroupChatNotification = false;
    }

    componentDidMount() {

        if (Number(localStorage.voteHereBlock) !== this.state.voteHereBlockShow) {
            this.setState({voteHereBlockShow: Number(localStorage.voteHereBlock)});
        }
        if (Number(localStorage.proposedSecondedFeature) !== this.state.proposedSecondedBlockShow) {
            this.setState({proposedSecondedBlockShow: Number(localStorage.proposedSecondedFeature)});
        }

        this.echo = new Echo({
            broadcaster: 'socket.io',
            host: 'https://echoserveraws.comjagat.org',
            // host: 'http://localhost:6001',
            auth: {
                headers: {
                    'key': this.key,
                    'access-token': localStorage.token
                }
            }
        });
        const echo = this.echo;


        // subscirbe
        echo.private(`reload-program-${localStorage.programId}`)
        .listen('.reload-program-event', (program_id) => {
            window.location.reload();
        });


        //get all active agenda list when application load
        axios({
            method: 'post',
            url: `${localStorage.apiHost}/agenda`,
            headers: {
                'key': this.key,
                'access-token': localStorage.token
            },
            data: {
                'program-id': localStorage.programId
            }
        }).then(response => {
            if (response.data.status === 200) {
                response.data[0].forEach((agenda, index) => {
                    if (localStorage.showPSBox === "true" && agenda.proposed_sh) {
                        this.proposedSecondedAgenda = agenda;
                        this.agdIndex = index;
                    }
                    // this.state.agenda.push(agenda);
                })
                this.setState({
                    agenda: response.data[0],
                    remaining_agenda: response.data.remaining_agenda,
                });
            }
        }).catch((error) => {
            if(error.response){
                console.log(error.response);
            }
        });

        //subscribe to Agenda channel so that shareholder can see agenda list which are open
        echo.private(`active-agenda-${localStorage.programId}`)
            .listen('.active-agenda-event', (agendaData) => {
                this.state.agenda.splice(0); //reset array

                agendaData[0].forEach((agenda, index) => {
                    this.state.agenda.push(agenda);
                })

                this.setState({agenda: this.state.agenda});

                axios({
                    method: 'post',
                    url: `${localStorage.apiHost}/agenda`,
                    headers: {
                        'key': this.key,
                        'access-token': localStorage.token
                    },
                    data: {
                        'program-id': localStorage.programId
                    }
                }).then(response => {
                    if (response.data.status === 200) {
                        this.setState({
                            remaining_agenda: response.data.remaining_agenda,
                        });
                    }
                }).catch((error) => {
                    if(error.response){
                        console.log(error.response);
                    }
                });
            });

        //subscribe to shareholder-information channel
        //to get total logged in shareholders and total number of share
        echo.private(`shareholder-information-${localStorage.programId}`)
            .listen('.shareholder-information-event', (shareholders) => {
                this.setState({
                    totalShareHolders: shareholders.total_shareholder,
                    totalShare: shareholders.total_share,

                    total_shareholder_evoting: shareholders.total_shareholder_evoting,
                    total_shareholder_physical: shareholders.total_shareholder_physical,

                    online_shareholders_evoting: shareholders.online_shareholders_evoting,
                    online_shareholders_physical: shareholders.online_shareholders_physical,
                });
            });

        //get shareholder attendance information from api
        axios({
            method: 'post',
            url: `${localStorage.apiHost}/shareholder-information`,
            headers: {
                'key': this.key,
                'access-token': localStorage.token
            },
            data: {
                'program-id': localStorage.programId
            }
        }).then(response => {
            if (response.data.status === 200) {
                this.setState({
                    totalShareHolders: response.data.total_shareholder,
                    totalShare: response.data.total_share,

                    total_shareholder_evoting: response.data.total_shareholder_evoting,
                    total_shareholder_physical: response.data.total_shareholder_physical,

                    online_shareholders_evoting: response.data.online_shareholders_evoting,
                    online_shareholders_physical: response.data.online_shareholders_physical,
                });
            }
        }).catch((error) => {
            if(error.response){
                console.log(error.response);
            }
        });

        //get all component block status from api
        axios({
            method: 'post',
            url: `${localStorage.apiHost}/program-details`,
            headers: {
                'key': this.key,
                'access-token': localStorage.token
            },
            data: {
                'program-id': localStorage.programId
            }
        }).then(response => {
            if (response.data.status === 200) {
                this.setState({
                    chatBlockShow: response.data[0].chat_status,
                    agendaBlockShow: response.data[0].agenda_status,
                    sliderBlockShow: response.data[0].slider_status,
                    voiceBlockShow: response.data[0].voice_status
                });
            }
        }).catch((error) => {
            if(error.response){
                console.log(error.response);
            }
        });

        echo.private(`agenda-block-${localStorage.programId}`)
        .listen('.agenda-block-event', (data) => {
            this.setState({
                agendaBlockShow: data.agenda_status,
            });
        });


    }
    componentWillUnmount = () => {
        this.echo.disconnect();
    }

    changeLoginStatus = (value) => {
        this.props.onClick(value);
    }

    //localization chagne

    changeLocalization = (value) => {
        // console.log('Language: '+value);

        axios({
            method: 'post',
            url: `${localStorage.apiHost}/change-localization`,
            headers: {
                'key': this.key,
                'access-token': localStorage.token
            },
            data: {
                'program-id': localStorage.programId,
                'localization-id': value,
            }
        }).then(response => {
            if (response.data.status === 200) {
                this.setState({

                    currentLocalization: value,

                    totalAttendedShareholdersText: response.data[0].total_attended_shareholders_text,
                    totalSharesText: response.data[0].total_shares_text,

                    logoutButton: response.data[0].logout_button,
                    voiceNote: response.data[0].voice_note,
                    voiceButtonRecord: response.data[0].voice_button_record,
                    voiceButtonStop: response.data[0].voice_button_stop,
                    commentsHeading: response.data[0].comments_heading,
                    commentsTypePlaceholder: response.data[0].comments_type_placeholder,
                    commentsBoid: response.data[0].comments_boid,

                    // agendaVoteModalButton: response.data[0].agenda_vote_modal_button,
                    agendaVoteModalHeading: response.data[0].agenda_vote_modal_heading,
                    agendaVoteHereButton: response.data[0].agenda_vote_here_button,
                    agendaVoteHereNote: response.data[0].agenda_vote_here_note,
                    agendaFavorButton: response.data[0].agenda_favor_button,
                    agendaAgainstButton: response.data[0].agenda_against_button,

                    agendaVoteCastedButton: response.data[0].agenda_vote_casted_button,


                    subAgendaVoteRequiredText: response.data[0].sub_agenda_vote_required_text,
                    subAgendaMaxAllowedVoteText: response.data[0].sub_agenda_max_allowed_vote_text,
                    subAgendaVoteSubmitButton: response.data[0].sub_agenda_vote_submit_button,

                    agendaProposedButton: response.data[0].agenda_proposed_button,
                    agendaProposedName: response.data[0].agenda_proposed_name,
                    agendaProposedBoId: response.data[0].agenda_proposed_boid,

                    agendaSecondedButton: response.data[0].agenda_seconded_button,
                    agendaSecondedName: response.data[0].agenda_seconded_name,
                    agendaSecondedBoId: response.data[0].agenda_seconded_boid,

                    agendaDemamdedButton: response.data[0].agenda_demamded_button,
                    agendaDemamdedTotal: response.data[0].agenda_demamded_total,

                    agendaVoteResultFavor: response.data[0].agenda_vote_result_favor,
                    agendaVoteResultAgainst: response.data[0].agenda_vote_result_against,

                    presentationTabHeading: response.data[0].presentation_tab_heading,
                    agendaTabHeading: response.data[0].agenda_tab_heading,

                    bmModeratorChatHeading: response.data[0].bm_moderator_chat_heading,
                    bmModeratorChatBroadcastButton: response.data[0].bm_moderator_chat_broadcast_button,
                    bmModeratorChatTypePlaceholder: response.data[0].bm_moderator_chat_type_placeholder,

                    bmGroupChatHeading: response.data[0].bm_group_chat_heading,
                })
            }
        }).catch((error) => {
            if(error.response){
                console.log(error.response);
            }
        });
    }

    closeConfirm = (e) => {
        document.getElementById('NotiflixConfirmWrap').remove();
        document.getElementById('closeConfirm').style.display = "none";
    }

    render() {
        // console.log(this.state.agendaFavorButton);
        // let activeTabAgenda = true;
        // let activeTabSlider = false;

        // if (this.state.agendaBlockShow === 0 && this.state.sliderBlockShow === 1) {
        //     activeTabAgenda = false;
        //     activeTabSlider = true;

        // }
        let style = document.querySelector('style');
        style.innerHTML += `
        .vote-blog .nav-tabs .nav-link{
            background-color:${localStorage.agendaPresentationTabBackgroudColor};
            color:${localStorage.agendaPresentationTabFontColor}
        }
        .vote-blog .nav-tabs .nav-link.active{
            background-color:${localStorage.agendaPresentationTabActiveBackgroudColor};
            color:${localStorage.agendaPresentationTabActiveFontColor}
        }`;

        return (
            <div>
                <div id='closeConfirm' onClick={(e) => this.closeConfirm(e)}>
                    <i className="fa fa-times"></i>
                </div>

                <NavBarJs
                    eventAgenda={this.state.agenda}
                    onClick={(e) => this.changeLoginStatus(e)}
                    login={this.state.isLogin}
                    agendaButtonShow={this.state.agendaBlockShow}
                    totalShare={this.state.totalShare}
                    totalShareHolders={this.state.totalShareHolders}
                    changeLocalization = {this.changeLocalization}
                    totalAttendedShareholdersText={this.state.totalAttendedShareholdersText}
                    totalSharesText={this.state.totalSharesText}
                    logoutButton={this.state.logoutButton}
                    showLocalization={false}

                    total_shareholder_evoting={this.state.total_shareholder_evoting}
                    total_shareholder_physical={this.state.total_shareholder_physical}
                    online_shareholders_evoting={this.state.online_shareholders_evoting}
                    online_shareholders_physical={this.state.online_shareholders_physical}

                />

                {localStorage.userType === "Shareholder" && localStorage.vote_remaining_feature === '1' &&

                <div className='remaining_agenda_container remaining_agenda_container_physical'>
                    {this.state.remaining_agenda > 0 ?
                    <div className='remaining_agenda'>
                        Votes Remaining: <b>{this.state.remaining_agenda}</b>
                    </div>
                    :
                    <div className='voting_complete'>
                        <i className='fa fa-check-circle'></i> Voting Complete
                    </div>
                    }
                </div>

                }
                <div className="col-md-12 main section no-sm-h-padding">


                    <div className="tab-content">
                        <div className='tab-pane fade show active'>


                            {
                                this.state.agendaBlockShow === 1
                                    ?
                                    <PhysicalAgendaVote
                                        isModal={false} eventAgenda={this.state.agenda}
                                        showproposed={this.state.proposedSecondedBlockShow}
                                        agendaFavorButton={this.state.agendaFavorButton}
                                        agendaAgainstButton={this.state.agendaAgainstButton}
                                        subAgendaVoteRequiredText={this.state.subAgendaVoteRequiredText}
                                        subAgendaMaxAllowedVoteText={this.state.subAgendaMaxAllowedVoteText}
                                        subAgendaVoteSubmitButton={this.state.subAgendaVoteSubmitButton}
                                        agendaVoteResultFavor={this.state.agendaVoteResultFavor}
                                        agendaVoteResultAgainst={this.state.agendaVoteResultAgainst}
                                        currentLocalization={this.state.currentLocalization}
                                        agendaVoteHereButton={this.state.agendaVoteHereButton}
                                        agendaVoteCastedButton={this.state.agendaVoteCastedButton}
                                    />
                                    : ''
                            }
                        </div>
                    </div>

                </div>
            </div>



        );
    }
}

export default PhysicalAGMDashboard;
