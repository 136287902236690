import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { browserName, fullBrowserVersion, engineName, engineVersion, deviceType, osName, osVersion } from 'react-device-detect';
import Notiflix from 'notiflix';
import "notiflix/dist/notiflix-3.2.6.min.css";


import ReactToPrint from 'react-to-print';
import PrintComponent from './PrintComponent';


window.io = require('socket.io-client');

class AttendanceDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            totalShare: 0,
            totalShareHolders: 0,
            AVusername: '',
            AVpassword: '',
            userInfo: {
                isLogin: false,
                userId: null,
                name: null
            },
            bo_id: '',
            name: '',
            mobile: '',
            authorized_person: '',
            proxy_name: '',
            type: '1',
            infoStatus: 0,
            username: '',
            shareholder: {
                id: '',
                name: '',
                email: '',
                mobile: '',
                bo_id: '',
                total_share: '',
                father: '',
                mother: '',
                nid: '',
                dob: '',
                address: '',
                bank: '',
                signature: '',
                image: '',
                authorized_person: '',
                proxy_name: '',
            },
            check_attendance: 0,
            vote_remaining: 0,
            vote_given: 0,
            total_agenda: 0,
            total_votes: 0,
        }
        this.key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj';

        // localStorage.setItem('AVtoken', null);
        // localStorage.setItem('AVisLogin', false);
        // localStorage.setItem('AVuserID', null);
        // localStorage.AVisLogin = false;

    }

    AVlogin = (event) => {
        const key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj';
        const url = `${localStorage.apiHost}/attendance-verification-login`;
        let userAgent = browserName+' '+fullBrowserVersion+' '+deviceType+' '+engineName+' '+engineVersion+' '+osName+' '+osVersion+' ';
        let data = {
            'username': this.state.AVusername,
            'password': this.state.AVpassword,
            'program-id': localStorage.programId,
            'ip': localStorage.ip,
            'user-agent': userAgent,
        };

        axios({
            method: 'post',
            url: url,
            data: data,
            headers: {
                'key': key
            }

        }).then(response => {
            console.log(response.data);
            if (response.data.status === 200) {
                this.setState({
                    userInfo: {
                        isLogin: response.data.isLogin,
                        userId: response.data.userId
                    }
                });
                console.log('check', response.data.isLogin == true);
                localStorage.AVtoken = response.headers.token;
                localStorage.AVisLogin = response.data.isLogin;
                localStorage.AVuserID = response.data.userId;
                localStorage.AVuserName = response.data.name;
                toast.success(response.data.message);
            } else {

                    this.setState({
                        userInfo: {
                            isLogin: false,
                            userId: null
                        }
                    });
                    localStorage.AVtoken = null;
                    localStorage.AVisLogin = null;
                    localStorage.AVuserID = null;
                    localStorage.AVuserName = null;

                    toast.error(response.data.message);

            }
        }).catch((error) => {
            if (error.response) {
                console.log(error.response);
            }
        });
    }

    handleChange = (event) => {

        const value = event.target.value;
        const name = event.target.name;



        this.setState({

            [name]: value,
            infoStatus: 1

        }, () => {

            this.getShareholder();

        });



    }

    // printShareholder = (event) => {
    //     //console.log('print');
    //     let printContents = document.getElementById('printablediv').innerHTML;
    //     let originalContents = document.body.innerHTML;
    //     document.body.innerHTML = "<h5>"+localStorage.companyName+"</h5><br>"+printContents;
    //     window.print();
    //     document.body.innerHTML = originalContents;
    // }

    resendOTP = (event) => {

        if (this.state.mobile == '') {

            toast.error("Error ! Please Enter Mobile Number.");

        } else {

            const key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj';
            const url = `${localStorage.apiHost}/resend-otp-from-attendance`;

            let data = {
                'shareholder-id': this.state.shareholder.id,
                'program-id': localStorage.programId,
                'mobile': this.state.mobile,
            };

            axios({
                method: 'post',
                url: url,
                data: data,
                headers: {
                    'key': key
                }

            }).then(response => {

                if (response.data.status === 200) {

                    toast.success("Sent successfully");

                } else {

                    toast.error("Error ! Please try again.");

                }

            }).catch((error) => {
                if (error.response) {
                    console.log(error.response);
                }
            });
        }
    }

    verifyShareholder = (event) => {

        if (localStorage.physical_otp_login === '1') {

            if (this.state.mobile == '') {

                toast.error("Error ! Please Enter Mobile Number.");

            } else {

                this.loginOTPNow();

            }

        } else {

            this.loginOTPNow();

        }



    }
    logoutVerifyShareholder() {
        this.setState({
            userInfo: {
                isLogin: false,
                userId: null
            }
        });
        localStorage.AVtoken = null;
        localStorage.AVisLogin = null;
        localStorage.AVuserID = null;
    }
    loginOTPNow = (event) => {

        let userAgent = browserName + ' ' + fullBrowserVersion + ' ' + deviceType + ' ' + engineName + ' ' + engineVersion + ' ' + osName + ' ' + osVersion + ' ';

        const key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj';
        const url = `${localStorage.apiHost}/verify-shareholder-attendance`;

        let data = {
            'shareholder-id': this.state.shareholder.id,
            'mobile': this.state.mobile,
            'program-id': localStorage.programId,
            'user-agent': userAgent
        };

        axios({
            method: 'post',
            url: url,
            data: data,
            headers: {
                'key': key,
                'access-token': localStorage.AVtoken
            }

        }).then(response => {

            // console.log(response);

            if (response.data.status === 200) {

                this.setState({
                    check_attendance: 1,
                });

                toast.success("Saved successfully");

                if (localStorage.auto_login_save_attendance === '1') {



                    Notiflix.Confirm.show(
                        "Confirm Login",
                        "Are you sure you want to login?",
                        "Confirm Login",
                        'Cancel',
                        function () {

                            localStorage.loginID = this.state.shareholder.id;
                            localStorage.token = response.headers.token;
                            localStorage.isLogin = "true";
                            localStorage.userName = this.state.shareholder.name;
                            localStorage.userType = "Shareholder"
                            this.props.onClick("true");

                            window.open(response.data.url, "_blank");

                        }.bind(this),
                        false
                    );

                }


            } else {

                toast.error("Error ! Please try again.");

            }

        }).catch((error) => {
            if (error.response) {
                console.log(error.response);
            }
        });



    }

    getShareholder() {

        if (this.state.bo_id === '' && this.state.name === '' && this.state.mobile === '' && this.state.authorized_person === '' && this.state.proxy_name === '') {

            this.setState({
                infoStatus: 0
            });

        }

        const key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj';
        const url = `${localStorage.apiHost}/search-shareholder`;

        let data = {
            'username': this.state.username,
            'bo-id': this.state.bo_id,
            'name': this.state.name,
            'mobile': this.state.mobile,
            'authorize-person-name': this.state.authorized_person,
            'proxy-name': this.state.proxy_name,
            'type': this.state.type,
            'program-id': localStorage.programId,
        };

        axios({
            method: 'post',
            url: url,
            data: data,
            headers: {
                'key': key
            }

        }).then(response => {

            // console.log(response);

            if (response.data.status === 200) {

                this.setState({
                    infoStatus: 2,
                    check_attendance: response.data.check_attendance,
                    shareholder: {
                        id: response.data.data.id,
                        bo_id: response.data.data.boid_full,
                        name: response.data.data.name,
                        email: response.data.data.email,
                        mobile: response.data.data.phone,
                        total_share: response.data.data.total_share,
                        father: response.data.data.father,
                        mother: response.data.data.mother,
                        nid: response.data.data.nid_number,
                        dob: response.data.data.dob,
                        address: response.data.data.address,
                        bank: response.data.data.bank,
                        signature: response.data.data.signature,
                        image: response.data.data.image,
                        authorized_person: response.data.data.authorized_person_name,
                        proxy_name: response.data.data.proxy_name,
                    },
                    vote_remaining: response.data.vote_remaining,
                    vote_given: response.data.vote_given,
                    total_agenda: response.data.total_agenda,
                    total_votes: response.data.total_votes,
                });

            } else {

                this.setState({
                    infoStatus: 3,
                    shareholder: {
                        id: '',
                        name: '',
                        email: '',
                        mobile: '',
                        bo_id: '',
                        total_share: '',
                        father: '',
                        mother: '',
                        nid: '',
                        dob: '',
                        address: '',
                        bank: '',
                        signature: '',
                        image: '',
                        authorized_person: '',
                        proxy_name: '',
                    },
                    vote_remaining: 0,
                    vote_given: 0,
                    total_agenda: 0,
                    total_votes: 0
                });

                if (response.data.status === 402) {
                    this.setState({
                        infoStatus: 0,
                    })
                }

            }
        }).catch((error) => {
            if (error.response) {
                console.log(error.response);
            }
        });

    }

    resetAll = (event) => {
        this.setState({
            bo_id: '',
            name: '',
            mobile: '',
            authorized_person: '',
            proxy_name: '',
            infoStatus: 0,
        });
    }


    render() {
        console.log('rd', localStorage.AVisLogin === 'true');
        return (
            <>

                {/* login header start  */}
                {this.state.userInfo.isLogin === true || localStorage.AVisLogin === 'true' ?
                (<div className="col-md-12 no-sm-h-padding mt-2 mb-2">
                    <div className="col-md-12 viewer-section" style={{background: localStorage.navigationBarBgColor}}>
                        <div className="row mb-2 pt-2 pb-2">
                            <div className="col-md-8 col-sm-6 col-xs-12 mt-auto mb-auto xs_padding_5">
                                <p style={{color: localStorage.navigationBarTextColor}}>
                                    <b>Shareholder Verification Panel</b>
                                </p>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12 mt-auto mb-auto xs_padding_5">
                                <p style={{color: localStorage.navigationBarTextColor}}>
                                    <a className="logout-icon" onClick={()=>{this.logoutVerifyShareholder()}} data-toggle="tooltip" title="Logout" href="">
                                        <span style={{color: localStorage.navigationBarTextColor}}>Logout </span>
                                        <span>
                                            <img src="logout.png" alt="logout icon" className="my_icon" />
                                        </span>
                                        &nbsp; &nbsp;
                                        <span style={{color: localStorage.navigationBarTextColor}}>{localStorage.AVuserName}</span>
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>) : ('') }
                {/* login header end  */}


                <div className="col-md-12">
                    {this.state.userInfo.isLogin === true || localStorage.AVisLogin === 'true' ?
                        (
                            <div className="row mb-2">
                                <div className="col-md-4">

                                    <div className="card">

                                        <h5 className="card-header text-center">Search Shareholder</h5>

                                        <div className="card-body">

                                            <div className="form-group mb-3">

                                                <div className="form-check form-check-inline">

                                                    <input className="form-check-input" onChange={this.handleChange} type="radio" name="type" id="inlineRadio1" value="1" />

                                                    <label className="form-check-label" htmlFor="inlineRadio1">Individual</label>

                                                </div>

                                                <div className="form-check form-check-inline">

                                                    <input className="form-check-input" onChange={this.handleChange} type="radio" name="type" id="inlineRadio2" value="2" />

                                                    <label className="form-check-label" htmlFor="inlineRadio2">Company</label>

                                                </div>

                                                <div className="form-check form-check-inline">

                                                    <input className="form-check-input" onChange={this.handleChange} type="radio" name="type" id="inlineRadio3" value="3" />

                                                    <label className="form-check-label" htmlFor="inlineRadio3">Proxy</label>

                                                </div>

                                                <div className="form-check form-check-inline">

                                                    <input className="form-check-input" onChange={this.handleChange} type="radio" name="type" id="inlineRadio4" value="4" />

                                                    <label className="form-check-label" htmlFor="inlineRadio4">Multi Proxy</label>

                                                </div>


                                            </div>

                                            {this.state.type === '4' &&
                                                <div className="form-group">

                                                    <label className="font-weight-bold">Username:</label>

                                                    <input type="text" onChange={this.handleChange} name="username" className="form-control" value={this.state.username} placeholder="Username" />

                                                </div>
                                            }

                                            <div className="form-group">

                                                <label className="font-weight-bold">BOID / Folio:</label>

                                                <input type="text" onChange={this.handleChange} name="bo_id" className="form-control" value={this.state.bo_id} placeholder="Enter BOID / Folio" />

                                            </div>

                                            <div className="form-group">

                                                <label className="font-weight-bold">Name:</label>

                                                <input type="text" onChange={this.handleChange} name="name" className="form-control" value={this.state.name} placeholder="Name" />

                                            </div>

                                            <div className="form-group">

                                                <label className="font-weight-bold">Mobile:</label>

                                                <input type="text" onChange={this.handleChange} name="mobile" className="form-control" value={this.state.mobile} placeholder="Mobile" />

                                            </div>

                                            <div className="form-group">

                                                <label className="font-weight-bold">Authorized Person Name:</label>

                                                <input type="text" onChange={this.handleChange} name="authorized_person" className="form-control" value={this.state.authorized_person} placeholder="Enter Authorized Person Name" />

                                            </div>

                                            <div className="form-group">

                                                <label className="font-weight-bold">Proxy Name:</label>

                                                <input type="text" onChange={this.handleChange} name="proxy_name" className="form-control" value={this.state.proxy_name} placeholder="Enter BOID / Folio" />

                                            </div>

                                            <div className="form-group mt-3">

                                                <button onClick={e => { this.resetAll(e); }} className="btn btn-danger">Reset All</button>

                                            </div>

                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-8">

                                    <div className="card">

                                        <h5 className="card-header text-center">Verify Shareholder Information</h5>

                                        <div className="card-body verify_card">

                                            {this.state.infoStatus === 0 &&
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h5 className="mt-5 text-center">
                                                            Search Shareholder to Verify
                                                        </h5>
                                                    </div>
                                                </div>
                                            }

                                            {this.state.infoStatus === 1 &&
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h3 className="mt-5 text-center">
                                                            <i className='fa fa-spinner fa-spin'></i>
                                                        </h3>
                                                    </div>
                                                </div>
                                            }

                                            {this.state.infoStatus === 2 &&
                                                <div className="row">
                                                    {/* <div className="col-md-9" id='printablediv' ref={el => (this.componentRef = el)}> */}
                                                    <div className="col-md-9" id='printablediv' >
                                                        <h5 className='ptitle text-center p-0 mt-2'>
                                                            <div dangerouslySetInnerHTML={{ __html: localStorage.companyName }} />
                                                        </h5>
                                                        <h5 className='ptitle text-center mb-5'>
                                                            <div dangerouslySetInnerHTML={{ __html: localStorage.programName }} />
                                                        </h5>
                                                        <table className="table table-borderless table-sm">
                                                            <thead>
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th> : {this.state.shareholder.name}</th>
                                                                </tr>
                                                                <tr>
                                                                    <th>Email</th>
                                                                    <th> : {this.state.shareholder.email}</th>
                                                                </tr>
                                                                <tr>
                                                                    <th>Mobile</th>
                                                                    <th> : {this.state.shareholder.mobile}</th>
                                                                </tr>
                                                                <tr>
                                                                    <th>BOID / Folio</th>
                                                                    <th> : {this.state.shareholder.bo_id}</th>
                                                                </tr>
                                                                <tr>
                                                                    <th>Total Share</th>
                                                                    <th> : {this.state.shareholder.total_share}</th>
                                                                </tr>
                                                                <tr>
                                                                    <th>Father's Name</th>
                                                                    <th> : {this.state.shareholder.father}</th>
                                                                </tr>
                                                                <tr>
                                                                    <th>Mother's Name</th>
                                                                    <th> : {this.state.shareholder.mother}</th>
                                                                </tr>
                                                                <tr>
                                                                    <th>National ID</th>
                                                                    <th> : {this.state.shareholder.nid}</th>
                                                                </tr>
                                                                <tr>
                                                                    <th>Date of Birth</th>
                                                                    <th> : {this.state.shareholder.dob}</th>
                                                                </tr>
                                                                <tr>
                                                                    <th>Address</th>
                                                                    <th> : {this.state.shareholder.address}</th>
                                                                </tr>
                                                                <tr>
                                                                    <th>Bank Name</th>
                                                                    <th> : {this.state.shareholder.bank}</th>
                                                                </tr>
                                                                {this.state.type != 4 ?
                                                                    <tr>
                                                                        <th>Vote</th>
                                                                        <th> : Agenda - {this.state.total_agenda} &nbsp;&nbsp;|&nbsp;&nbsp; Remaining - {this.state.vote_remaining} &nbsp;&nbsp;|&nbsp;&nbsp; Vote Given - {this.state.vote_given}</th>
                                                                    </tr>
                                                                    :
                                                                    <tr>
                                                                        <th>Vote</th>
                                                                        <th> : Total Votes - {this.state.total_votes} &nbsp;&nbsp;|&nbsp;&nbsp; Remaining - {this.state.vote_remaining} &nbsp;&nbsp;|&nbsp;&nbsp; Vote Given - {this.state.vote_given}</th>
                                                                    </tr>
                                                                }
                                                            </thead>
                                                        </table>

                                                        <h5 className='ptitle text-center mt-5 mb-4'>
                                                            <br></br>
                                                            Print by {localStorage.AVuserName} ({localStorage.AVuserID})
                                                            <br></br>
                                                            Powered By Comjagat Technologies
                                                        </h5>
                                                    </div>
                                                    <div className="col-md-3">

                                                        {localStorage.show_image === '1' &&
                                                            <>
                                                                <h6 className="text-center">Image</h6>

                                                                {this.state.shareholder.image ?

                                                                    <img src={"data:image/jpg;base64," + this.state.shareholder.image} width="100%" alt="Shareholder Signature" className="img-thumbnail" />

                                                                    :

                                                                    <img src={"https://sgp1.digitaloceanspaces.com/hybrid/images/" + localStorage.programId + "/" + this.state.shareholder.bo_id + ".jpg"} width="100%" alt="Shareholder image" className="img-thumbnail" />

                                                                }

                                                                <br></br>
                                                                <br></br>

                                                            </>
                                                        }

                                                        {localStorage.show_signature === '1' &&
                                                            <>

                                                                <h6 className="text-center">Signature</h6>

                                                                {this.state.shareholder.signature ?

                                                                    <img src={"data:image/jpg;base64," + this.state.shareholder.signature} width="100%" alt="Shareholder Signature" className="img-thumbnail" />

                                                                    :

                                                                    <img src={"https://sgp1.digitaloceanspaces.com/hybrid/signatures/" + localStorage.programId + "/" + this.state.shareholder.bo_id + ".jpg"} width="100%" alt="Shareholder Signature" className="img-thumbnail" />

                                                                }

                                                                <br></br>
                                                                <br></br>

                                                            </>
                                                        }

                                                        {this.state.check_attendance === 0 ?
                                                            <>

                                                                <button onClick={this.verifyShareholder} className="btn btn-success btn-block">Save Attendance {localStorage.auto_login_save_attendance === '1' && <>and Login</>}</button>



                                                            </>
                                                            :
                                                            <>
                                                                <span className="btn btn-danger btn-block">Already Attended</span>

                                                                {/* <ReactToPrint
                                                                    bodyClass="print-title"
                                                                    trigger={() => {
                                                                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                                                        // to the root node of the returned component as it will be overwritten.
                                                                        return <button className="btn btn-primary btn-block mt-2">Print</button>;
                                                                    }}
                                                                    content={() => this.componentRef}
                                                                /> */}
                                                                <ReactToPrint
                                                                    bodyClass="print-title"
                                                                    trigger={() => {
                                                                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                                                        // to the root node of the returned component as it will be overwritten.
                                                                        return <button className="btn btn-primary btn-block mt-2">Print</button>;
                                                                    }}
                                                                    content={() => this.componentRef}
                                                                />
                                                                <PrintComponent ref={el => (this.componentRef = el)} shareholder={this.state.shareholder}/>



                                                                {localStorage.physical_otp_login === '1' &&
                                                                    <p>
                                                                        <br></br>

                                                                        {localStorage.opt_sent_note}

                                                                        <br></br>
                                                                        <br></br>

                                                                        <button onClick={this.resendOTP} className="btn btn-success btn-block">
                                                                            Resend OTP
                                                                        </button>
                                                                    </p>
                                                                }
                                                            </>

                                                        }

                                                    </div>
                                                </div>
                                            }

                                            {this.state.infoStatus === 3 &&
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h4 className="mt-5 text-center text-danger">
                                                            No shareholders found. Please search again.
                                                        </h4>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>)


                        // login interface loaded
                        : (
                            <div className="row">
                                <div className="col-md-6 mx-auto mb-3 mt-2">
                                    <div className="card">

                                        <h5 className="card-header text-center">Login for Verification Panel</h5>

                                        <div className="card-body">
                                            <div className="form-group">
                                                <label className="font-weight-bold">Username:</label>
                                                <input type="text" onChange={this.handleChange} name="AVusername" className="form-control" placeholder="Username" />
                                            </div>
                                            <div className="form-group">
                                                <label className="font-weight-bold">Password:</label>
                                                <input type="password" onChange={this.handleChange} name="AVpassword" className="form-control" placeholder="Password" />
                                            </div>
                                            <div className="form-group mt-3">
                                                <button onClick={e => { this.AVlogin(e); }} className="btn btn-info">Log in</button>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        )}
                </div>


            </>

        );
    }
}

export default AttendanceDashboard;
