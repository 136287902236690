import React from 'react';
import VoteButton from './VoteButton';
// import ProposedSeconded from "./ProposedSeconded";
import SubAgenda from "./SubAgenda";
import axios from 'axios';

class Agenda extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            agendas: [],
            disabledAgenda: [],
            // enableVoteButton: false
        }

        this.key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj';

        // sultana
        this.showproposed=true;
        // sultana
    }

    componentDidMount = () => {
        this.setState({agendas: this.props.eventAgenda});

    }

    changeAgendaStatus = (value) => {
        this.state.disabledAgenda.push(value);
    }

    render() {
        // console.log(this.state.agendas);
        return (

            
            <div className="">
                {this.state.agendas.map((agenda, index) => {

                    let total_share = localStorage.total_share*1;
                    let favor = agenda.favour ? agenda.favour*1 : 0;
                    let against = agenda.against ? agenda.against*1 : 0;

                    let vote_percentage_favor = 0;
                    let vote_percentage_against = 0;

                    if(localStorage.votingPercentageLogic === '1'){

                        if(total_share > 0 && favor > 0){
                            vote_percentage_favor = Math.round((favor*100/total_share) * 100) / 100;
                        }
    
                        if(total_share > 0 && against > 0){
                            vote_percentage_against = Math.round((against*100/total_share) * 100) / 100;
                        }

                    }

                    if(localStorage.votingPercentageLogic === '2'){

                        let voted_shares = favor + against;

                        if(voted_shares > 0 && favor > 0){
                            vote_percentage_favor = Math.round((favor*100/voted_shares) * 100) / 100;
                        }
    
                        if(voted_shares > 0 && against > 0){
                            vote_percentage_against = Math.round((against*100/voted_shares) * 100) / 100;
                        }
                        
                    }
                    
                    if(agenda.status === 1){

                        return (
                            <div className="vote-agenda-blog mt-3 pb-2" key={index}>
        
                                <div className="row">                    
                                    <div className="pl10 pr10 col-lg-12 col-md-12 col-sm-12">
        
                                        {this.props.currentLocalization === '1'
                                        ?
                                            <h4  style={{background:localStorage.agendaHeaderBgColor, color:localStorage.agendaHeaderTextColor, fontSize:localStorage.agendaNameFontSize}}><div dangerouslySetInnerHTML={{__html: agenda.agenda_name}}/></h4>
                                        :
                                            <h4  style={{background:localStorage.agendaHeaderBgColor, color:localStorage.agendaHeaderTextColor, fontSize:localStorage.agendaNameFontSize}}><div dangerouslySetInnerHTML={{__html: agenda.agenda_name_bn}}/></h4>
                                        }
        
                                    </div>
        
        
                                    <div className={`${agenda.vote_result_status === 1 ? "col-lg-12 pr-1":"col-lg-12 pr-4" } pl-4  mb-2 col-md-12 col-sm-12 details`} >
                                        {this.props.currentLocalization === '1'
                                        ?
                                            <p style={{fontSize:localStorage.agendaDescriptionFontSizeEn}} dangerouslySetInnerHTML={{__html: agenda.description}} />
                                        :
                                            <p className="pt-1" style={{fontSize:localStorage.agendaDescriptionFontSizeBn}} dangerouslySetInnerHTML={{__html: agenda.description_bn}} />
        
                                        }
                                    </div>
        
                                    {!this.props.isModal &&
        
                                    <div className={`${agenda.vote_result_status === 1?"col-lg-12 pl-1 pr-1 mb-2 col-md-12 col-sm-12":"lg-hidden" } `} >
                                    
                                        {!this.props.isModal && agenda.vote_result_status === 1 &&
                                            <div className="vote-given-blog text-center">
                                                <div className="vote-favour">
                                                    <span  style={{background:localStorage.voteResultFavourHeaderBgColor, color:localStorage.voteResultFavourHeaderTextColor}}>{this.props.agendaVoteResultFavor}</span>
        
                                                    <span style={{background:localStorage.voteResultFavourNumberBgColor, color:localStorage.voteResultFavourNumberTextColor}}>
                                                        {agenda.favour ? agenda.favour.toLocaleString('en-IN') : 0}
                                                        {localStorage.vote_percentage_module === '1' &&   
                                                            <>                  
                                                                <br></br>
                                                                <samp style={{borderTop: '1px solid #fff', width:'100%', display: 'block'}}> 
                                                                {vote_percentage_favor}%
                                                                </samp>
                                                            </>
                                                        }
                                                    </span>
                                                </div>
                                                
                                                
                                                <div className="vote-against">
                                                    <span style={{background:localStorage.voteResultAgainstHeaderBgColor,color:localStorage.voteResultAgainstHeaderTextColor}}>{this.props.agendaVoteResultAgainst}</span>
        
                                                    <span style={{background:localStorage.voteResultAgainstNumberBgColor, color:localStorage.voteResultAgainstNumberTextColor}}>
                                                        {agenda.against ? agenda.against.toLocaleString('en-IN') : 0}
                                                        {localStorage.vote_percentage_module === '1' &&   
                                                            <>                                     
                                                                <br></br>
                                                                <samp style={{borderTop: '1px solid #fff', width:'100%', display: 'block'}}> 
                                                                    {vote_percentage_against}%
                                                                </samp>
                                                            </>
                                                        }
                                                    </span>                                    
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    }
        
                                    {
                                        this.props.isModal && agenda.sub_agenda === 0 &&
                                        <VoteButton agenda={agenda}
                                        voteHereBlockShow={this.props.voteHereBlockShow}
                                        agendaVoteHereButton={this.props.agendaVoteHereButton}
                                        agendaVoteCastedButton={this.props.agendaVoteCastedButton}
                                        onClick={(e) => this.changeAgendaStatus(e)}
                                        agendaFavorButton={this.props.agendaFavorButton}
                                        agendaAgainstButton={this.props.agendaAgainstButton}
                                        subAgendaVoteRequiredText={this.props.subAgendaVoteRequiredText}
                                        subAgendaMaxAllowedVoteText={this.props.subAgendaMaxAllowedVoteText}
                                        subAgendaVoteSubmitButton={this.props.subAgendaVoteSubmitButton}
                                        buttonType={'virtual'}
                                        vote_percentage_favor={vote_percentage_favor}
                                        vote_percentage_against={vote_percentage_against}
                                        />
                                    }
                                    {
                                        this.props.isModal && agenda.sub_agenda === 1 &&
                                        <SubAgenda subAgendaText={agenda.sub_agenda_text} agendaId={agenda.id} requiredVote={agenda.required_sub_agenda_vote}
                                        // agendaVoteModalButton={this.props.agendaVoteModalButton}
                                        subAgendaVoteRequiredText={this.props.subAgendaVoteRequiredText}
                                        subAgendaMaxAllowedVoteText={this.props.subAgendaMaxAllowedVoteText}
                                        subAgendaVoteSubmitButton={this.props.subAgendaVoteSubmitButton}   
                                        vote_given_sub_agenda={this.props.vote_given_sub_agenda}   
                                        buttonType={1}
                                        />
                                    }
        
                                    {/*{*/}
                                    {/*    this.props.isModal && localStorage.userType === 'Shareholder' && (agenda.proposed_sh === 1 || agenda.seconded_sh === 1 || agenda.demanded_sh === 1) &&*/}
                                    {/*    <div className="model-blog pl-10 pr-10 mb-2 col-lg-12 col-md-12 col-sm-12">*/}
                                    {/*        <ProposedSeconded psdAgenda={agenda}/>*/}
                                    {/*    </div>*/}
                                    {/*}*/}
        
                                </div>
        
                            </div>                    
                        );
                    }
                })}

            </div>
        );
    }

}

export default Agenda;
