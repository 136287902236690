// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-section{
    /*background-color: #eeeff6;*/
    min-height: 62vh;
    /* margin-top: 5%;
    margin-bottom: 6%; */
}

.login-card{border-radius:1.25rem;overflow:hidden;box-shadow:inherit;}

.login-card-header{padding-bottom:2px}
.login-card-header .card-title{font-size:16px; font-weight: 700;}

.login-nav-tabs .nav-link{font-weight:bold; border:1px solid #DBDBDB!important;border-radius:12px 12px 0 0}
.login-nav-tabs .nav-link{padding:0.5rem 0.5rem; font-size: 15px; font-weight: 700;}
.login-tab-content {
    border-radius:0 0 12px 12px;
}


.btn-login{
    box-shadow:none;
    padding:0.125rem 1.1875rem;
    font-size: 14px;
    opacity: 1;
}
.btn-login:hover{
    color:#ffffff;
    background-color:#23336E;
    opacity:0.9;    
    padding:0.125rem 1.1875rem;
}

.font-weight-bold {
    font-size: 14px;
}

.form-group {
    margin-bottom: 0.3rem;
}

.form-control {
    font-size: 0.8rem;
}`, "",{"version":3,"sources":["webpack://./src/Login.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,gBAAgB;IAChB;wBACoB;AACxB;;AAEA,YAAY,qBAAqB,CAAC,eAAe,CAAC,kBAAkB,CAAC;;AAErE,mBAAmB,kBAAkB;AACrC,+BAA+B,cAAc,EAAE,gBAAgB,CAAC;;AAEhE,0BAA0B,gBAAgB,EAAE,kCAAkC,CAAC,2BAA2B;AAC1G,0BAA0B,qBAAqB,EAAE,eAAe,EAAE,gBAAgB,CAAC;AACnF;IACI,2BAA2B;AAC/B;;;AAGA;IACI,eAAe;IACf,0BAA0B;IAC1B,eAAe;IACf,UAAU;AACd;AACA;IACI,aAAa;IACb,wBAAwB;IACxB,WAAW;IACX,0BAA0B;AAC9B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".login-section{\n    /*background-color: #eeeff6;*/\n    min-height: 62vh;\n    /* margin-top: 5%;\n    margin-bottom: 6%; */\n}\n\n.login-card{border-radius:1.25rem;overflow:hidden;box-shadow:inherit;}\n\n.login-card-header{padding-bottom:2px}\n.login-card-header .card-title{font-size:16px; font-weight: 700;}\n\n.login-nav-tabs .nav-link{font-weight:bold; border:1px solid #DBDBDB!important;border-radius:12px 12px 0 0}\n.login-nav-tabs .nav-link{padding:0.5rem 0.5rem; font-size: 15px; font-weight: 700;}\n.login-tab-content {\n    border-radius:0 0 12px 12px;\n}\n\n\n.btn-login{\n    box-shadow:none;\n    padding:0.125rem 1.1875rem;\n    font-size: 14px;\n    opacity: 1;\n}\n.btn-login:hover{\n    color:#ffffff;\n    background-color:#23336E;\n    opacity:0.9;    \n    padding:0.125rem 1.1875rem;\n}\n\n.font-weight-bold {\n    font-size: 14px;\n}\n\n.form-group {\n    margin-bottom: 0.3rem;\n}\n\n.form-control {\n    font-size: 0.8rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
